import React from 'react'
import clsx from 'clsx'

export const PageTitle: React.FC<{
  children: React.PropsWithChildren<any>
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        className,
        'text-2xl text-white font-bold text-center font-mulish'
      )}
    >
      {children}
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'

// Define a type for the slice state
export interface JoyrideState {
  openDepositPanel: boolean
  tabPanelIndex: number
}

// Define the initial state using that type
const initialState: JoyrideState = {
  openDepositPanel: false,
  tabPanelIndex: 0
}

export const gvJoyrideSlice = createSlice({
  name: 'joyride',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setOpenDepositPanel: (state, action: PayloadAction<boolean>) => {
      state.openDepositPanel = action.payload
    },
    setTabPanelIndex: (state, action: PayloadAction<number>) => {
      state.tabPanelIndex = action.payload
    }
  }
})

export const { setOpenDepositPanel, setTabPanelIndex } = gvJoyrideSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOpenDepositPanel = (state: RootState) =>
  state.joyride.openDepositPanel
export const selectTabPanelIndex = (state: RootState) =>
  state.joyride.tabPanelIndex

export default gvJoyrideSlice.reducer

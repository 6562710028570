import { Step } from 'react-joyride'
import React from 'react'

export const withdrawTabSteps = (
  account: string | null | undefined
): Array<Step> => {
  let _steps: Array<Step> = []

  _steps.push({
    target: '.withdraw-tab',
    placement: 'top',
    content: (
      <div>
        <div>
          This opens the Withdraw tab where you can Withdraw your ez-tokens.
        </div>
        {!account && (
          <div className={'mt-4'}>
            Connect to your wallet and come back to this guide to view a more in-depth explanation of this screen.
          </div>
        )}
      </div>
    ),
    disableBeacon: true
  })
  if (account) {
    _steps.push({
      target: '.withdraw-tab-token',
      placement: 'top',
      content:
        'The left shows the ez-tokens that can be withdrawn in the current vault.',
      disableBeacon: true
    })
    _steps.push({
      target: '.withdraw-tab-balance',
      placement: 'top',
      content: 'The top right shows the ez-tokens you have in your wallet.',
      disableBeacon: true
    })
    _steps.push({
      target: '.withdraw-tab-ez-token',
      placement: 'top',
      content:
        'The second part of the Withdrawal tab shows the balance of tokens you currently have in your wallet and the amount you will receive when withdrawing the amount above.',
      disableBeacon: true
    })
    _steps.push({
      target: '.withdraw-tab-call-to-action',
      placement: 'top',
      content:
        'You can click [Withdraw] to deposit the amount entered or click [Withdraw Max] to do a one-click deposit of all your tokens for this vault.',
      disableBeacon: true
    })
  }

  return _steps
}

import useContractMethodCall from '../useContractMethodCall'
import Contracts from '../../contracts'
import BN from 'bn.js'

export const useGetUnderlyingTokenBalance = (address: string) =>
  useContractMethodCall<number>(
    Contracts.underlyingToken,
    'balanceOf',
    new BN('0'),
    address ?? '0x'
  )

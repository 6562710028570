import { InformationCircleIcon } from '@heroicons/react/outline'
import { chainIdToName } from '../classes/helpers'
import React from 'react'
import clsx from 'clsx'
import { InformationAlert } from './InformationAlert'

export const WrongChainAlert: React.FC<{
  chainId: number
  className?: string
}> = ({ chainId, className }) => {
  return (
    <InformationAlert>
      <div>
        You are currently connected to{' '}
        <span className={'font-bold'}>{chainIdToName(chainId)}</span>.
      </div>
      <div>
        Please switch to <span className={'font-bold'}>Ethereum Mainnet</span>{' '}
        in your wallet.
      </div>
    </InformationAlert>
  )
}

import useContractMethodCall from '../useContractMethodCall'
import Contracts from '../../contracts'
import BN from 'bn.js'

export const useGetEzTokenBalance = (token: string) =>
  useContractMethodCall<number>(
    Contracts.rcaShield,
    'balanceOf',
    new BN('0'),
    token
  )

import { Step } from 'react-joyride'
import React from 'react'

export const farmingTabSteps = (
  account: string | null | undefined
): Array<Step> => {
  let _steps: Array<Step> = []

  _steps.push({
    target: '.farming-tab',
    placement: 'top',
    content: (
      <div>
        <div>
          This tab shows the ez-farm for this vault. You can stake your ez-tokens here and earn $Ease rewards on top of your auto-compounding yield!
        </div>
        {!account && (
          <div className={'mt-4'}>
            Connect to your wallet and come back to this guide to view a more
            in-depth explanation of this screen.
          </div>
        )}
      </div>
    ),
    disableBeacon: true
  })
  if (account) {
    _steps.push({
      target: '.farming-tab-content',
      placement: 'top',
      content:
        'The Farming tab consists of 4 columns.',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-ez-balance',
      placement: 'top',
      content: 'The left column shows your ez-tokens balance that have not yet been staked into the ez-farm and their $USD value.',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-ez-staked',
      placement: 'top',
      content:
        'The second column shows how many ez-tokens you have staked into the ez-farm, and their value in $USD.',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-rewards',
      placement: 'top',
      content:
        'The third column shows how many $Ease rewards you have earned and their $USD value.',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-apr',
      placement: 'top',
      content: (
        <div>
          The fourth column shows the current apy of the vault.
          <div>
            This will change if:
            <br />
            <div>- you or other users deposit or withdraw tokens</div>
            <div>- the value of either the staked tokens or the $Ease rewards change</div>
            <div>- the allocated $Ease rewards per week change.</div>
          </div>
        </div>
      ),
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-buttons',
      placement: 'top',
      content:(
        <div>
          These buttons are for adding ez-tokens to the farm (“staking”), claiming rewards and withdrawing of ez-tokens from the farm (“unstaking”).
          <br/>
          <br/>
          Note that staking or unstaking tokens will also automatically claim all outstanding $Ease rewards, so there is no need to claim rewards first if you want to (un)stake.
        </div>
      ),
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-button-stake',
      placement: 'top',
      content:
        'Clicking the first button will create a pop-up in which you can choose the amount to stake and its effect on the apy (a higher stake will result in a lower apy)',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-button-claim',
      placement: 'top',
      content:
        'Clicking the second button will create a pop-up and a transaction to claim all $Ease rewards while not changing the amount of (un)staked ez-tokens. These $Ease rewards will be auto-deposited into $gvEase and can be used in the Ease ecosystem or withdrawn with a 7-day delay.',
      disableBeacon: true
    })
    _steps.push({
      target: '.farming-button-unstake',
      placement: 'top',
      content:
        'Clicking the third button will create a pop-up in which you can choose the amount of ez-tokens to unstake from the farm.',
      disableBeacon: true
    })
  }

  return _steps
}

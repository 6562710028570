import { Step } from 'react-joyride'

export const firstSteps: Array<Step> = [
  {
    target: '.bribe-0',
    placement: 'top',
    content: 'This is the amount of $Ease in your wallet and that is available to be used for bribes.',
    disableBeacon: true
  },
  {
    target: '.bribe-1',
    placement: 'top',
    content: 'This is the total amount of $Ease you currently spend weekly on bribes.',
    disableBeacon: true
  }
]

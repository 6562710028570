import { ChevronDownIcon } from '@heroicons/react/solid'
import { Disclosure, Tab, Transition } from '@headlessui/react'
import {
  classNames,
  formatDecimals,
  formatShort,
  makeDecimalUnits, unifiedFormat
} from '../../../classes/helpers'
import React, { useEffect, useMemo, useState } from 'react'
import { DepositTabPanel } from '../DepositTabPanel'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { useWeb3React } from '@web3-react/core'
import { StatsTabPanel } from '../StatsTabPanel'
import { WithdrawTabPanel } from '../WithdrawTabPanel'
import { FarmingTabPanel } from '../FarmingTabPanel'
import { InfoTabPanel } from '../InfoTabPanel'
import { InstructionTabPanel } from '../InstructionTabPanel'
import { ConnectToWalletTabPanel } from '../ConnectToWalletTabPanel'
import { formatUnits } from 'ethers/lib/utils'
import { useAppSelector } from '../../../hooks/redux'
import { useGetUnderlyingTokenBalance } from '../../../hooks/dashboard/useGetUnderlyingTokenBalance'
import {
  selectOpenDepositPanel,
  selectTabPanelIndex
} from '../../../store/joyrideSlice'

export const OpportunityRow: React.FC<{
  children?: React.PropsWithChildren<any>
  vault: VaultsApi.Vault
  onUpdate: Function
  withdrawalDelay: string
  isOpen?: boolean
  index: number
  total: number
}> = ({ children, vault, withdrawalDelay, onUpdate, isOpen, index, total }) => {
  const tabs = ['Deposit', 'Withdraw', 'Farming', 'Info', 'Instructions']

  const [underlyingTokenBalance, getUnderlyingTokenBalance] =
    useGetUnderlyingTokenBalance(vault.token?.address ?? '0x')

  const [tabIndex, setTabIndex] = useState(0)
  const joyrideTabPanelIndex = useAppSelector(selectTabPanelIndex)

  const { account } = useWeb3React()

  const memoizedBalance = useMemo(() => {
    return formatUnits(vault.balance ?? '0', vault.decimals ?? '18')
  }, [vault.balance, vault.decimals])
  
  const memoizedAssets = useMemo(() => {
    if (!vault.token?.assets || vault.token.assets.trim() === '') {
      return 0
    }
    return Number(formatUnits(vault.token.assets.toString() ?? '0', 18))
  }, [vault.token?.assets])

  const memoizedUnderlyingTokenBalance = useMemo(
    () =>
      underlyingTokenBalance /
      Number(makeDecimalUnits(vault.token?.decimals ?? 18)),
    [underlyingTokenBalance, vault.token?.decimals]
  )

  const assetsDollarValue = (_assetAmount:number|string) => {
    if(!vault.token?.priceUSD) return 0
    let valueInDollars = Number(vault.token?.priceUSD) * Number(_assetAmount)
    return valueInDollars < 0.001 ? 0: unifiedFormat(valueInDollars)
  }

  useEffect(() => {
    if (joyrideTabPanelIndex != tabIndex) {
      setTabIndex(joyrideTabPanelIndex)
    }
  }, [joyrideTabPanelIndex])

  useEffect(() => {
    getUnderlyingTokenBalance([account]).catch((err) =>
      console.error({ err })
    )
  }, [memoizedUnderlyingTokenBalance, account])

  return (
    <>
      <div data-testid={'opportunity-row'}>
        <Disclosure defaultOpen={isOpen}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  'w-full grid grid-cols-5 md:grid-cols-7 text-white font-medium pb-4 py-4 hover:bg-blackop-15',
                  index == 0 ? 'rounded-t-2xl' : '',
                  index == total - 1 ? 'rounded-b-2xl' : ''
                )}
              >
                <div
                  className={
                    'flex col-span-3 md:col-span-2 gap-x-2 items-center text-left ml-4'
                  }
                  data-testid={'opportunity-row-display-name'}
                >
                  <img src={vault.icon} className={'h-6 w-6 rounded-full'} />
                  {vault.display_name}
                </div>
                <div
                  className={'text-left flex justify-between'}
                  data-testid={'opportunity-row-apy'}
                >
                  <span>{unifiedFormat(Number(vault.token?.apy) ?? 0)}%</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-6 h-6 text-white mr-2 md:hidden`}
                  />
                </div>
                <div
                  className={'text-left hidden md:flex'}
                  data-testid={'opportunity-row-total-assets'}
                >
                  ${assetsDollarValue(memoizedAssets)}
                </div>
                <div className={'text-left hidden md:flex'}>
                  {unifiedFormat(vault.maxFee)}%
                </div>
                <div
                  className={'text-left hidden md:flex justify-between mr-4'}
                  data-testid={'opportunity-row-balance'}
                >
                  ${assetsDollarValue(
                    memoizedUnderlyingTokenBalance
                  )}
                </div>
                <div
                  className={'text-left hidden md:flex justify-between mr-4'}
                  data-testid={'opportunity-row-balance'}
                >
                  <div>${assetsDollarValue(vault.remaining_capacity)}</div>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-6 h-6 text-white`}
                  />
                </div>
              </Disclosure.Button>
              <Transition
                enter="transition duration-[300ms] ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-[50ms] ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="w-full px-4  sm:px-4 bg-blackop-10">
                  <Tab.Group
                    defaultIndex={tabIndex}
                    selectedIndex={tabIndex}
                    onChange={(tabIndex) => {
                      setTabIndex(tabIndex)
                    }}
                  >
                    <Tab.List className="overflow-x-auto flex p-1 space-x-1 rounded-xl gap-2">
                      {tabs.map((title) => (
                        <Tab
                          key={title}
                          className={({ selected }) =>
                            classNames(
                              'uppercase w-full py-2.5 text-sm leading-5 font-medium font-bold',
                              title == 'Deposit' ? 'deposit-tab' : '',
                              title == 'Withdraw' ? 'withdraw-tab' : '',
                              title == 'Farming' ? 'farming-tab' : '',
                              title == 'Info' ? 'info-tab' : '',
                              title == 'Instructions' ? 'instructions-tab' : '',
                              'focus:outline-none focus:ring-transparent',
                              selected
                                ? 'border-b-2 border-orange-500 text-white'
                                : 'hover:bg-blackop-5 text-gray-400'
                            )
                          }
                        >
                          {title}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels>
                      {account && (
                        <>
                          <Tab.Panel
                            className={classNames(
                              'text-white rounded-xl py-4 px-1 panel-sm mx-auto deposit-tab',
                              'focus:outline-none focus:ring-transparent relative'
                            )}
                          >
                            <DepositTabPanel
                              onUpdate={onUpdate}
                              vault={vault}
                              withdrawalDelay={withdrawalDelay}
                            />
                          </Tab.Panel>
                          <WithdrawTabPanel
                            vault={vault}
                            withdrawalDelay={withdrawalDelay}
                            onUpdate={onUpdate}
                          />
                          <FarmingTabPanel
                            vault={vault}
                            onUpdate={onUpdate}
                          />
                          <InfoTabPanel vault={vault} />
                          <InstructionTabPanel vault={vault} />
                        </>
                      )}
                      {!account && (
                        <>
                          {
                            [...Array(3),].map((value, index) => (
                              <ConnectToWalletTabPanel key={index}>
                                <div className={'mb-4 bg-blackop-20 rounded p-2'}>
                                  <span className={'font-bold'}>
                                    Remaining Capacity:
                                  </span>{' '}
                                  $
                                  {formatDecimals(
                                    Number(vault.remaining_capacity_usd) ?? 0,
                                    2,
                                    2
                                  )}
                                </div>
                              </ConnectToWalletTabPanel>
                            ))
                          }
                          <InfoTabPanel vault={vault} />
                          <InstructionTabPanel vault={vault} />
                        </>
                      )}
                    </Tab.Panels>
                  </Tab.Group>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </>
  )
}

import clsx from 'clsx'
import { ExclamationIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { CashIcon, XIcon } from '@heroicons/react/solid'

export const SuccessAlert: React.FC<{
  className?: string
  title?: string
  children?: React.PropsWithChildren<any>
  closeable?: boolean
}> = ({ children, className, title = 'Success', closeable = true }) => {
  const [hidden, setHidden] = useState(false)
  if (hidden) {
    return <></>
  }

  return (
    <div className={clsx(className, 'rounded-md bg-green-50 p-4')}>
      <div className="flex relative">
        <div className="flex-shrink-0">
          <CashIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-grow">
          <h3 className="text-sm font-medium text-green-800">{title}</h3>
          <div className="mt-2 text-sm text-green-700 w-full">{children}</div>
        </div>
        {closeable && (
          <div className={'absolute right-0'}>
            <XIcon
              className={'h-4 w-4 text-green-800 cursor-pointer'}
              onClick={() => setHidden(true)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

import { useQuery } from 'react-query'

interface GetPermitsRequest {
  chainId: string
  user: string
  vault: string
  amount: string
  nonce: string
}
export const getPermits = async (
  permit: GetPermitsRequest
): Promise<PermitsApi.Permit> => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/permits`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(permit)
    }
  )
  return response.json()
}

export default function useGetPermits(permit: GetPermitsRequest) {
  return useQuery<PermitsApi.Permit, Error>('vaults/get', () =>
    getPermits(permit)
  )
}

export declare module PermitsApi {
  export interface Permit {
    chainId: string
    user: string
    vault: string
    amount: string
    nonce: string
    expiry: number
    signature: string
    r: string
    s: string
    vInt: number
    error?: string
  }
}

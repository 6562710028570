import React from 'react'
import { Step } from 'react-joyride'

export const secondSteps = (): Array<Step> => {
  let _steps: Array<Step> = []

  _steps.push({
    target: '.second-step',
    placement: 'top',
    content: 'The second section is the Leasing section. Here you can lease your unused $gvEase to earn a share of $Ease bribes.',
    disableBeacon: true
  })

  _steps.push({
    target: '.lease-1',
    placement: 'top',
    content: 'The first column shows the amount of $gvEase you have currently leased.',
    disableBeacon: true
  })

  _steps.push({
    target: '.lease-2',
    placement: 'top',
    content: 'The second column shows the estimated weekly $Ease rewards, based on currently available bribes and other users’ active leases.',
    disableBeacon: true
  })

  _steps.push({
    target: '.lease-3',
    placement: 'top',
    content: 'The third column shows the current estimated APR (annual percentage rate) you can earn on your leased $gvEase, based on currently available bribes and other users’ active leases.',
    disableBeacon: true
  })

  _steps.push({
    target: '.lease-4',
    placement: 'top',
    content: (
      <div>
        <div>
          The fourth column shows 2 buttons:
        </div>
        <div>
          - The [LEASE] button starts the leasing process.
        </div>
        <div>
          - If you have an active lease, then the [WITHDRAW] button lets you withdraw your leased amount.
        </div>
      </div>
    ),
    disableBeacon: true
  })

  return _steps
}

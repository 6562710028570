import { useQuery } from 'react-query'

const getEvents = async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/ease`)
  return response.json()
}

export default function useGetEase() {
  return useQuery<EaseStatsApi.Response, Error>(
    'ease/events/get',
    () => getEvents(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1
    }
  )
}

export declare module EaseStatsApi {
  export interface Vaults {
    percentageStaked: string
    gvEaseStaked: string
    uAmount: string
    rcaAmount: string
    bribes: Bribes[]
  }
  export interface VaultStats {
    [key: string]: VaultStat
  }
  export interface VaultStat {
    uAmount: string
    rcaAmount: string
    rcaAmountEth: string
    maxFee: number
    weeklyBribes: WeeklyBribes
    gvEaseStaked: string
  }
  export interface WeeklyBribes {
    [key: string]: WeeklyBribe
  }
  export interface WeeklyBribe {
    ease: string
    gvEase: string
  }

  export interface Bribes {
    receiver: string
    eventName: string
    eventTimestamp: number
    blockNumber: number
    contract: string
    eventTime: Date
    sender: string
    startWeek: number
    status: string
    bribePerWeek: string
    endWeek: number
    key: string
    eventClass: string
    logIndex: number
    transactionFee: string
    transactionHash: string
  }

  export interface Deposit {
    eventTimestamp: Date
    eventName: string
    amount: string
  }

  export interface User {
    vaults: Vaults[]
    deposits: Deposit[]
    easeBalance: string
    gvEaseBalance: string
    gvEaseStakeable: string
    gvEaseStaked: string
    leasedAmount: string
  }

  export interface Response {
    genesis: number
    timestamp: number
    week: number
    users: User[]
    vaults: VaultStats
    rcaAmountEth: string
    leasedAmount: string
    weeklyBribes: WeeklyBribes
    totalDeposited: string
    totalSupply: string
    gvEaseBalance: string
    gvEaseStakeable: string
    gvEaseStaked: string
  }
}

export declare module EaseEventsApi {
  export interface Event {
    receiver: string
    eventName: string
    eventTimestamp: string
    blockNumber: number
    contract: string
    sender: string
    startWeek: number
    amount: string
    bribePerWeek: string
    percentage: number
    endWeek: number
    key: string
    logIndex: number
    transactionFee: number
    transactionHash: string
  }

  export interface Response {
    events: Event[]
  }
}

export declare module EaseUsersApi {
  export interface Bribes {
    receiver: string
    eventName: string
    eventTimestamp: number
    blockNumber: number
    contract: string
    eventTime: Date
    sender: string
    startWeek: number
    bribePerWeek: string
    endWeek: number
    key: string
    eventClass: string
    logIndex: number
    transactionFee: string
    transactionHash: string
    status: string
  }

  export interface Stake {
    eventTimestamp: Date
    eventName: string
    amount: string
  }

  export interface Vault {
    totalUAmount: string
    totalRcaAmount: string
    stakeCount: number
    stakePercentage: number
    unStakeCount: number
    depositedCount: number
    stakes: Stake[]
    bribes: Bribes[]
    bribeCanceledCount: number
  }

  export interface Response {
    vaults: Vault[]
  }
}

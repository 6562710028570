import { ContractInterface } from '../index'

const BribePot = (address: string): ContractInterface =>
  Object.freeze({
    address: address,
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: '_gvToken',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '_rewardsToken',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '_rcaController',
            type: 'address'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'vault',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'bribePerWeek',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'startWeek',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'endWeek',
            type: 'uint256'
          }
        ],
        name: 'BribeAdded',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'vault',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'bribePerWeek',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'expiryWeek',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'endWeek',
            type: 'uint256'
          }
        ],
        name: 'BribeCanceled',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Deposited',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256'
          }
        ],
        name: 'RewardPaid',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Withdrawn',
        type: 'event'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'bribeRate',
            type: 'uint256'
          },
          {
            internalType: 'address',
            name: 'vault',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'numOfWeeks',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
              },
              {
                internalType: 'uint8',
                name: 'v',
                type: 'uint8'
              },
              {
                internalType: 'bytes32',
                name: 'r',
                type: 'bytes32'
              },
              {
                internalType: 'bytes32',
                name: 's',
                type: 'bytes32'
              }
            ],
            internalType: 'struct BribePot.PermitArgs',
            name: 'permit',
            type: 'tuple'
          }
        ],
        name: 'bribe',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'bribePerWeek',
        outputs: [
          {
            internalType: 'uint256',
            name: 'bribeRate',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'bribes',
        outputs: [
          {
            internalType: 'uint112',
            name: 'rate',
            type: 'uint112'
          },
          {
            internalType: 'uint32',
            name: 'startWeek',
            type: 'uint32'
          },
          {
            internalType: 'uint32',
            name: 'endWeek',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'vault',
            type: 'address'
          }
        ],
        name: 'cancelBribe',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address'
          }
        ],
        name: 'earnable',
        outputs: [
          {
            internalType: 'uint256',
            name: 'rewardAmt',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'earned',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'gvAmt',
            type: 'uint256'
          }
        ],
        name: 'earningsPerWeek',
        outputs: [
          {
            internalType: 'uint256',
            name: 'rewardAmt',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'bribeRate',
            type: 'uint256'
          }
        ],
        name: 'expectedGvAmount',
        outputs: [
          {
            internalType: 'uint256',
            name: 'gvAmt',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'genesis',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            internalType: 'bool',
            name: 'toUser',
            type: 'bool'
          }
        ],
        name: 'getReward',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'gvToken',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'lastBribeUpdate',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'lastRewardUpdate',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'periodFinish',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'rcaController',
        outputs: [
          {
            internalType: 'contract IRcaController',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'rewardPerToken',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'rewards',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'rewardsToken',
        outputs: [
          {
            internalType: 'contract IERC20Permit',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'userRewardPerTokenPaid',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'from',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'withdraw',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      }
    ]
  })

export default BribePot

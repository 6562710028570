import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { injected } from '../config/connectors'

export function useEagerConnect() {
  const { activate, active, account } = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch((err) => {
          setTried(true)
        })
      } else {
        setTried(true)
      }
    })
    return
  }, [activate])

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true)
    }
  }, [tried, active])

  return tried
}

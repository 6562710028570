import { Step } from 'react-joyride'

export const thirdSteps: Array<Step> = [
  {
    target: '.third-step',
    placement: 'top',
    content: 'The third section is the Staking section. Here you can stake your $gvEase on one or more specific vaults, which will lower the charges for that vault in case of a hack.',
    disableBeacon: true
  },
  {
    target: '.vault-0',
    placement: 'top',
    content: 'When you add a stake, you can choose the vault that you want to stake on.',
    disableBeacon: true
  },
  {
    target: '.staked-0',
    placement: 'top',
    content: 'Here you can set the percentage for each stake. You can choose any amount of vaults to stake on, but the total must be 100%.',
    disableBeacon: true
  },
  {
    target: '.amount-0',
    placement: 'top',
    content: 'This will show your staked amount.',
    disableBeacon: true
  },
  {
    target: '.max-fee-0',
    placement: 'top',
    content: 'This will show the Max charge that your chosen vault will be charged in case of a hack. This will be lower if you stake more.',
    disableBeacon: true
  }
]

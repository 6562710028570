export interface ProofDetail {
  address: string
  easeAmount: string
  depositStart: string
  vArmorAmount: string
  proof: string[]
}

export const proofDetails: ProofDetail[] = [
  {
    address: '0x005b588fd48a60b91a0c2fbffd01c3ee4b7dd806',
    easeAmount: '62554827303930391890018',
    depositStart: '1649894400',
    vArmorAmount: '53153975010002013879640',
    proof: [
      '0x3ea4bfd4dd9815d48c367fa892fcc5deb1fda61dc66d4c0477e8387926308dc4',
      '0x511e719f1c4e6b56cba2bf8c7c0e3830044e78522dcb7dd733f7537c9c56fec9',
      '0x434d42cf471eb90a34d5789e5ddec5989328947ef9ff6747c9ed1337431bffdf',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x00d7938495a8e0881b7e7e21aeeeb8e632cced95',
    easeAmount: '123914583635967779548840',
    depositStart: '1649894400',
    vArmorAmount: '105292476469632826318508',
    proof: [
      '0x59a53794c2919f46a73b198a2c2cc54b38f14b2aac46f1e6b43c672820cc566a',
      '0xc4c666d7e1a348ab20e521141f7724d529d81e60f64629e2d4363e4da86fedc1',
      '0xf3de3874d057c9ace2b710ba5fd19b2e88975f27c8b2efa39f1df0c271063e96',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x017b8b00c792d7c6ed3f22bc2e94bf5d9427ba5e',
    easeAmount: '398065903134065522506085',
    depositStart: '1649894400',
    vArmorAmount: '338243841114282322858312',
    proof: [
      '0x61b5812345919b89975e89756d34eda59cd89ef3875f91c61a2c456728c153ca',
      '0x411c408cb1cfad242dfd84f344e779c72ed0bdcbef74efb6b0f7611317be5f7a',
      '0x2a8d4821219fb0d23cf145e7606fd2402ef10965c7ba75fa74d4bf90183d75d4',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x020891d1e23ab9a8a5cdc6def299761c5aee0b22',
    easeAmount: '119865806220131696188373',
    depositStart: '1649894400',
    vArmorAmount: '101852156627699701810575',
    proof: [
      '0x22827035983a4fc36dea2ebd1388199f416323bcf4bdddfcc08a064574562e77',
      '0x1d66ff49b47eb0664fac83d467b575acc40536fdf3c9b044e0ab7e3b21036102',
      '0x6df905ddf0d7a764bf5c3cfaac6a96ffb731ba67f74456b5c953254b7a2ef752',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x02d4bc45e55f325a0a20f5831b60e1f55918aba8',
    easeAmount: '42073647620359684906977',
    depositStart: '1649894400',
    vArmorAmount: '35750743956601331004076',
    proof: [
      '0xf58fa34e19ffc260d94596a0b8283d207f6d4e34ad78d179be93eed99580ea72',
      '0xb6429e344954a33b81f9fd826836f529ce92d17443d1d641a2bc5c2c1202d022',
      '0x4538d0f069b5f1bb621e2651fca0933db5944a5f86eca93e81bc98d50b33785e',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x034a98b99b4963f5aa37eb93fb43d62e25fac62d',
    easeAmount: '77811120683132136396493',
    depositStart: '1649894400',
    vArmorAmount: '66117525098364209285318',
    proof: [
      '0xd09b3a79996ea5df61af67b9eb02d4b07cf180b463a0e51aed1e81e743e7e13b',
      '0x1ba1e98a491034765c2930220b7a4ee36b569cc4816cd79aab6138ff7f0f9468',
      '0xba66c3211a6ae7f53431dfe489327ce862386e56acd041e9900507d523bbbd39',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x056e29a0e7f9cef91d1f3fdf9c58b6dd547b5332',
    easeAmount: '344909014776498543650101',
    depositStart: '1649894400',
    vArmorAmount: '293075465832235100673854',
    proof: [
      '0x114a408d6e8e26cc056e12de95e77437d9f59c6a31a77743b915bf0888b9422f',
      '0xa789fe88b434273422361dcc5c2c5b6c910c672819ff65c9a7a295633927425d',
      '0xf6ac62716d375ddddc544d5920b999bc1511c76700bea73498aa28fe103793ed',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x08003f6a0843a0d2bc4223be96c1e1bed9e0ddac',
    easeAmount: '23301880945682049766008',
    depositStart: '1649894400',
    vArmorAmount: '19800032241396722419021',
    proof: [
      '0x8b132a649817971885caea73703a9234c0f135bc3d1b89a86cc13ef59be648c0',
      '0x7f1a64036dfa42296b38dc6e8f9f4d481ff93000b45d743979efb620ccc7d0b2',
      '0xc3ba78039c56948fca407ace5e54e1717c45ece4f2e76d4481f4d68e89546780',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x089c47e09b96f96edfa79910e76268bd17ccf4c4',
    easeAmount: '80379880415531682982509',
    depositStart: '1649894400',
    vArmorAmount: '68300246984227141039316',
    proof: [
      '0xdc872f892c2c9fe235aacd32501f6fc4a7daa7538c06386ffa9600cf8eb1f07d',
      '0xfd07dddee30e2812b5b4461d9357ba95539654ef14e43f42c1c7f08964e5b0ee',
      '0xb2f04622642a3fa83e35f387f3cf48ae54510069b1eca4e043e1b479ef60e938',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x091f1ce4d8bc81715c2f4b605195ca754fc6893e',
    easeAmount: '68766356193531681376883',
    depositStart: '1649894400',
    vArmorAmount: '58432024132695822324591',
    proof: [
      '0x82517f991820089546777f522afc1dfba6ce9a3dcd296f075c28f223888d32a6',
      '0x8b96b26eae3f1402c5b4436ccc8be856cfc35f7f6275cce7fc95f56ad2bbb1ae',
      '0x1c164e63e9dc26543ac0dbda820f2e49c2e8dd103aaaa5aaa03656d0b485240a',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x09fa38eba245bb68354b8950fa2fe71f02863393',
    easeAmount: '1898246550544186360532',
    depositStart: '1649894400',
    vArmorAmount: '1612974634558835783504',
    proof: [
      '0x39dfdebeabb92f4586db8c5a9f2390a3a9230f1f8c4cb85a224e0ac0e8e6666c',
      '0xdd6e7c5f5c4797b6a46cf9b341e27b5fa3dd148d7f44aeb9a23e1cf509bba479',
      '0x434d42cf471eb90a34d5789e5ddec5989328947ef9ff6747c9ed1337431bffdf',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x0be38e7a382aa154ab9e3b7fad1e654c389c8d30',
    easeAmount: '210979422753620838001244',
    depositStart: '1649894400',
    vArmorAmount: '179273054502797723138798',
    proof: [
      '0x534f38e8b5d78547ea8cde62c779e15c5045d936c0a801864c33733b86a16627',
      '0xf0b899f93640365f15e7efb30e7a84541ac5a182b5c2c009f708c8319af2288f',
      '0x341e44a8619acf4451ef9082386e5ac8473b7503363957aa0de76fa176188225',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x0e7a5ebd4361250ee07c990f14d723d7e6a896cc',
    easeAmount: '107041598449973029945925',
    depositStart: '1661527962',
    vArmorAmount: '90955193935657259519214',
    proof: [
      '0xe785050f33117b2c751a238ac83695b0b65a94fde5b4c3342b423ca71081d9e8',
      '0x72327354197e032cbee8514d0f03f55db0ebca87fd4fd523b701b05a2b6aab4a',
      '0xc2dcfb68f776f22eb8f9baf45d648b27e25341bcab6369eff887a706a7576aca',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x0f7739f2f93753ab14e0faf5c9a3ee4519674aee',
    easeAmount: '7914809501954632215557',
    depositStart: '1649894400',
    vArmorAmount: '6725357651964770354988',
    proof: [
      '0xf4f56e820f7a229a9e0e99747bc57f3d47b432e3a5f0bcdd4b403edc44677165',
      '0xa1e1645cef48f8fa779cb2de5416dc9f6959cae257e8dcbb228935ebf4ea8312',
      '0x2539f701226ecf9e65911912ac2091ab4972087c9f41b88b00f9da6529698802',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x1120567c87164ff8971639abd191ce17b79828c1',
    easeAmount: '22361352307046792908553',
    depositStart: '1652720063',
    vArmorAmount: '19000847943255936885536',
    proof: [
      '0xb3dc0afd243e6c062d2d2acd1decb324a7e31c172b1ba4ba4b5a94f14f9ca133',
      '0xab4893671631c6aff1812e1f937416a86de1ead63e652601eb6535f433cde064',
      '0x721fd24dfba9e11dadce4ad81aba9b49239b525459c41f379f225359785007a2',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x12531bce59f0915dfd4a47b77e6e557684fed798',
    easeAmount: '27700488536057901716655',
    depositStart: '1649894400',
    vArmorAmount: '23537609148158529871022',
    proof: [
      '0x0d7503805b1bd79deb238d2af780ec13118034c5c9f5784ab8077fa36aec344c',
      '0xbc3c17866175e754e604bf44cb7d8de3e142949f4d52dad79386422417435d65',
      '0x423857ca2f20f5e224db279c10334cf22462642423f865fd3c6cf8ced8d8a7bc',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x12d618852c82e30805615b28c89cfc39d9faaffc',
    easeAmount: '18218727416452256631314',
    depositStart: '1649894400',
    vArmorAmount: '15480784194368581583704',
    proof: [
      '0xd9344264c28baae5ec00fccc60afcb37b060669fb990c28c70883d513d40ce69',
      '0x915deb4ac09542f4c650054db57183d77f7b562ab06507bcf2e6de3aafe48e34',
      '0xb2f04622642a3fa83e35f387f3cf48ae54510069b1eca4e043e1b479ef60e938',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x1445f581b7e5f11deebdca9a240866fb87bf5be4',
    easeAmount: '7641843879194878996138',
    depositStart: '1660739606',
    vArmorAmount: '6493413795413672030304',
    proof: [
      '0x9d5c7be417c594c4c34c45f34ef85b2719ff860a3d33e17b5aa08cae7579f2a7',
      '0x991b00677396777dca751ada7015beba8f44eb3b1b35886d9aa2e20ad800ded3',
      '0xce9e0607dd3e91f01706b6d8a3649dbb9e06de2a4162aa62319053aadcb0d9ec',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x17155d0de1649bf8acfb80366439574f5d9c1f81',
    easeAmount: '15941950733611835793276',
    depositStart: '1659925358',
    vArmorAmount: '13546165618649946340395',
    proof: [
      '0x70777f41ad83df8a225babdfad23e96acc587dae9584fb81c35743d121eb5453',
      '0xa8c4dd561b1d51783fa2fbb62ee3c1a2239bbdb47a6200e82e8d8b96fb135087',
      '0x5859fb009e1565b7176344a5bf8fb2ae09d0efe0ec5873202347ee5129994a26',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x175c498b55f46af1dd79ad67919815699a759454',
    easeAmount: '197707746409062350687',
    depositStart: '1649894400',
    vArmorAmount: '167995869620933730594',
    proof: [
      '0xb5f934fe72577fc79c785264ea8c4b7b4b0b9437e5b377f122a307fd3b49cf47',
      '0x36b2240d174ac7bc6b6772551a565d4ac166b57cbc80458bf0a5aaabd9462ef5',
      '0xb2f726116375c96740d6d91e37c5ef35d6a67aca027ba5dcf55cbf7762062412',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x18ad72b9f727c34c896d5acd64c3f627ce5562c9',
    easeAmount: '5604657268079323591440',
    depositStart: '1649894400',
    vArmorAmount: '4762379263229082676229',
    proof: [
      '0xe1b63880a019a824787fe4a12b969524f0a8aa2e3f7a1da80e95db82c3c00e2f',
      '0x9078d0e94fe13d30f8d4325462e7ff6f7308e9df3fffd4f9590518cf2f13b626',
      '0xb60325b0d3c278b7f855e51e8e776b5edef92e5c727327074073f48e2e95133c',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x18b8893f6c5f7c653887ff78063bc3bc20404a86',
    easeAmount: '6971345547712928942447',
    depositStart: '1649894400',
    vArmorAmount: '5923679162742080603127',
    proof: [
      '0x7823a27fd8ae2dd0fc267cdf34338053897ee2c9ced62136ef7beb0b4f45d9b4',
      '0xb8ad10c3d1dfee242bbb0841f9fd0ba6661cc1085a9d8221fb4239b4de44d314',
      '0x79358fff1e07a46cc3b7564a5b5976adaea3dea834e69fd89c4893b943297cad',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x190757f124d750c5c4570d75a80cb214e8b53a0d',
    easeAmount: '117722857781048601110067',
    depositStart: '1649894400',
    vArmorAmount: '100031254345845127828163',
    proof: [
      '0x169a0ec3f881e254756d94a57267772b0e70f2a086355ae2aa402d180d0a1ec9',
      '0x21795bc9875f0c3a5b0ca21cba0037afa063e54cdb681077a73bca66f4be4626',
      '0x20274533ce2604d78de5b88b11f80e32bb57ad812996bf4b0cc9c60190098346',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x191458d229eafe1e246e4c28d346fcb10acb12a3',
    easeAmount: '461774729994496516074011',
    depositStart: '1649894400',
    vArmorAmount: '392378390545659685126138',
    proof: [
      '0x799a0c7f461b03b5c07b842978818da22cce4cb956f3b0c21d55ca181243f29b',
      '0x3453eed141113395ea321d40df59a89a2019d3bd3f6d333b6e5c0603c9a6a236',
      '0x79358fff1e07a46cc3b7564a5b5976adaea3dea834e69fd89c4893b943297cad',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x1940ac3e9cc28a258b2dcac2935bff8625d3cb7e',
    easeAmount: '125893022156885186215558',
    depositStart: '1649894400',
    vArmorAmount: '106973591680593719483382',
    proof: [
      '0x7c85a74ee4cbcf99f6df947e35cfd8fa185fe419a9b51f898e15bae34ef3629c',
      '0x990c0d4b18bbdda7f399f0a569a052b037775af2082ec6157fc8a124a9004eef',
      '0x1cd8a25f7810e46bd47017192ceb95fad0bdfb81a22a51d1f903dd7515281f9d',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x1b73faea4bf6cc125b53cf065ee4240fba43c54a',
    easeAmount: '16876592592725403894989',
    depositStart: '1649894400',
    vArmorAmount: '14340347813115104341563',
    proof: [
      '0x0c7868e165728dbf094af5f5461fe64baa29450537c25800506ee786ab3548bb',
      '0xbc3c17866175e754e604bf44cb7d8de3e142949f4d52dad79386422417435d65',
      '0x423857ca2f20f5e224db279c10334cf22462642423f865fd3c6cf8ced8d8a7bc',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x1c012b03f1c2dea274d2eeeb566b0eeabfe3af1a',
    easeAmount: '1271760253403501029785619',
    depositStart: '1649894400',
    vArmorAmount: '1080637828311552099822880',
    proof: [
      '0x02140ba063824f761e144c271c0c346043aaddda70d7938f95625b0500fcb9a1',
      '0xc8ccc02aa374be43b156bd7d94a64b4d34ba46d3ebe393386037baa7c97e29a3',
      '0xa82171641f2e3dd06d00a5c8d8f601698c9d51e2c87b78d0a7ba1445381dc6cb',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x213d67ad32035a75bc2367740726db785bbfa7c3',
    easeAmount: '80014002462467881813318',
    depositStart: '1649894400',
    vArmorAmount: '67989353829980641422094',
    proof: [
      '0x472f506459fb24db74e28ed83634795f4647cc76c2228ef904dbbb38d429de98',
      '0x2d0735e9d71dc3024da4518e99630b3105008cb157e5b6a29faa399adb95cacb',
      '0x8c6bfd6e49629ff86ec70aa037e638af7f0d49f147635cd608731c48f9d7e058',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x231bc628c6e83d5f6ba5b15f88279afdbaed6e9b',
    easeAmount: '128875062043951594242612',
    depositStart: '1649894400',
    vArmorAmount: '109507485233937409799631',
    proof: [
      '0xedc845286af80fc78fa2e60325d4850c88da0b7536136a30632c27c9ca4e7605',
      '0xbe84262103dbd9807159c1f87516550578dc4b5f1257c1534cbcb17e74c0db87',
      '0x2539f701226ecf9e65911912ac2091ab4972087c9f41b88b00f9da6529698802',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x238f24101876377e9178d125d0747de7fad9c3b2',
    easeAmount: '347204238941496775796180',
    depositStart: '1649894400',
    vArmorAmount: '295025759569214266657501',
    proof: [
      '0xae18727fcb0de9e1ca7ec1c7182a8bee95aaa8eaf9a0f5ef13cd29adf90952e9',
      '0xed2ddf5814edad69488dd738f79a8e03b466a84d2c291d2f2a273a637d7d3221',
      '0xd79ac94d01132006409ab6f24ededa22b4f8af889eb7b2e1e719a8a36b07bec0',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x24b88908192740c496e372a91bbc0f062b8d49b1',
    easeAmount: '1326237154258424668821',
    depositStart: '1649894400',
    vArmorAmount: '1126927842231597412767',
    proof: [
      '0x76845667e5626c9ff66345c15e3fc20491da0572228d12a354521c7a8958ba56',
      '0xb8ad10c3d1dfee242bbb0841f9fd0ba6661cc1085a9d8221fb4239b4de44d314',
      '0x79358fff1e07a46cc3b7564a5b5976adaea3dea834e69fd89c4893b943297cad',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x273034e4bf3037b2eb617c0bd51acb76cfee8659',
    easeAmount: '26446484327159430766987',
    depositStart: '1649894400',
    vArmorAmount: '22472058953952516291992',
    proof: [
      '0x5c49ea499ff30786e81f3f735c6f2516d41fa7d8f50a95f042ba893077c6fe0b',
      '0x2407e2eb14a373228f0da36810249161f61f7985ae60fc7a49686289c21dcfa6',
      '0xf3de3874d057c9ace2b710ba5fd19b2e88975f27c8b2efa39f1df0c271063e96',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x27c7a7dbe592018a3afd0b3d53ca84e14c678b13',
    easeAmount: '21996664409635300503711',
    depositStart: '1650195429',
    vArmorAmount: '18690966000951498808406',
    proof: [
      '0x8faba56c4ca0aad9e400ffbf516a049c8f09254e03bdd214bb14896290209e7b',
      '0xd30d4da1385d7090392f7c2d9131bfe43b7d003797b36eeb8d0d5a3bd58629db',
      '0xa64e27b26017cc9aa1d9a77186da62ad0d648a82a4cda52e61092f7ec22a331f',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x27d98e8d1ba54b3e4f5c6125aeb4fa49d50e3142',
    easeAmount: '74808341011214259630686',
    depositStart: '1653992890',
    vArmorAmount: '63566008572450366537676',
    proof: [
      '0x20838ad5c21b77df5d1f58624a7252648ee4e8994a6c91487aeadc2af5e3bd14',
      '0x57dc2dc9d6f11a65f3024f404fcded8db2c676336cf16bc97236c4a2bce68a29',
      '0x6df905ddf0d7a764bf5c3cfaac6a96ffb731ba67f74456b5c953254b7a2ef752',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x28bb088561ba181ec9e88d427f0bd4e75ba88728',
    easeAmount: '5285747165203449064830',
    depositStart: '1649894400',
    vArmorAmount: '4491395545915929231378',
    proof: [
      '0x6f13d4ebae3068e28987ae4e5957aa3899a2769cd5b6afd6d680c3fbb37151fa',
      '0x8d87e001a53d191b01f0ee4ce5ffb3b66d7971c6e15429732e53f75d6a22bc9e',
      '0x5859fb009e1565b7176344a5bf8fb2ae09d0efe0ec5873202347ee5129994a26',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x290789aed2d6fae51191bce9cce498d0c336dd89',
    easeAmount: '19064429306214334413570',
    depositStart: '1649894400',
    vArmorAmount: '16199392478520947043969',
    proof: [
      '0x241bf15d47e8c81d1ec07dd157e8577b374fb5da16fa234d1557f0f60a29720a',
      '0x3606def0a23a02f2aa8bbb7d323996b137a8106d789f304da92443d6880ab171',
      '0x6bf800b804e71b8c80e24bfbffcb69bb0d5bc93f33a68f43d64abe96df2511ee',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x296241848709fb619807331a212903d724cfabc5',
    easeAmount: '35205967104003024051515',
    depositStart: '1657469333',
    vArmorAmount: '29915150857295252186890',
    proof: [
      '0xab21ed7b5b21ab4ec4c20fab865f461a13b2dcc0e1440953e3d7a9acb43b3e65',
      '0xd4b37a4e3472a9ec2fa79cbacea32bb017ee2b1b94ed5e8df92f2bb2ca79ddad',
      '0xd79ac94d01132006409ab6f24ededa22b4f8af889eb7b2e1e719a8a36b07bec0',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x2b941d864f977a43f17930d3b5097f60cbc05c0f',
    easeAmount: '1883298519683788520424372',
    depositStart: '1653303788',
    vArmorAmount: '1600273020741856971776307',
    proof: [
      '0x5dadba88a457aa8c1a7b0da8890fcb17d7faaab3e5acf32a848ad81d9e9b6059',
      '0xaf82cd0d84bfe892827b5f2f4da1d2a6f82d7ecb1affc7e96f5a7ca1808bcda7',
      '0x0dc0cb302821c16615ebe0bf2d98f46be28ae17c8963457f88368347060fbf00',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x2cca6b8821d53e2d7c7fc321d21e83fdbe5cb362',
    easeAmount: '39133589043768612625494',
    depositStart: '1649894400',
    vArmorAmount: '33252522686661945217549',
    proof: [
      '0x68d7c05f01b52b6ea63de072c8b86ad709c43dc6c5cf1aaa56179f631e8acb33',
      '0x176a4c679bede0bb0abbdea99b5ed65d1e6eb6b7be0458ce547684bc6d245e01',
      '0xe612796e9657f5eff9650e6fd598deb7cfd20a0a7f2fcb2fdc547c08db843a7c',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x2ccb1cb25695af8f4ff38352c2696a0af8297d01',
    easeAmount: '683139895624942803727976',
    depositStart: '1649894400',
    vArmorAmount: '580476399750241084106356',
    proof: [
      '0x095c3817df72f6646bed1522ce09a8b980cb330af458b6686a7038d7962a68c4',
      '0xa77b984d0410eaa235182d4ce0aeb5f150bc4713a53d856e38b8096454bfc323',
      '0xa82171641f2e3dd06d00a5c8d8f601698c9d51e2c87b78d0a7ba1445381dc6cb',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x2d929ff2a5fcbad9116ce8bdae7f0ac6cfbaaf30',
    easeAmount: '6824851144239191187995',
    depositStart: '1649894400',
    vArmorAmount: '5799200202493093428357',
    proof: [
      '0xb0d61193a4de77a3bf1e54128dfcec88f52064b06c174550fe995be6fc12013e',
      '0xae95c64772cb7126147c587a88706f9a31226dd45588eb02ceb3d74e403251f5',
      '0x6c179330959e06744a99875342ae84424dc0a7823ae9ea823cb678cbc2c37afd',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x32248cb2c05ce6eeeb17649b61e6338052b30afe',
    easeAmount: '1552045731933454497997',
    depositStart: '1649894400',
    vArmorAmount: '1318801499502944328081',
    proof: [
      '0x6c3bbacc111160f2f2e31f44b8a0f13a01782b60ac3ccc11e48f426c0036ea33',
      '0x176a4c679bede0bb0abbdea99b5ed65d1e6eb6b7be0458ce547684bc6d245e01',
      '0xe612796e9657f5eff9650e6fd598deb7cfd20a0a7f2fcb2fdc547c08db843a7c',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x3249cc1fca7f61b4bd9d76f5e7ecb6c7dc7de298',
    easeAmount: '58843037933386355736960',
    depositStart: '1649894400',
    vArmorAmount: '50000000041999944993013',
    proof: [
      '0x9791e86560013c3f92bf2c8ff62fe861b9eb0ef4513afa0d8261537503d6d9c2',
      '0xc6dee9be02945aba0b8f5ca3637dcc2b8c7961d3f7ea47baaf24f69cb9131f1e',
      '0x8b08f58c10e6f2e08822e7bb513faefba36b0b350753cf9da0ecb773fe15e080',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x37fcebdd4d2eba610ac04da3ad03d30d807b20e3',
    easeAmount: '2642982319643467826890',
    depositStart: '1649894400',
    vArmorAmount: '2245790168800916954373',
    proof: [
      '0xa40ad0d326038771623d90b084319d0b81b6d8e15ae6eb2f08a5553044888511',
      '0x08ddc05efef60a19c06da617468ad175e1c28ef26b8a7ae3417c886a82467539',
      '0x85c6f7cdb70906fb722ce51c282b9c1fc3bf100f7a3a639792868f19bb8e285d',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x39a875fe468cc1288273496dacd0a2ebd1443b2f',
    easeAmount: '634562211206548657882397',
    depositStart: '1649894400',
    vArmorAmount: '539199057378666020857527',
    proof: [
      '0x4e702dbeaee2f2e09640de9d9b7b07ba61f85655841fe51bb7dd3ae3024f3df9',
      '0x916fab2793b19cb421700793debabc8735db6f6a3c8d858f0e995a37c20fbb7d',
      '0x341e44a8619acf4451ef9082386e5ac8473b7503363957aa0de76fa176188225',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x3c229864378b3b0adf98ae247ca33986aed8ec1e',
    easeAmount: '88052241297096521822054',
    depositStart: '1649894400',
    vArmorAmount: '74819591631843023518972',
    proof: [
      '0xe802ab6866172341342cc4bce9536c49c68e669e5b63d29b4737ce5dac3b2d6f',
      '0xa26203c6bc3bdd842d4315a1fac58ee780b166f559e8fcc5520f3b95d24e947a',
      '0xc2dcfb68f776f22eb8f9baf45d648b27e25341bcab6369eff887a706a7576aca',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x3d1aee8cf3ec85874b714eac226c6d7351c0d572',
    easeAmount: '81203392279862410737',
    depositStart: '1649894400',
    vArmorAmount: '69000000000000000000',
    proof: [
      '0x2e09c2b31600a023d389fb0c277c5eb1d9ba6728dd04cc7c85117d0e1e1d8ae3',
      '0xc03d319d694a34d297ebb86f2e3fc16c9a4c8b841a3b5ee40422101afd8346f5',
      '0x4c6e3a872452d9227711608dbc282961a771d5174eeb78542dd9d96c7a5acbf6',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x4334703b0b74e2045926f82f4158a103fce1df4f',
    easeAmount: '203980778587175117764935',
    depositStart: '1649894400',
    vArmorAmount: '173326179206991756810831',
    proof: [
      '0xb83a771e307b930b6e4c00acbb8a2b35669517811c797fc94fd5ff53b1f009bd',
      '0x36b2240d174ac7bc6b6772551a565d4ac166b57cbc80458bf0a5aaabd9462ef5',
      '0xb2f726116375c96740d6d91e37c5ef35d6a67aca027ba5dcf55cbf7762062412',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x4578badb9501b797762f42623277c7cb13ba85cb',
    easeAmount: '79999999999999999959585',
    depositStart: '1660922812',
    vArmorAmount: '67977455682832379391816',
    proof: [
      '0xd0c8007480be7adbd4461a2ae56fa30674fb3dc1c2f414701b0f77577f434e77',
      '0x2052ce59fbd740b38768396608ac67c6d052fa5412910cdf0b1a403276239251',
      '0xa59c353926ba4fc36132f3b84ea91726d582fbed1a28f667b346c3e88a53d98b',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x475bf0d87b359bb02767a8d4fcacb2794f83ee09',
    easeAmount: '27892237616115190139873',
    depositStart: '1649894400',
    vArmorAmount: '23700541830556257393517',
    proof: [
      '0xa01bf4e6d59290261daf43d7461ebd117ea570738d0500f683f9faf4fc3ac9cd',
      '0x6b3b2466f17c16c9720798439f053d1416bf58a3221989aa44360af24be57f77',
      '0x6f630ce43a180716158b272cb7c35a7189fe6b98cc0e4eade7733b29f2b01419',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x47e2bc7475ef8a9a5e10aef076da53d3c446291e',
    easeAmount: '38249628686169536991240',
    depositStart: '1649894400',
    vArmorAmount: '32501405486236047389213',
    proof: [
      '0x539c459647589d5cdbb1227ede34198c408a636b503ea952231e92f8a4df31cb',
      '0xf0b899f93640365f15e7efb30e7a84541ac5a182b5c2c009f708c8319af2288f',
      '0x341e44a8619acf4451ef9082386e5ac8473b7503363957aa0de76fa176188225',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x498509d337bd0eafd6b0d45b43fb77a1a9aaa84e',
    easeAmount: '105243490133948191316952',
    depositStart: '1656307539',
    vArmorAmount: '89427308581088374185708',
    proof: [
      '0x1310f631e04bb4481a09ff141c7917b87cf43ff6ce89e1ace81bbe220ebfda05',
      '0xc60c8696538c5ce253b85eefacdff49017ed937766c2e74ef6005a0754b173f3',
      '0xf6ac62716d375ddddc544d5920b999bc1511c76700bea73498aa28fe103793ed',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x4b3f1048c55faa0c0873e249e541139360501f2a',
    easeAmount: '2857440427553774697838921',
    depositStart: '1650443115',
    vArmorAmount: '2428019125379629072719035',
    proof: [
      '0xe0de8c2732618f0eb095a654f1d1d1e5248f01050ea70a61e5e0dccdbda89689',
      '0x0c141a7c58126858aed11d50165c2e32fdfac7d60a83dcfd544e53796768683c',
      '0x25eb5ea8b590a1debfcff27ac3f53b3ea3b5d80f9b48848945264d027069cfe0',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x4e6a82ac98e87c5acf7738fa57b5fd9ea14af932',
    easeAmount: '12809828066620360595413806',
    depositStart: '1649894400',
    vArmorAmount: '10884743996292349290360782',
    proof: [
      '0x2cb75fa9154ec2a4f55b40e1a928b503be2e8f585822862dda71277dbf5b9c02',
      '0xc03d319d694a34d297ebb86f2e3fc16c9a4c8b841a3b5ee40422101afd8346f5',
      '0x4c6e3a872452d9227711608dbc282961a771d5174eeb78542dd9d96c7a5acbf6',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x4e70812b550687692e18f53445c601458228affd',
    easeAmount: '221748034512012460029416',
    depositStart: '1649894400',
    vArmorAmount: '188423339859943900000000',
    proof: [
      '0x6017a974f196f11a514708334c777ff2a1cf24f49fed77bcc0647cbb76edbd18',
      '0x16397569c3cc0266d8b61e91aea617ccd2931a5227f2f1c5f4b32fb1a639ca43',
      '0x2a8d4821219fb0d23cf145e7606fd2402ef10965c7ba75fa74d4bf90183d75d4',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x513732d5d14b9c8144f22c2851ddc7f4d407cac8',
    easeAmount: '676556422926233090963',
    depositStart: '1649894400',
    vArmorAmount: '574882303205045129291',
    proof: [
      '0x864860d640df81d97c0dfa48d4be6aeebb746644e1e7eba936bbff55e174fe48',
      '0x3b98fe6a0b9f7ba17537b018cde37196e3952c62079ab620f9a9a064f64baa4b',
      '0x1c164e63e9dc26543ac0dbda820f2e49c2e8dd103aaaa5aaa03656d0b485240a',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x525087f76e899acc5317d5d2c338cb3743196fab',
    easeAmount: '94306857436953574284002',
    depositStart: '1651524346',
    vArmorAmount: '80134252775096284910729',
    proof: [
      '0x0b305445ee353f9ee0c863e331e377837e3b18806ecd934ed9fbacfc97a6c8b6',
      '0x28a8c6e3c827c9ad8373eaafe6c87b08a5bab423599b9b1f0b87300a37657f1d',
      '0x423857ca2f20f5e224db279c10334cf22462642423f865fd3c6cf8ced8d8a7bc',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x54f3da7f2e7bfcd6c7816afca9cf2656445fa7f9',
    easeAmount: '28292931715730210897594',
    depositStart: '1649894400',
    vArmorAmount: '24041018898043163597820',
    proof: [
      '0x11ea4ca1a03f33703c418337e37d884c19c5d7f0559fdea035bb7458476f8c14',
      '0xc60c8696538c5ce253b85eefacdff49017ed937766c2e74ef6005a0754b173f3',
      '0xf6ac62716d375ddddc544d5920b999bc1511c76700bea73498aa28fe103793ed',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x5538793f0ed24fd4ea2fcd5225a40995a8f5ac3a',
    easeAmount: '44820957193138961106684',
    depositStart: '1649894400',
    vArmorAmount: '38085182890734136071567',
    proof: [
      '0xb5794fab0c39f4aa08689320736edfda839d78b546e9db8df3f28d69f1e7a786',
      '0xab4893671631c6aff1812e1f937416a86de1ead63e652601eb6535f433cde064',
      '0x721fd24dfba9e11dadce4ad81aba9b49239b525459c41f379f225359785007a2',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x5667cb11e19c1aad2520b9a73c351599a17f697e',
    easeAmount: '7061164595503193238396',
    depositStart: '1649894400',
    vArmorAmount: '6000000042000041787332',
    proof: [
      '0xb2143c1be003fa0844ecd2a346ed9b46dbaa17cc7b4eec5b5709d55dadd10ea1',
      '0xfcc3d2c00e0c517cf2720da34ee85d0c8353cdc728f4141671a0499d9151b534',
      '0x721fd24dfba9e11dadce4ad81aba9b49239b525459c41f379f225359785007a2',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x569d4c513673f8a611cf4dde30563498642a8579',
    easeAmount: '47660087162610151266516',
    depositStart: '1649894400',
    vArmorAmount: '40497643286703249514574',
    proof: [
      '0x70162cb8920f1f40f5f1e3e4e12eb02181456d085d8b7003f3599fb8ca1bd8ab',
      '0xa8c4dd561b1d51783fa2fbb62ee3c1a2239bbdb47a6200e82e8d8b96fb135087',
      '0x5859fb009e1565b7176344a5bf8fb2ae09d0efe0ec5873202347ee5129994a26',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x5792a454546099a592ecd0fdcc124f2a1577a701',
    easeAmount: '509858612336281662898',
    depositStart: '1655991853',
    vArmorAmount: '433236140307500012994',
    proof: [
      '0xacc7433c561ea406b0f8f60974f7803d75d50643f7baf4fa8849ba3c2c0d504f',
      '0xed2ddf5814edad69488dd738f79a8e03b466a84d2c291d2f2a273a637d7d3221',
      '0xd79ac94d01132006409ab6f24ededa22b4f8af889eb7b2e1e719a8a36b07bec0',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x579f09279e221401de305336b546103ab43c0864',
    easeAmount: '10141025244351153519035',
    depositStart: '1649894400',
    vArmorAmount: '8617013676579561749492',
    proof: [
      '0xeda956859d16f7d898909c77ea61d3ce593c713cb7d97a6de4c966e72cbc10b6',
      '0x5b1c0b795df3412240d3f099c168540959d81dd4f9934fdda7495518f208c2d1',
      '0xa81744561bca2ffd687cb987d0babb34cd239beb8b37cac7ee287b37ce486cf1',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x57f580a7aa2d1852709b15c376957d512f19f4ae',
    easeAmount: '22880888073353060295263',
    depositStart: '1649894400',
    vArmorAmount: '19442306937377570023060',
    proof: [
      '0xcc37cf0d30d6efa60de70c9ab238c994a759ca2a976877e439f49f3b8c1000e5',
      '0x41cd074b0fbf3b3f0aef7b02e4b81bf46072753757a4c4bd46542b2b6247dde4',
      '0xfe5a2d4b6fd37c254604c8c7363d69560f2945552c9824d5d639700f61743275',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x595742690486deddd75c6808e75dbbe1a989970f',
    easeAmount: '58520158524009853333155',
    depositStart: '1649894400',
    vArmorAmount: '49725643532727566423209',
    proof: [
      '0xbd6d9074bc82bf07b66f84b8878ff3ce3221d62ae9603ca5531420e9ee5f93ae',
      '0xfbacd6bfdd14f737a5dad0b4efa0b6bdc31e81b7e421497ef8ea083f907f6635',
      '0x640f8b5a1aeddbaa9a59b2243b155157dc1d51a8abf119b18ab8b97a7f98c77e',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x5b6e5dda3cd43f209efb360d08eab8140d9c49e0',
    easeAmount: '787908505479336032500397',
    depositStart: '1649894400',
    vArmorAmount: '669500193916853226821706',
    proof: [
      '0xa0cc6c6d84fe25fb05d44da1bac3c6f7c72beb08df1a4dfd73c34a265f765375',
      '0x6b3b2466f17c16c9720798439f053d1416bf58a3221989aa44360af24be57f77',
      '0x6f630ce43a180716158b272cb7c35a7189fe6b98cc0e4eade7733b29f2b01419',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x5c2ba7470a1680eb2e5c94adc21bfe256df830df',
    easeAmount: '65081590494924399731626',
    depositStart: '1649894400',
    vArmorAmount: '55301011670462105070583',
    proof: [
      '0xe30ab0c4eb403916a7d734fc96af73578a6441a1d84b748026727b9b01316f95',
      '0x72327354197e032cbee8514d0f03f55db0ebca87fd4fd523b701b05a2b6aab4a',
      '0xc2dcfb68f776f22eb8f9baf45d648b27e25341bcab6369eff887a706a7576aca',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x5ff6c8d2ad276db5a0cc0c8b8dee5eae36554e75',
    easeAmount: '879228417309601573180',
    depositStart: '1649894400',
    vArmorAmount: '747096384659378678243',
    proof: [
      '0xc523d2d968e0c7e0ced2c61e43336e00623e901344bf946221503d25c46e31dc',
      '0xf8306d18e85e34f86ee9e979b7cdd665cb4a8315f063d04622db1472dbb6e146',
      '0x4af0518d9a065b287abac84e30a075edf000efeb431af30279e3841c3e5f5f71',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x604c7c8ac1570c3257a773b4b03a4ebca8ae8039',
    easeAmount: '2092126415210635058558',
    depositStart: '1657803767',
    vArmorAmount: '1777717883410798979665',
    proof: [
      '0xcc1e4b7c73e9dc48da2161445da06d6153019454704ab4efe4e53092e8cfabea',
      '0x5b77b9ca6085bbe2d8dc666aa5149c760f478c179fb0b5c3cea1b68598b7a4b2',
      '0x418282c3e9827960484d02968e0a5649277d1142f4624e40a7269e9b5ef44eb5',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x607b92578f2ce06e01c3c3337e4db8db826a86c6',
    easeAmount: '8188909401849973208587',
    depositStart: '1649894400',
    vArmorAmount: '6958265324437324528855',
    proof: [
      '0xf4b4818b532cc44d12ce6253fa2c73ed2f87f441af38082bf979633c7723af0f',
      '0xa1e1645cef48f8fa779cb2de5416dc9f6959cae257e8dcbb228935ebf4ea8312',
      '0x2539f701226ecf9e65911912ac2091ab4972087c9f41b88b00f9da6529698802',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x6255cb7a706314835a22889500c8d55130912926',
    easeAmount: '164301406520361152620979',
    depositStart: '1649894400',
    vArmorAmount: '139609894754560964917541',
    proof: [
      '0x9277d68a1f15477291689b1bbb9fbe4d15293807aa9738520938acd572b07c0b',
      '0xc6dee9be02945aba0b8f5ca3637dcc2b8c7961d3f7ea47baaf24f69cb9131f1e',
      '0x8b08f58c10e6f2e08822e7bb513faefba36b0b350753cf9da0ecb773fe15e080',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x630018c1e21fafaa460c49b3b1e941b86a153afd',
    easeAmount: '25073665177075744881738',
    depositStart: '1651917993',
    vArmorAmount: '21305549542260549163591',
    proof: [
      '0xdcc83c324958f20506244c327bc896d2579680ed0c8141c2aafbf04c81529a17',
      '0xfd07dddee30e2812b5b4461d9357ba95539654ef14e43f42c1c7f08964e5b0ee',
      '0xb2f04622642a3fa83e35f387f3cf48ae54510069b1eca4e043e1b479ef60e938',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x641763fb275dd3418012ee26591a9898360e9d69',
    easeAmount: '45664983064506537804890',
    depositStart: '1649894400',
    vArmorAmount: '38802367031559803915374',
    proof: [
      '0x37f29039d87f4ad9a82f4d87d44e48bbc5bd101d1cb1ecff650e2bbe6d2bd5b9',
      '0x3f0e1af332d2a620911466f60801e4fd6ecf0a0ceea933d090902d2dd026dbdc',
      '0x914f37d52d50b07eea89c7c24a437852ff9fb6c2d3c133f7dde0e3b17eddaedf',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x64abc5b10c77d93be5038e7b4965d2f0d8279127',
    easeAmount: '28151736798715589227322',
    depositStart: '1649894400',
    vArmorAmount: '23921043007868130596808',
    proof: [
      '0x4396be93f0cc53844b857c16fb9a1f9e019a26e24e259e5e58baff856d6ec405',
      '0x8d7ca4f6c1da36a597a80a3babe0fd3a33bd02b810f305df6de412af52e658ea',
      '0x8e7dbe80b90aa42ddc7d93595bf13f6079ca5db7c3142055f7f541e3df164de3',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x64ad5e331bdeb2f6de21ca1fcc17604cf724130c',
    easeAmount: '161940605754601320679381',
    depositStart: '1649894400',
    vArmorAmount: '137603879386680518406885',
    proof: [
      '0xaa41200f00fcb1e0d61318e813db9888c6d724143505ba06e89c5e75ac67e4ef',
      '0xd4b37a4e3472a9ec2fa79cbacea32bb017ee2b1b94ed5e8df92f2bb2ca79ddad',
      '0xd79ac94d01132006409ab6f24ededa22b4f8af889eb7b2e1e719a8a36b07bec0',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x653dd24cf009eef58120618313058b71dc0e4025',
    easeAmount: '9748370421437004310210',
    depositStart: '1649894400',
    vArmorAmount: '8283367728788349577869',
    proof: [
      '0x884ac0f6fd047ed3f2e52a1b4d45cd42d28285596ba3baad5894b2f003475396',
      '0xfb12632824692e35764bf14ed6662ee34072b92fe0e43dc52e33631ff6de556e',
      '0xc3ba78039c56948fca407ace5e54e1717c45ece4f2e76d4481f4d68e89546780',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x68a32dc51ef5e5f4dba05fa2da3ddb1388d93615',
    easeAmount: '38510192626833565400024',
    depositStart: '1649894400',
    vArmorAmount: '32722811407848961850127',
    proof: [
      '0x80d99221e9ce814ead8b187ce8ed0400d6682732defc0ce9425ec2a20f62dcf6',
      '0x990c0d4b18bbdda7f399f0a569a052b037775af2082ec6157fc8a124a9004eef',
      '0x1cd8a25f7810e46bd47017192ceb95fad0bdfb81a22a51d1f903dd7515281f9d',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x69e69571d0d07edbede6c43849e8d877573ee6bf',
    easeAmount: '3004913063303052334100',
    depositStart: '1659847822',
    vArmorAmount: '2553329307393091604849',
    proof: [
      '0x2c79cd7435885aaa4d700c5dfc98f30a252e2a52221147ddc2b6ac1d7001d375',
      '0xc0ca5435b3c51a8138da01296755538e9399f6c12d9160efc6abcbc489b45ecd',
      '0x3d94121da30c2ba376fd293fc5f5dfc4c17d94cbeab3dec387bbc807e9a4da26',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x6e2be4d0f24a8d9860aa5b7c4056adaa0d9d6193',
    easeAmount: '8507454817989807580494693',
    depositStart: '1649894400',
    vArmorAmount: '7228939160795011893627992',
    proof: [
      '0xe1b1751f2e7c78c9f127ade15b079d6d984296f4f817d3be71c80a282f3b41b8',
      '0x9078d0e94fe13d30f8d4325462e7ff6f7308e9df3fffd4f9590518cf2f13b626',
      '0xb60325b0d3c278b7f855e51e8e776b5edef92e5c727327074073f48e2e95133c',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x6eaec23098a0ed7de290736b90f503f3c7cfd48b',
    easeAmount: '7694179259758232408718',
    depositStart: '1649894400',
    vArmorAmount: '6537884120574791076441',
    proof: [
      '0xded54481603925deb61b1339f4af752c8a9fddce4a1477877a11eeca658358bb',
      '0x95ad357caff26976648cba71e1f412c54511e20e04d5d4ecbdbcfc1ec4ce364d',
      '0x25eb5ea8b590a1debfcff27ac3f53b3ea3b5d80f9b48848945264d027069cfe0',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x6f1d13763c581dbef3842595a2aa5b7b9e7ae4ab',
    easeAmount: '26397404113501136403988',
    depositStart: '1649894400',
    vArmorAmount: '22430354603341758173085',
    proof: [
      '0xcefa5323395a762f89a4b05fcd34f66ad121e6ed8b1270e3380e22c4e787c7d9',
      '0x40f64cf38823ce065a30ea34af127af1fbab08a64113882d9d16b0b8104ea6a5',
      '0xba66c3211a6ae7f53431dfe489327ce862386e56acd041e9900507d523bbbd39',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x6f2900715edcad13e7cdb8eb9603703c6e9021e1',
    easeAmount: '17740334632397860430970',
    depositStart: '1649894400',
    vArmorAmount: '15074285140905525104988',
    proof: [
      '0xf584241a614ee12fed4311128dc9797cf3d3741790ca4beaccbd6f1159d8dd81',
      '0x68e79a1b2f6fe60017216f84ff7db827989db7f199629588cdb68d89308a8711',
      '0x4538d0f069b5f1bb621e2651fca0933db5944a5f86eca93e81bc98d50b33785e',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x6fcc37acb756bfcc2c51665c1a64924ed1a228ad',
    easeAmount: '838205639984885391565130',
    depositStart: '1649894400',
    vArmorAmount: '712238584314658737307249',
    proof: [
      '0xcc05efb6d5558c3e9cc0483be095631bf0718cc104fb0ec89ee41aba423776cf',
      '0x5b77b9ca6085bbe2d8dc666aa5149c760f478c179fb0b5c3cea1b68598b7a4b2',
      '0x418282c3e9827960484d02968e0a5649277d1142f4624e40a7269e9b5ef44eb5',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x70bb664533267e122feac94ebacedf8add8d2a02',
    easeAmount: '2442793608205672690956',
    depositStart: '1649894400',
    vArmorAmount: '2075686178051341475404',
    proof: [
      '0x36f1cebcd0d8e993cfbece9fdc114fee77c60efcf8755db94edeb3cea8988c75',
      '0x7e97570f24cb356fd11c5419b08191598f2fd9a123b52487d15c2c21c74ed02e',
      '0x914f37d52d50b07eea89c7c24a437852ff9fb6c2d3c133f7dde0e3b17eddaedf',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x73d9dbfe6247d2e23824760ad2a3551cd3b98d54',
    easeAmount: '64689122496541581336547',
    depositStart: '1649894400',
    vArmorAmount: '54967524470874630477970',
    proof: [
      '0x16d8c5dd236d4d8f85b0ab6b1d75b18e1ba0a19c34212a77782eb24cf4037cfa',
      '0x21795bc9875f0c3a5b0ca21cba0037afa063e54cdb681077a73bca66f4be4626',
      '0x20274533ce2604d78de5b88b11f80e32bb57ad812996bf4b0cc9c60190098346',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x73dbd760e34a87df4c598be5423ae50cc6a3209a',
    easeAmount: '18486151997461597009946',
    depositStart: '1649894400',
    vArmorAmount: '15708019726919362247901',
    proof: [
      '0xd9ce6cbf3eb09461a6e19acd0e00a294a6ed97c74b86433c8d9fa6342e8c24d8',
      '0x915deb4ac09542f4c650054db57183d77f7b562ab06507bcf2e6de3aafe48e34',
      '0xb2f04622642a3fa83e35f387f3cf48ae54510069b1eca4e043e1b479ef60e938',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x768f2a7ccdfde9ebdfd5cea8b635dd590cb3a3f1',
    easeAmount: '2103940465947130951556314',
    depositStart: '1649894400',
    vArmorAmount: '1787756497290485014652289',
    proof: [
      '0x97964d3baefa8e8b609ad33eccd4f8f4691748887146245b39c7268421e8cf2b',
      '0x450ed7d631f052da6e74f86ee4824b06b4de06a47a4a35dd5d5e939d2d013bef',
      '0x8b08f58c10e6f2e08822e7bb513faefba36b0b350753cf9da0ecb773fe15e080',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x77f8d2af77e79cb14a33dc39f340b31d0d6ae5ee',
    easeAmount: '1420470559995847244443564',
    depositStart: '1655033039',
    vArmorAmount: '1206999681761072482910078',
    proof: [
      '0x0e4fb66a73772c8abe388a9b5e95fe2f9b600ea2be25b2b540198db231b36f29',
      '0xa789fe88b434273422361dcc5c2c5b6c910c672819ff65c9a7a295633927425d',
      '0xf6ac62716d375ddddc544d5920b999bc1511c76700bea73498aa28fe103793ed',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x77fc03e142b2c1cee2f952106a6c47d62eccdb32',
    easeAmount: '995967109963096095223133',
    depositStart: '1649894400',
    vArmorAmount: '846291375988437600000000',
    proof: [
      '0x2efa1be566d1e87ce15f383885b787d34252f2ef8e005823cfb93a785db8e923',
      '0xe1055d28d9f7a58c39cbf46bd50149bc5ba023a5e37dd7eb30b9dec9ae756fa3',
      '0x4c6e3a872452d9227711608dbc282961a771d5174eeb78542dd9d96c7a5acbf6',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x790f54ae142a33ab64e6f558476eb1a6caab30dd',
    easeAmount: '16777994057346110191816',
    depositStart: '1657927798',
    vArmorAmount: '14256566843500877864560',
    proof: [
      '0xc98cb5f874066b714d74406cfa77d06770217831f8c3bffeac6ed7668cf9cc5a',
      '0xa13e120b1ef3f083487beb5938b7421b179f66ceb6c54ea4502e1ef37e4b057d',
      '0x4af0518d9a065b287abac84e30a075edf000efeb431af30279e3841c3e5f5f71',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x793d6260fa382a26ac6ab5fb6f9aa96f1f95b23b',
    easeAmount: '470028672705213859027581',
    depositStart: '1649894400',
    vArmorAmount: '399391915855990004365541',
    proof: [
      '0x1477ec4fddc8ec863d7f89dad4be5254340aff71173e9a3acb05f77a7d50db45',
      '0xa118833ab7198590213a7b1f1f8d38baaa55d87cb31c646fe8d98d5460003c56',
      '0x20274533ce2604d78de5b88b11f80e32bb57ad812996bf4b0cc9c60190098346',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x7a6e3d531151ca58336a40719ed74031b4dbdcc4',
    easeAmount: '30040639051679240342820',
    depositStart: '1649894400',
    vArmorAmount: '25526077622743615974734',
    proof: [
      '0x5b6c8eac3e99e0d108cf39e1f348e044f6abc506f1919b047a8819e383f82090',
      '0x2407e2eb14a373228f0da36810249161f61f7985ae60fc7a49686289c21dcfa6',
      '0xf3de3874d057c9ace2b710ba5fd19b2e88975f27c8b2efa39f1df0c271063e96',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x7c2be77574546b4513b4f30b6b1c5ea1c4ac8efb',
    easeAmount: '60018148924220257982464',
    depositStart: '1651102933',
    vArmorAmount: '50998513233272705447127',
    proof: [
      '0x7be88c61a07097b1120a97f03dda1f3fb1421a9c4e966d8d932529f1e4dd0cc6',
      '0x1f9c3b735d36ce25b06ea854ff1a937bfd0f12ef697e82992306efa560fc0198',
      '0x1cd8a25f7810e46bd47017192ceb95fad0bdfb81a22a51d1f903dd7515281f9d',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x7e1633c967485246ee5a1403015a1c1f7cfa3402',
    easeAmount: '27272726078791259823711',
    depositStart: '1649894400',
    vArmorAmount: '23174131604638247010981',
    proof: [
      '0xe28bd1ca45622e351ad7530db2c0b752a1f742a55dd0ef771dc6ce110369ba5d',
      '0xe905c29a7d78dd811ee3d8ba693122a287b856259c2ba80a77258afe53247757',
      '0xb60325b0d3c278b7f855e51e8e776b5edef92e5c727327074073f48e2e95133c',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x7fc2a53de587b8c3799cf10f275ec68336737b16',
    easeAmount: '10244030198838005878975',
    depositStart: '1649894400',
    vArmorAmount: '8704538860688838906422',
    proof: [
      '0xf0bc872e5706b5a9f808a11b4844d3e77df32d8c1f786bfacc79ad331e90f43b',
      '0xbe84262103dbd9807159c1f87516550578dc4b5f1257c1534cbcb17e74c0db87',
      '0x2539f701226ecf9e65911912ac2091ab4972087c9f41b88b00f9da6529698802',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x8023f257c94ca233d1c4b6784a2e7b6b3da46650',
    easeAmount: '28346927384142288422913',
    depositStart: '1649894400',
    vArmorAmount: '24086900000000000000000',
    proof: [
      '0xfbc5cd20202672445b5e5ff343204c2a8d8e7f323ab4125380367a479ef8caae',
      '0xb188f20be3de4d569535407fd97ce3a18cd711877c20eee57f9aa81acc278207',
      '0x4f0b083e08e15ed0e837d7ac2fef92556d3ed0e64191d4ed28a0097288208aa1',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x81f85b84dd2ecfbf8273be60e7a1c36b9acb4bf2',
    easeAmount: '102717854673171049848562',
    depositStart: '1649894400',
    vArmorAmount: '87281230173513773305187',
    proof: [
      '0xb1974d1e20aebfcc3b5998eb6d8a2d2bb377b3a9b35fcac18dab6ba1cbab16a0',
      '0xfcc3d2c00e0c517cf2720da34ee85d0c8353cdc728f4141671a0499d9151b534',
      '0x721fd24dfba9e11dadce4ad81aba9b49239b525459c41f379f225359785007a2',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x84763fc817bfbe0e9690c7f7fafecb7398e00740',
    easeAmount: '1157144792767288662830',
    depositStart: '1649894400',
    vArmorAmount: '983246985861982784193',
    proof: [
      '0xd4f9517c9805db535de32a953b8dba70a5c3e3013993d87a1ef86b7c521561ba',
      '0x3187a66d2cb42273c18163f8d547df7d6b9099f219d9903f0a18ff42f59fbd78',
      '0xa59c353926ba4fc36132f3b84ea91726d582fbed1a28f667b346c3e88a53d98b',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x84d0ee7dd5c71cb2128c665855cc49dd7d19d344',
    easeAmount: '12379706676615160240486',
    depositStart: '1649894400',
    vArmorAmount: '10519262024700889676956',
    proof: [
      '0x13bfacebf463bcbe3c8929093849ba393c9a1025b009f8e352f0f6d80d1b3e46',
      '0xa118833ab7198590213a7b1f1f8d38baaa55d87cb31c646fe8d98d5460003c56',
      '0x20274533ce2604d78de5b88b11f80e32bb57ad812996bf4b0cc9c60190098346',
      '0x788b0566e052cfc66a7fc38f03a537a78b05e80bc3713facd7b0221b4ac0510a',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x871cace2c13eb921aeff73887d8c6ba30ef1c154',
    easeAmount: '3553906032711240971833',
    depositStart: '1649894400',
    vArmorAmount: '3019818622994737796627',
    proof: [
      '0x392e7804ed3bf4ff2ca22d3488a5918d94c39dea9038322b1aee9d545b8ec60f',
      '0x3f0e1af332d2a620911466f60801e4fd6ecf0a0ceea933d090902d2dd026dbdc',
      '0x914f37d52d50b07eea89c7c24a437852ff9fb6c2d3c133f7dde0e3b17eddaedf',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x888851dc68c2f675c48b9967dd234dbe0e955fb7',
    easeAmount: '126545500637351335040514',
    depositStart: '1649894400',
    vArmorAmount: '107528014517967337534792',
    proof: [
      '0x20f3b1acdebd70058420d290fa6ea3b8717a59456614bc8cd5eacabd40bf1346',
      '0x1d66ff49b47eb0664fac83d467b575acc40536fdf3c9b044e0ab7e3b21036102',
      '0x6df905ddf0d7a764bf5c3cfaac6a96ffb731ba67f74456b5c953254b7a2ef752',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x8aaf89f4ba9fbaa4404b154094c7a39c4ae74dbd',
    easeAmount: '20347055096122551545329',
    depositStart: '1649894400',
    vArmorAmount: '17289262950910243346979',
    proof: [
      '0x24e57e028c4808c1caa3f504cce5f7e7c453f718c98b767f0e851916713e82e0',
      '0x3606def0a23a02f2aa8bbb7d323996b137a8106d789f304da92443d6880ab171',
      '0x6bf800b804e71b8c80e24bfbffcb69bb0d5bc93f33a68f43d64abe96df2511ee',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x8b8ff2a504b8326ecfe8d65c529034448f3697c3',
    easeAmount: '18348160731949609547733',
    depositStart: '1649894400',
    vArmorAmount: '15590766037719873699867',
    proof: [
      '0xa190807c958f99d6d0596cf2563aef61dda3d52d9592306b44099ed55f76f253',
      '0x820d55c69a4b44333b292e2546ca6bd1cb0c3e4371d9ad58d88c05dc527d7072',
      '0x6f630ce43a180716158b272cb7c35a7189fe6b98cc0e4eade7733b29f2b01419',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x8da02d597a2616e9ec0c82b2b8366b00d69da29a',
    easeAmount: '14642983307202728733717',
    depositStart: '1659724548',
    vArmorAmount: '12442409360372847524103',
    proof: [
      '0x9a11b1d0f913aff8332dae59c0984f16681fd1db9f24f511729abed8b746788d',
      '0x2368ac8ab5e81257e0786b5800573901b633a4046a85c9b2a94b170f4532f216',
      '0xce9e0607dd3e91f01706b6d8a3649dbb9e06de2a4162aa62319053aadcb0d9ec',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x8de962fdc6521a426389aad89e1c067c8f816004',
    easeAmount: '1035961243067573253379847',
    depositStart: '1649894400',
    vArmorAmount: '880275118621973792349281',
    proof: [
      '0x68ab98e765e8d620601d64c34b5c3d8961c961d3678fe728a5a44005ef731c9e',
      '0x7e258df414a0af1a22e7d59bbbfc3788e125b317b2aecc8baa36e46c6fb89087',
      '0xe612796e9657f5eff9650e6fd598deb7cfd20a0a7f2fcb2fdc547c08db843a7c',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x8df5abb9d98ef93583492bd671f1553fe46e6dd9',
    easeAmount: '117686075767916537300',
    depositStart: '1649894400',
    vArmorAmount: '100000000000000000000',
    proof: [
      '0xc9fa193b82730931e8dfd7cfafd47231beca1a9d9f322204568fb313fdf18cfb',
      '0x327fdd1977968cea258228b3825c2b96ad0010656948c9236acfe0360a2b152e',
      '0x418282c3e9827960484d02968e0a5649277d1142f4624e40a7269e9b5ef44eb5',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x8ed29cf7121950acc9008c675003774f0fe3a429',
    easeAmount: '10162632114862859391396',
    depositStart: '1658415650',
    vArmorAmount: '8635373427612739045931',
    proof: [
      '0x0a8c8afce66618e7ff32d679ef1914f7aaff037b40a7321baccb223418c0de86',
      '0x28a8c6e3c827c9ad8373eaafe6c87b08a5bab423599b9b1f0b87300a37657f1d',
      '0x423857ca2f20f5e224db279c10334cf22462642423f865fd3c6cf8ced8d8a7bc',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x93f7f925f9e187472867ac314786a497271db01c',
    easeAmount: '505717540111232281815988',
    depositStart: '1649894400',
    vArmorAmount: '429717395886778733292367',
    proof: [
      '0xb0539bc0e8a0c8ad7e278b0fa81e8ec910bd3cb2605eabe867c41d6ae1c61148',
      '0x9b29dd85f4c146e739a02a96d789b3114b582d2a200bcc002029ddf832e99c9f',
      '0x6c179330959e06744a99875342ae84424dc0a7823ae9ea823cb678cbc2c37afd',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x941b5790108a359241caff127bbf0b16e2bef085',
    easeAmount: '77262497905242411125939',
    depositStart: '1649894400',
    vArmorAmount: '65651350341231819393127',
    proof: [
      '0x1d4746a97fc24b47673307291ba41a907e88084d48756141dfcd5d1c15b1b046',
      '0x57dc2dc9d6f11a65f3024f404fcded8db2c676336cf16bc97236c4a2bce68a29',
      '0x6df905ddf0d7a764bf5c3cfaac6a96ffb731ba67f74456b5c953254b7a2ef752',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x960940ee2cd026adc67d557e348ebbde3c6239c2',
    easeAmount: '847430515916990028146128',
    depositStart: '1649894400',
    vArmorAmount: '720077129250337111946077',
    proof: [
      '0x44c3d9bd93a2d20910563b3f496c14e46413245e3aa44afc282929dd1d41fc7e',
      '0x8d7ca4f6c1da36a597a80a3babe0fd3a33bd02b810f305df6de412af52e658ea',
      '0x8e7dbe80b90aa42ddc7d93595bf13f6079ca5db7c3142055f7f541e3df164de3',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x9663a4cf47174c02add34a60d9bac5252f59bf0c',
    easeAmount: '5884303837823977677151',
    depositStart: '1649894400',
    vArmorAmount: '5000000041999999141467',
    proof: [
      '0x8a0a2149dfedacb7d6f51a88b2bc6f670a7bb92d70572e84c5730584b49cf25b',
      '0x7f1a64036dfa42296b38dc6e8f9f4d481ff93000b45d743979efb620ccc7d0b2',
      '0xc3ba78039c56948fca407ace5e54e1717c45ece4f2e76d4481f4d68e89546780',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x99eed6892670419c6d0e04176fe5b3483f9b7b10',
    easeAmount: '1176884066575066803793',
    depositStart: '1649894400',
    vArmorAmount: '1000019805992976940813',
    proof: [
      '0x9c2b6c601af92d4ced708b99ab59e702be1bb075bbf3ec9f8ae96dd284d3fdcc',
      '0x991b00677396777dca751ada7015beba8f44eb3b1b35886d9aa2e20ad800ded3',
      '0xce9e0607dd3e91f01706b6d8a3649dbb9e06de2a4162aa62319053aadcb0d9ec',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x9a6b9f703de8adf7e7dda5dc5fb3256562266525',
    easeAmount: '115408985299863638935221',
    depositStart: '1649894400',
    vArmorAmount: '98065114795276675766494',
    proof: [
      '0x7afa462fe98134630619a9fffab466097468e8bf9512696be03c62f476f9a811',
      '0x3453eed141113395ea321d40df59a89a2019d3bd3f6d333b6e5c0603c9a6a236',
      '0x79358fff1e07a46cc3b7564a5b5976adaea3dea834e69fd89c4893b943297cad',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x9b0cad3b798097a096c490ae428c67bf0d4406df',
    easeAmount: '10129947211216213839668',
    depositStart: '1653181623',
    vArmorAmount: '8607600470248520365657',
    proof: [
      '0x1910beb5b350a9c5b9a85768e534ff0ee1924682e608d2912f468c80208b3a4c',
      '0x77bd54ff32f19a7e399899c992a92f2952f20e7ef6dc298c3f078417a049a327',
      '0x3601e1e60c7fdb7bcb6c9955d2f215e9a68739a7c3d1fb256c43ffe5475e91d2',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x9c1b1feae8709768c7ff1b6d5a03ae79b58a308c',
    easeAmount: '13254317212689496978201',
    depositStart: '1649894400',
    vArmorAmount: '11262434511647533372727',
    proof: [
      '0xf7c76bad9dfcef6600448df257f0a31c93e7e7d438d1a33932d287dbe2805e01',
      '0xb6429e344954a33b81f9fd826836f529ce92d17443d1d641a2bc5c2c1202d022',
      '0x4538d0f069b5f1bb621e2651fca0933db5944a5f86eca93e81bc98d50b33785e',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0x9d0b6da2d728df2324cea859ba26011917934e88',
    easeAmount: '105000566453994187894720',
    depositStart: '1660646432',
    vArmorAmount: '89220891909983576384376',
    proof: [
      '0x82cd379ecdd7f7ae768b5b6d29275da8da4398244cb8b3eea75c9d25d276fe11',
      '0x8b96b26eae3f1402c5b4436ccc8be856cfc35f7f6275cce7fc95f56ad2bbb1ae',
      '0x1c164e63e9dc26543ac0dbda820f2e49c2e8dd103aaaa5aaa03656d0b485240a',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0x9f6f5c875c5b03dad822376eeb0fb006296d6f43',
    easeAmount: '52144284485400466748914',
    depositStart: '1653436964',
    vArmorAmount: '44307947346491427925547',
    proof: [
      '0x59bc3ca99bce49b991a5b73391ce663dd84b3f2e4ed9796e05584f4adea30bca',
      '0xc4c666d7e1a348ab20e521141f7724d529d81e60f64629e2d4363e4da86fedc1',
      '0xf3de3874d057c9ace2b710ba5fd19b2e88975f27c8b2efa39f1df0c271063e96',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xa0f75491720835b36edc92d06ddc468d201e9b73',
    easeAmount: '12826248656135245126721339',
    depositStart: '1649894400',
    vArmorAmount: '10898696869992774856200566',
    proof: [
      '0x5ee26489903172cadd7e00a7a4895f8bd48222c5eabbea10100e6b761b699a5b',
      '0xaf82cd0d84bfe892827b5f2f4da1d2a6f82d7ecb1affc7e96f5a7ca1808bcda7',
      '0x0dc0cb302821c16615ebe0bf2d98f46be28ae17c8963457f88368347060fbf00',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xa317fa45b44f44c5c2ab3a937024af1fd5e24e86',
    easeAmount: '9222396319764291793545',
    depositStart: '1649894400',
    vArmorAmount: '7836437963953669737979',
    proof: [
      '0xe966ecf1fe8d8b07c923d8b7a1c6d1f9bd70a1d8dd058a11665cd6b47b93c04b',
      '0x009310908ad89c4acd6eab6eca471b4ab8cf8533726d72417c782076e278dda5',
      '0xa81744561bca2ffd687cb987d0babb34cd239beb8b37cac7ee287b37ce486cf1',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xa3805113e2a86a9bf7888aba1d78e526ca680ac3',
    easeAmount: '16789094292942218688313691',
    depositStart: '1649894400',
    vArmorAmount: '14265998915667171161810608',
    proof: [
      '0xd49d3e053744440e777763a82d6e00110f32c3b2c752d057a7fa6fab4c1e9c94',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xa6585b22c2c7c92e80c33bb6620e2869bad08cf5',
    easeAmount: '8813762260407338996102',
    depositStart: '1649894400',
    vArmorAmount: '7489214168198255303096',
    proof: [
      '0x09f117eed29cbd433e1898b05ebf452c65f0ab3671ae4e63b821c9a3fecd53e6',
      '0xa77b984d0410eaa235182d4ce0aeb5f150bc4713a53d856e38b8096454bfc323',
      '0xa82171641f2e3dd06d00a5c8d8f601698c9d51e2c87b78d0a7ba1445381dc6cb',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xa673edcb35edee169362454a53ae98a7bda55c1a',
    easeAmount: '18255021935879536107955070',
    depositStart: '1649894400',
    vArmorAmount: '15511624307942301451908784',
    proof: [
      '0xe956118f732fd077759adcb3ca328ee0c730fd8de9d218901cf35c59c7e59757',
      '0x009310908ad89c4acd6eab6eca471b4ab8cf8533726d72417c782076e278dda5',
      '0xa81744561bca2ffd687cb987d0babb34cd239beb8b37cac7ee287b37ce486cf1',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xa67fb5b40d5ef11559bf66382b9e58ba34d28d0c',
    easeAmount: '436959885025878705299418',
    depositStart: '1649894400',
    vArmorAmount: '371292765244027519136877',
    proof: [
      '0x754671dc723fb5cb450b376322393961563fc6b81e537a6d03d8ede17fed0229',
      '0x62bed85eba68dfe1ee3247364a06d04d529569d54870520d4a7edd32b3eb3a93',
      '0xbec0c245cafeb8c6d1ffebd48663aa60f7b941b4324c44f80f443197e25ef59a',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xa6a14182682b25f42f4a52c3faf8327c5b1a0d27',
    easeAmount: '8550424514545512815082',
    depositStart: '1649894400',
    vArmorAmount: '7265451293836514490126',
    proof: [
      '0xcde54b0cd41920019e334d50cd4c11d4bdf1fec95254ef3c33b1916535c1647d',
      '0x682f279f9dc1c2ca746c732ce9755004c7a8211b11dce0f53542b7d718d3021a',
      '0xfe5a2d4b6fd37c254604c8c7363d69560f2945552c9824d5d639700f61743275',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xa7538d9815c461fea96676e95a9fac471b0ba1f5',
    easeAmount: '195327707103045228099343',
    depositStart: '1658427358',
    vArmorAmount: '165973506915331504563946',
    proof: [
      '0xbd9e6d45fde77fcf67e03bae1f681e28e203e2e09e2b1e831bfbf4f49d13463c',
      '0x908aee1dcc596364ee8efcdf89c14a3fc0d248bf1df900b770e22723fddb50e5',
      '0x640f8b5a1aeddbaa9a59b2243b155157dc1d51a8abf119b18ab8b97a7f98c77e',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xac8eb5c1c12cc1db3e37bb64cc9f2220e16395d9',
    easeAmount: '128294225706250863633979',
    depositStart: '1651511025',
    vArmorAmount: '109013938028874533837821',
    proof: [
      '0x02750efe4f37c5888dfd2d47f8a9e5d8a994bb98547cddfcb8bf17d2113959f9',
      '0xc8ccc02aa374be43b156bd7d94a64b4d34ba46d3ebe393386037baa7c97e29a3',
      '0xa82171641f2e3dd06d00a5c8d8f601698c9d51e2c87b78d0a7ba1445381dc6cb',
      '0x1eda466e20084b45fd33df1daad837583e67076c07b50c22e53ccf49d41e5a71',
      '0x6e998adc6073474ab33c89aff7fc18d82e6ee6673333e2d267a4ea295169c683',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xae1bbe49e69517055944af5f7cf071715db668d4',
    easeAmount: '135176944569824540046659',
    depositStart: '1649894400',
    vArmorAmount: '114862309485449209163606',
    proof: [
      '0xa5cdfa0c54c48697ec533f2be3cc786cea81b3b9e69ff87a4f4e882b57552506',
      '0x08ddc05efef60a19c06da617468ad175e1c28ef26b8a7ae3417c886a82467539',
      '0x85c6f7cdb70906fb722ce51c282b9c1fc3bf100f7a3a639792868f19bb8e285d',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xb032926aa3cfb0a2b7f6c25f04cbb00bbd7453c7',
    easeAmount: '145986602898288415572693',
    depositStart: '1649894400',
    vArmorAmount: '124047472860070622099923',
    proof: [
      '0xcf3f1740fc7ca670e30fbe8cb9cc99fd75b61de9dc5adaf1a4966d54d908aa7f',
      '0x1ba1e98a491034765c2930220b7a4ee36b569cc4816cd79aab6138ff7f0f9468',
      '0xba66c3211a6ae7f53431dfe489327ce862386e56acd041e9900507d523bbbd39',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xb2e866d50ac7d9583f5b78f05db56488fc928662',
    easeAmount: '15889955226939828453583',
    depositStart: '1649894400',
    vArmorAmount: '13501984090518661412470',
    proof: [
      '0xcd5fc662681f63f0cd95d6d65f8289749d41cd2acb1e5867ee037c04be8fa0a6',
      '0x682f279f9dc1c2ca746c732ce9755004c7a8211b11dce0f53542b7d718d3021a',
      '0xfe5a2d4b6fd37c254604c8c7363d69560f2945552c9824d5d639700f61743275',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xb3f09f6d5d1d22579383e56123b7c360f343e255',
    easeAmount: '133470780068810784499995',
    depositStart: '1649894400',
    vArmorAmount: '113412550463508154313931',
    proof: [
      '0x41a10bf389c3e3bf75b8dbe503b7f04f06cc362335aeaf44e5de7c7862326d71',
      '0x1d80c85eb8d9db32414be7a2b1c0f2b936e7588f62c2ea3d33ab3ad18dd31255',
      '0x8e7dbe80b90aa42ddc7d93595bf13f6079ca5db7c3142055f7f541e3df164de3',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xb6ac0341fcf3fb507a8208d34a97f13779e1393d',
    easeAmount: '16147031113979704941011',
    depositStart: '1650232670',
    vArmorAmount: '13720426149498386747098',
    proof: [
      '0x56e087b2da7626a73c1819c786016d018573ee9c2c3567045390cf84cf80564d',
      '0x02195e17cc0f3db947dcb42473ef6508897f1fa0ede801c86c5865e3ebaed97b',
      '0x184cdf731725b000bb5cb354d9724110ff7b17870ad031ad2a73fc109337bd0e',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xb7dd913645e1ee6bebcdfa7c4087ddff5dd1fa02',
    easeAmount: '12076853231089556515454',
    depositStart: '1651233878',
    vArmorAmount: '10261921941305766959558',
    proof: [
      '0xbd323f1535a399d9c128715a9b032602b2c75d54bd93fc29e442f662413f9d54',
      '0xfbacd6bfdd14f737a5dad0b4efa0b6bdc31e81b7e421497ef8ea083f907f6635',
      '0x640f8b5a1aeddbaa9a59b2243b155157dc1d51a8abf119b18ab8b97a7f98c77e',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xbaaa67d958c51be7c522ad2a483d6cf0bb3dd036',
    easeAmount: '104800759364727104215339',
    depositStart: '1649894400',
    vArmorAmount: '89051112190536464959541',
    proof: [
      '0x637467524754c76e4d9df778975128f0edbeefb5dbcc5d3237652601ac6dffb4',
      '0x411c408cb1cfad242dfd84f344e779c72ed0bdcbef74efb6b0f7611317be5f7a',
      '0x2a8d4821219fb0d23cf145e7606fd2402ef10965c7ba75fa74d4bf90183d75d4',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xbc2d145a8e678034eb90035e6f4ef37d1b3aa202',
    easeAmount: '49520001766885591429152',
    depositStart: '1649894400',
    vArmorAmount: '42078046569028080214920',
    proof: [
      '0x7c575b70e32a1b6a3566d70c31d15e2b57dd586bbdceef3a03811be2067dbb72',
      '0x1f9c3b735d36ce25b06ea854ff1a937bfd0f12ef697e82992306efa560fc0198',
      '0x1cd8a25f7810e46bd47017192ceb95fad0bdfb81a22a51d1f903dd7515281f9d',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xbc4fef6016fbd9248375016617d4481f2c96d4bc',
    easeAmount: '52253041085014436611553',
    depositStart: '1649894400',
    vArmorAmount: '44400359808122355193067',
    proof: [
      '0xbb44cd9ada2d25dc07e3f4c1ada9a965710ada88161bd8ef09728c632a9ff4a1',
      '0xf3aec1f120f3908067d334b52b2b2ebd2e74f4206493511c2a213b6149d86daf',
      '0xb2f726116375c96740d6d91e37c5ef35d6a67aca027ba5dcf55cbf7762062412',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xbd356fc94fb325d9f440f574958c1d218f19c1cf',
    easeAmount: '106236867888563789210270',
    depositStart: '1649894400',
    vArmorAmount: '90271399734722040985012',
    proof: [
      '0xf88cd4b65f468886d8b701cb66a00f038d5c652a23d76c080b71d8b1882329e9',
      '0xbaaa612e37714e6f3845883c4e6d52c6508dc2dfd135e00bdbe4f7536e4fdab1',
      '0x4f0b083e08e15ed0e837d7ac2fef92556d3ed0e64191d4ed28a0097288208aa1',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xbd656db318abac214f3bd83befeef83798b76be3',
    easeAmount: '21334769308928271091131',
    depositStart: '1649894400',
    vArmorAmount: '18128541690014049225610',
    proof: [
      '0xf5749baef6d38b412090b352dd03e809625e9c1c31d8554bb403964972ec4d07',
      '0x68e79a1b2f6fe60017216f84ff7db827989db7f199629588cdb68d89308a8711',
      '0x4538d0f069b5f1bb621e2651fca0933db5944a5f86eca93e81bc98d50b33785e',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xbe1f49683b1b50d2f41b2f5a90d29820e0ab430b',
    easeAmount: '2466447583960613111212',
    depositStart: '1649894400',
    vArmorAmount: '2095785391658894650320',
    proof: [
      '0x74d3ee81b5d6556392b53c450585359441d475a547ef37561b89d86e6616ced7',
      '0x62bed85eba68dfe1ee3247364a06d04d529569d54870520d4a7edd32b3eb3a93',
      '0xbec0c245cafeb8c6d1ffebd48663aa60f7b941b4324c44f80f443197e25ef59a',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xc0ebf8cf9448be8e3ab08f2e16ccb45fd19a29b3',
    easeAmount: '19395315035536448681570',
    depositStart: '1649894400',
    vArmorAmount: '16480552103534393206832',
    proof: [
      '0x2be9703d01781e9d608e8ffaedd98b100494ddc26a42e34005560f8f46acd12c',
      '0xc0ca5435b3c51a8138da01296755538e9399f6c12d9160efc6abcbc489b45ecd',
      '0x3d94121da30c2ba376fd293fc5f5dfc4c17d94cbeab3dec387bbc807e9a4da26',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xc1029cc0644f6e2e086c68af2d6f000b1eaf652a',
    easeAmount: '127006192615194632840393',
    depositStart: '1649894400',
    vArmorAmount: '107919472874308327192994',
    proof: [
      '0xfb3cc1f8bc35e7c46096f7c1db92c32a13437080158cd59e6cf1fcb77a940d1a',
      '0xb188f20be3de4d569535407fd97ce3a18cd711877c20eee57f9aa81acc278207',
      '0x4f0b083e08e15ed0e837d7ac2fef92556d3ed0e64191d4ed28a0097288208aa1',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xc1835065ea53e4df9db1d4b2a3ab5a9b13e02bba',
    easeAmount: '1912054372380589102015',
    depositStart: '1659997488',
    vArmorAmount: '1624707392020842190507',
    proof: [
      '0xeb63eca333c1b56f7221629a98e07cafe53ff591ea88ee95fd3e5e6a4a2c70d3',
      '0x5b1c0b795df3412240d3f099c168540959d81dd4f9934fdda7495518f208c2d1',
      '0xa81744561bca2ffd687cb987d0babb34cd239beb8b37cac7ee287b37ce486cf1',
      '0x36762c1077efefd90969e3b1ceadd0cfbc196bd255d8e77c63ea2817530b2d59',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xc1a377575b1cec54a24ebeaef559484e1008ed14',
    easeAmount: '23139017548023047221574',
    depositStart: '1649894400',
    vArmorAmount: '19661644248937718017095',
    proof: [
      '0xceca09b014627d6010567d7e4d8e17be3f81d77925e7c00bd4976aa5528e78de',
      '0x40f64cf38823ce065a30ea34af127af1fbab08a64113882d9d16b0b8104ea6a5',
      '0xba66c3211a6ae7f53431dfe489327ce862386e56acd041e9900507d523bbbd39',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xc4417f80695f9198d91b233f707eb73bad529497',
    easeAmount: '4888406785693071679617',
    depositStart: '1649894400',
    vArmorAmount: '4153768195426348262384',
    proof: [
      '0x919b20093813616e58cb0642ea31045f04bed1e33b5a197f856e4a5c1b9de339',
      '0xd30d4da1385d7090392f7c2d9131bfe43b7d003797b36eeb8d0d5a3bd58629db',
      '0xa64e27b26017cc9aa1d9a77186da62ad0d648a82a4cda52e61092f7ec22a331f',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xc97db12bacaf9c724d08b570d3e5c65eb3f73799',
    easeAmount: '11823350124051691195085',
    depositStart: '1649894400',
    vArmorAmount: '10046515738504181923532',
    proof: [
      '0x5580b0fdd0683c085b775bacf04619a69c626025647db17ca94a07f81290585f',
      '0x02195e17cc0f3db947dcb42473ef6508897f1fa0ede801c86c5865e3ebaed97b',
      '0x184cdf731725b000bb5cb354d9724110ff7b17870ad031ad2a73fc109337bd0e',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xc9a3a4b124934fcc2d8eddd14b4a031cd925922c',
    easeAmount: '116861811664223203237141',
    depositStart: '1649894400',
    vArmorAmount: '99299607792752960384270',
    proof: [
      '0xe0aa013c5d18f95f53f42d98c4877b9ab7a9dda13d029d330d1bd6de67d72bf5',
      '0x0c141a7c58126858aed11d50165c2e32fdfac7d60a83dcfd544e53796768683c',
      '0x25eb5ea8b590a1debfcff27ac3f53b3ea3b5d80f9b48848945264d027069cfe0',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xca5687ce3bb84335a574fce8a207e02ec0dd3d3a',
    easeAmount: '1974000529177799944209',
    depositStart: '1654359568',
    vArmorAmount: '1677344168625894512918',
    proof: [
      '0x84e5dc3f7c96b78e33e299f74dca4696d4c61b4953f776e5b9272e96d3fc7160',
      '0x3b98fe6a0b9f7ba17537b018cde37196e3952c62079ab620f9a9a064f64baa4b',
      '0x1c164e63e9dc26543ac0dbda820f2e49c2e8dd103aaaa5aaa03656d0b485240a',
      '0x64015ad3c19862563899488ed36799566c4599b5374de54f2b658cabf321d0c2',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xca8dc04f1e6c9acd9028b60f9cf3351549a8597a',
    easeAmount: '2870322647919320118479',
    depositStart: '1659954484',
    vArmorAmount: '2438965382429570879801',
    proof: [
      '0x5fbb22f83ba90b0598d33e7ae0842c41778726a4063296800529f8aa99d2e7ec',
      '0x16397569c3cc0266d8b61e91aea617ccd2931a5227f2f1c5f4b32fb1a639ca43',
      '0x2a8d4821219fb0d23cf145e7606fd2402ef10965c7ba75fa74d4bf90183d75d4',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xcab5541bad35e9ee799bea9765c3c416d8cc1bb8',
    easeAmount: '4832682525240017218583',
    depositStart: '1649894400',
    vArmorAmount: '4106418277358771779954',
    proof: [
      '0x5fb70fbdc8c9b2462f24fe8b0b8efad07c4d945c301060fd44a8c57299d5cc23',
      '0x0d49d63420a2fe15b023c262c57bfde17d95a7c079d8757ff03817539de0caaf',
      '0x0dc0cb302821c16615ebe0bf2d98f46be28ae17c8963457f88368347060fbf00',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xcca7eddc14e6fee2a94f360b6ab025b42e527b37',
    easeAmount: '24425193565169847920994',
    depositStart: '1649894400',
    vArmorAmount: '20754531414011699305695',
    proof: [
      '0x6cf4aadcfeb371dfae8cec41f843bbef58ed29c206d5163e5be5d212ae2b0b38',
      '0x8d87e001a53d191b01f0ee4ce5ffb3b66d7971c6e15429732e53f75d6a22bc9e',
      '0x5859fb009e1565b7176344a5bf8fb2ae09d0efe0ec5873202347ee5129994a26',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xcdb2c18eaa364b0d1be092925582653a766d4fce',
    easeAmount: '33880203857058933150129',
    depositStart: '1649894400',
    vArmorAmount: '28788625702731878446497',
    proof: [
      '0x1c39e7b52803587daba65f1b444331eedbd700436f7edcd53373d5c9ae8225a1',
      '0x8014d8cd42bf4efb3e0a826f584e1d5d6b415d5c4c4f6cc2ea60fdc29e07389c',
      '0x3601e1e60c7fdb7bcb6c9955d2f215e9a68739a7c3d1fb256c43ffe5475e91d2',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xcf82e226af594bc666f91cad5e29ea8c24006dbd',
    easeAmount: '3522634692110274231695',
    depositStart: '1649894400',
    vArmorAmount: '2993246795871675628569',
    proof: [
      '0xe2c900141b01c0bb0f86820e92657116f01b952f8ae58879e1f8b7853a64e0e1',
      '0xe905c29a7d78dd811ee3d8ba693122a287b856259c2ba80a77258afe53247757',
      '0xb60325b0d3c278b7f855e51e8e776b5edef92e5c727327074073f48e2e95133c',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xd04fac08bf7956afc7cc5f609742abe2fe9b1efc',
    easeAmount: '930138337073880042363283',
    depositStart: '1649894400',
    vArmorAmount: '790355469841788576934930',
    proof: [
      '0x5f7fea26e475545b195a144335953e8cc09bd49709e1a1d536cecc9542755ea3',
      '0x0d49d63420a2fe15b023c262c57bfde17d95a7c079d8757ff03817539de0caaf',
      '0x0dc0cb302821c16615ebe0bf2d98f46be28ae17c8963457f88368347060fbf00',
      '0x015807381f4f90ac292ac7cf36f05884219c220fd21e904cb1e2f39155a32c1f',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd08d3fc1fd5f82e86f71733a5b6f4731938e76f3',
    easeAmount: '83638883012184769161942',
    depositStart: '1650955910',
    vArmorAmount: '71069480791529901214343',
    proof: [
      '0xae6a49a2c2b2d41d15b48eacb9bce38847462c2b3921972f2a81eb8a10f26c1d',
      '0x9b29dd85f4c146e739a02a96d789b3114b582d2a200bcc002029ddf832e99c9f',
      '0x6c179330959e06744a99875342ae84424dc0a7823ae9ea823cb678cbc2c37afd',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd0e72c1b2eb87e3dc0445b7f689cadcfc872b293',
    easeAmount: '543131929658761761192093',
    depositStart: '1649894400',
    vArmorAmount: '461509083478871385604608',
    proof: [
      '0x19c772115f8231c90d6715893ada5fe537b3243dffe21571a4b353c7f35aa9d1',
      '0x77bd54ff32f19a7e399899c992a92f2952f20e7ef6dc298c3f078417a049a327',
      '0x3601e1e60c7fdb7bcb6c9955d2f215e9a68739a7c3d1fb256c43ffe5475e91d2',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd2d9f68ac272e741b62ff98c5a1be51eaef79387',
    easeAmount: '10109219601817150832367',
    depositStart: '1658146970',
    vArmorAmount: '8589987843381822047323',
    proof: [
      '0x5834e4e8ea9021a7dd34d5324cdd2996bb1d62a90fe51c490a3ea30656941d05',
      '0xab0b96cb8b698da3dc44d539ef16a6716f7a9eda62ec02643f083c0ea41aafc9',
      '0x184cdf731725b000bb5cb354d9724110ff7b17870ad031ad2a73fc109337bd0e',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd30b430f8765f9ba452455d4b548385e1ada6b00',
    easeAmount: '18111788586731506297406',
    depositStart: '1649894400',
    vArmorAmount: '15389916324892128284444',
    proof: [
      '0x3caeb35fc29a8f1b5084292130700e94919c97c3711d490a558fa53196df8043',
      '0x511e719f1c4e6b56cba2bf8c7c0e3830044e78522dcb7dd733f7537c9c56fec9',
      '0x434d42cf471eb90a34d5789e5ddec5989328947ef9ff6747c9ed1337431bffdf',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd3f5959da9ce7be0affeba1ab48ef845b18c4a2e',
    easeAmount: '462744760353945975895530',
    depositStart: '1649894400',
    vArmorAmount: '393202642992790655354265',
    proof: [
      '0xb13724b9640606a66b886dae8f6184c59e93346a7e47bb7e4c97df713ba66791',
      '0xae95c64772cb7126147c587a88706f9a31226dd45588eb02ceb3d74e403251f5',
      '0x6c179330959e06744a99875342ae84424dc0a7823ae9ea823cb678cbc2c37afd',
      '0x6c3d707edb1de98990c82065efbe6cd45ad1e073934e77f512471834c5127105',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd420838bc856b5c13c0cb543ac9a5b624e910223',
    easeAmount: '641590687223934574096',
    depositStart: '1649894400',
    vArmorAmount: '545171281341037287605',
    proof: [
      '0x985dbc6114463f3f20752303ae27d93df16a6b7f29e9a2f1ceb593d73db9067b',
      '0x450ed7d631f052da6e74f86ee4824b06b4de06a47a4a35dd5d5e939d2d013bef',
      '0x8b08f58c10e6f2e08822e7bb513faefba36b0b350753cf9da0ecb773fe15e080',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd455712e43582134f101a0c686d26548b5438a3b',
    easeAmount: '24129887322005178693800',
    depositStart: '1649894400',
    vArmorAmount: '20503604325791823997433',
    proof: [
      '0xfa64fe0163b5e83c24814855743f0c9ea1db619c80b82b20794074fae460e0b3',
      '0xbaaa612e37714e6f3845883c4e6d52c6508dc2dfd135e00bdbe4f7536e4fdab1',
      '0x4f0b083e08e15ed0e837d7ac2fef92556d3ed0e64191d4ed28a0097288208aa1',
      '0x1987c96585bf2ea0e6da2b5114fc7dc20e265c4b5078d1b606ffe643fb847a0c',
      '0xc701facb7ac3f3dfa72269b9512609d9715a3bff6e3837731c5b38488f50592f',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xd5767d428b058bcf4ee20ac8e542f6373c9e036c',
    easeAmount: '714147217948310613960580',
    depositStart: '1649894400',
    vArmorAmount: '606823885738741514734286',
    proof: [
      '0x27fcdcea20ef97b4d64fdf8347d2a85ae3a5b3410bfead865cfc93f7106ba29b',
      '0xbbbb79d5283ab0436cae441b419b00579ca390c1974bdad83a43873419fcd8bb',
      '0x6bf800b804e71b8c80e24bfbffcb69bb0d5bc93f33a68f43d64abe96df2511ee',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xd699571a57d3efe7c50369fb5350448fa1ad246e',
    easeAmount: '411894479250844039543357',
    depositStart: '1652328786',
    vArmorAmount: '349994233865969646867855',
    proof: [
      '0xc73ab67bcfff8a119e98884a66f60ca7f8966aa0740d9598831cfc4c716bbd6b',
      '0xf8306d18e85e34f86ee9e979b7cdd665cb4a8315f063d04622db1472dbb6e146',
      '0x4af0518d9a065b287abac84e30a075edf000efeb431af30279e3841c3e5f5f71',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xd6fd705ee0b31a9300d3e2154bcce777270cbb6f',
    easeAmount: '30568776205728398954273',
    depositStart: '1652782510',
    vArmorAmount: '25974845372541539685492',
    proof: [
      '0xb94dfc73b188111a8fb05011bc98154982d2516b0f95521bc75cf383c1a52e74',
      '0xf3aec1f120f3908067d334b52b2b2ebd2e74f4206493511c2a213b6149d86daf',
      '0xb2f726116375c96740d6d91e37c5ef35d6a67aca027ba5dcf55cbf7762062412',
      '0x6813b4bc7f475b44bd532e324e5f20bbf83d06bddc2ca285af949c1af0e57ac9',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xd874387ebb001a6b0bea98072f8de05f8965e51e',
    easeAmount: '691073917466032347027',
    depositStart: '1653308902',
    vArmorAmount: '587218082476357191516',
    proof: [
      '0x340af2b7ab5085a600b5c1904a2fa4f9f8f98685c7090130b902a8045aa7b61e',
      '0x7e97570f24cb356fd11c5419b08191598f2fd9a123b52487d15c2c21c74ed02e',
      '0x914f37d52d50b07eea89c7c24a437852ff9fb6c2d3c133f7dde0e3b17eddaedf',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xdb46fbbcafc9513d19323c456c4e11128a55f424',
    easeAmount: '73288579651917082568276',
    depositStart: '1651137229',
    vArmorAmount: '62274639691824054656228',
    proof: [
      '0xc144b2d102c97f39c21fc31fb92fea19c5502828d2d0ce90a71236f0f3e18eab',
      '0x908aee1dcc596364ee8efcdf89c14a3fc0d248bf1df900b770e22723fddb50e5',
      '0x640f8b5a1aeddbaa9a59b2243b155157dc1d51a8abf119b18ab8b97a7f98c77e',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xdc8444d2679190293d14d2067ec85dbe5be45b07',
    easeAmount: '16515424377830329156587',
    depositStart: '1649894400',
    vArmorAmount: '14033456609089134037797',
    proof: [
      '0xd437089e4af08d4490b8745dcdc4f5446e2caec288c5ee8122dafe19daf419b2',
      '0x2052ce59fbd740b38768396608ac67c6d052fa5412910cdf0b1a403276239251',
      '0xa59c353926ba4fc36132f3b84ea91726d582fbed1a28f667b346c3e88a53d98b',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xdd24db70d15701e0f6946c581b83f47895daf0da',
    easeAmount: '36330392385748780057822',
    depositStart: '1649894400',
    vArmorAmount: '30870595479276857699154',
    proof: [
      '0xc967941a8b7959ba94e20921321c7d941745a8db2b94179a2279ae83467dc94e',
      '0xa13e120b1ef3f083487beb5938b7421b179f66ceb6c54ea4502e1ef37e4b057d',
      '0x4af0518d9a065b287abac84e30a075edf000efeb431af30279e3841c3e5f5f71',
      '0xc54fbd8cc3682f3e1d1d425b6d39940ee43f020b6fc1f5d1cc6dc11308da7895',
      '0x3f145a7d48b6d1b5cac0fe1a321d263fedfe4bfc345a1d260df33082043be187',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xdea34ad01fe55a6ac37afb84d021099a182f63bc',
    easeAmount: '32751775793459229209799',
    depositStart: '1649894400',
    vArmorAmount: '27829779844174213042795',
    proof: [
      '0x8db9a873523152c605c7553b8b0a9a1a5bf0bbeb2b217d3b093b5a93de69137e',
      '0x2169a4ab2d73dcb847aceacc8b8ccda478206799d273efdc69912b2981647f1e',
      '0xa64e27b26017cc9aa1d9a77186da62ad0d648a82a4cda52e61092f7ec22a331f',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe040a20d16c3fb7f87bd4fc0f3aec0cc352ead3f',
    easeAmount: '55875061847697677618949',
    depositStart: '1649894400',
    vArmorAmount: '47478056756592339574922',
    proof: [
      '0x8b19dc7c9f7dfd83406b0531e7b4971fc97d839c8144b9dd2b00c3ecd020d030',
      '0x2169a4ab2d73dcb847aceacc8b8ccda478206799d273efdc69912b2981647f1e',
      '0xa64e27b26017cc9aa1d9a77186da62ad0d648a82a4cda52e61092f7ec22a331f',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe08753f2caf86cfa29ce24b1ca765f6f3a25a68d',
    easeAmount: '13653263844514144638289',
    depositStart: '1655967032',
    vArmorAmount: '11601426723955973773694',
    proof: [
      '0x3939ada20507c09d87e86749aeb1068df00351c817450d28c17277d87e9a8701',
      '0xdd6e7c5f5c4797b6a46cf9b341e27b5fa3dd148d7f44aeb9a23e1cf509bba479',
      '0x434d42cf471eb90a34d5789e5ddec5989328947ef9ff6747c9ed1337431bffdf',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe21b5f453d93b46d184e2fa679103af8dbf6b7a6',
    easeAmount: '15711652931749674075582',
    depositStart: '1649894400',
    vArmorAmount: '13350477385900711210527',
    proof: [
      '0x4dca90ab5228939921554db59365c8752a11d581fc0bbc576d659d6937890951',
      '0x916fab2793b19cb421700793debabc8735db6f6a3c8d858f0e995a37c20fbb7d',
      '0x341e44a8619acf4451ef9082386e5ac8473b7503363957aa0de76fa176188225',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe38cc80fa1ca307987bf017acb1b812ae555893a',
    easeAmount: '230767271459083528532924',
    depositStart: '1649894400',
    vArmorAmount: '196087149608224999577199',
    proof: [
      '0xe02865e085eb3390822964c489a8bf48711e855609ca4e26e827283dca4134c2',
      '0x95ad357caff26976648cba71e1f412c54511e20e04d5d4ecbdbcfc1ec4ce364d',
      '0x25eb5ea8b590a1debfcff27ac3f53b3ea3b5d80f9b48848945264d027069cfe0',
      '0x6fbdbc69e1ae4b79086d0bfec82086a5df7f1bed62d7940190ef7e51703a730d',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xe3fce3bede27ab58e9d52f3fe58a2d2c9c5e8b4a',
    easeAmount: '178979534437228584893582',
    depositStart: '1649894400',
    vArmorAmount: '152082167129258472783447',
    proof: [
      '0x2e22f9dd482f733d8391cceb2937dd93203b306f9d26b92d7219b7d668735c00',
      '0xe1055d28d9f7a58c39cbf46bd50149bc5ba023a5e37dd7eb30b9dec9ae756fa3',
      '0x4c6e3a872452d9227711608dbc282961a771d5174eeb78542dd9d96c7a5acbf6',
      '0x4220e1c4795f275fd5f6ea9e89769d1b0a15beb2c05c5e47c8873434a82fbd17',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe5546ea6535859c5d16373ecabde6de89fb2a4ce',
    easeAmount: '4724659589781031885900',
    depositStart: '1649894400',
    vArmorAmount: '4014629223510113806827',
    proof: [
      '0x76473d8cc0b950a8b5e77a2804c969704ea19462cf496305d7289c927cdabfc0',
      '0x060e77cb92efa9a81def17fa042617c50429a7348b6ad2a89cfca54d639a2a0c',
      '0xbec0c245cafeb8c6d1ffebd48663aa60f7b941b4324c44f80f443197e25ef59a',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe81a56283a2db5f58d602f9fe893ed18961e0eec',
    easeAmount: '8105580301997856527828',
    depositStart: '1649894400',
    vArmorAmount: '6887459072033729830749',
    proof: [
      '0x469cb0b0dda595683d50e76712536883481e4c8a4c88975d472543ce0f4b0fcb',
      '0x538b28bd7cae6b83315f406aaf793d7be0d30e39ce6e98395404e8752407f8d9',
      '0x8c6bfd6e49629ff86ec70aa037e638af7f0d49f147635cd608731c48f9d7e058',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe964e7736988f0fcf7b9bf55bfa410712d925314',
    easeAmount: '477082249276928890430812',
    depositStart: '1649894400',
    vArmorAmount: '405385468216105295540109',
    proof: [
      '0xa163f85f4e5a22bcdfed9c623b1d367f42cd4367ec8d72a5d0985628242bfca8',
      '0x820d55c69a4b44333b292e2546ca6bd1cb0c3e4371d9ad58d88c05dc527d7072',
      '0x6f630ce43a180716158b272cb7c35a7189fe6b98cc0e4eade7733b29f2b01419',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xe990ce99537229f3481c39a180955d3e10f914be',
    easeAmount: '11031161067940136438663',
    depositStart: '1650384384',
    vArmorAmount: '9373378282826081591348',
    proof: [
      '0x66ebe3fd24308f3d1c3fab8ef6d19062e166fa2c73634e6e6bbffd2e519a9540',
      '0x7e258df414a0af1a22e7d59bbbfc3788e125b317b2aecc8baa36e46c6fb89087',
      '0xe612796e9657f5eff9650e6fd598deb7cfd20a0a7f2fcb2fdc547c08db843a7c',
      '0xb9931aca0989cb06b570ebb51e0ccb8160800c8dd77b35c974d61b87c9f0d764',
      '0x1cfbb30fa84e3a287a7c787cb069823b5467671a5536b0babf6a5a292dce988f',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xee80dd2eaa099b4720048b351cd6afbde7505151',
    easeAmount: '2904531999999999998532730',
    depositStart: '1661948454',
    vArmorAmount: '2468033691367106207245912',
    proof: [
      '0x1ab91dd45abc57e7a9d21e23da650dfde5f312b27f466c1328c873cd0fb1c4b9',
      '0x8014d8cd42bf4efb3e0a826f584e1d5d6b415d5c4c4f6cc2ea60fdc29e07389c',
      '0x3601e1e60c7fdb7bcb6c9955d2f215e9a68739a7c3d1fb256c43ffe5475e91d2',
      '0x714beb55a12f318ceb023c458f50fe42d7128d6ec882f13afd7783ecc63115ae',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xf149a87efb6444db819b4b7dff8bf33ffb51732b',
    easeAmount: '13493932379913071295879',
    depositStart: '1653010288',
    vArmorAmount: '11466039879283470783989',
    proof: [
      '0x25f7ed77e01e1a67ce0e99994061319239f320dae71f382d880b66aea9e2aab2',
      '0xbbbb79d5283ab0436cae441b419b00579ca390c1974bdad83a43873419fcd8bb',
      '0x6bf800b804e71b8c80e24bfbffcb69bb0d5bc93f33a68f43d64abe96df2511ee',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xf1df232bc4a0b7bfe55c9217b798d83809d01920',
    easeAmount: '106852218489263257773886',
    depositStart: '1649894400',
    vArmorAmount: '90794274337077696080812',
    proof: [
      '0x40bb1c1f34b62ab1927a90af297a5c8be618c2222426e3d430e04b7ecae57824',
      '0x1d80c85eb8d9db32414be7a2b1c0f2b936e7588f62c2ea3d33ab3ad18dd31255',
      '0x8e7dbe80b90aa42ddc7d93595bf13f6079ca5db7c3142055f7f541e3df164de3',
      '0x0ddb605aaf6107fe664facd4fccebf50d431234236c39bcd028e8bfb26ebd3f9',
      '0xb74e37117ff6ad6ddd08a1b76d20bb12156dec4e8823810fb26f63e825834e85',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xf1fb5dea21337feb46963c29d04a95f6ca8b71e6',
    easeAmount: '913464540447666847423123',
    depositStart: '1650562797',
    vArmorAmount: '776187441451501483137481',
    proof: [
      '0xcc359346799a2305c7e0ee99dc3a3b3f482c63dc44151937612a6432ccb7b3cd',
      '0x41cd074b0fbf3b3f0aef7b02e4b81bf46072753757a4c4bd46542b2b6247dde4',
      '0xfe5a2d4b6fd37c254604c8c7363d69560f2945552c9824d5d639700f61743275',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xf4f3cc629f76d17b23dccd10bf9e576a9cbca43d',
    easeAmount: '10415118852960802906124',
    depositStart: '1653718512',
    vArmorAmount: '8849916003232187308605',
    proof: [
      '0xe8efaf3494f7c997d319570160c88638f082b4b2e062e23e8e6d41d703c9bcd1',
      '0xa26203c6bc3bdd842d4315a1fac58ee780b166f559e8fcc5520f3b95d24e947a',
      '0xc2dcfb68f776f22eb8f9baf45d648b27e25341bcab6369eff887a706a7576aca',
      '0x9cd607318b6525d10f6c5395d3ec3b8b97d488be123ee98ed8f23d8a0e906cf4',
      '0x4d34772fa26b4f7ebeb5ef177bee3dd264d533138aa456d2f631c395b54a74d1',
      '0xd1b18bcef0d456bb7317daf9aa47bf3ab3ec8b842d228534ed6777de435a0b12',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xf6101db467c47e6d1b1bcaf461b1157fa92df019',
    easeAmount: '9436171941957466048101451',
    depositStart: '1649894400',
    vArmorAmount: '8018087000000000000000000',
    proof: [
      '0xcad00b016152bf471b02bbae474cffcd92a46c13297ac8e3bc44f8ab32f9e5a1',
      '0x327fdd1977968cea258228b3825c2b96ad0010656948c9236acfe0360a2b152e',
      '0x418282c3e9827960484d02968e0a5649277d1142f4624e40a7269e9b5ef44eb5',
      '0x50fb15f89c27c79d12154cd0e3025db7582ec1427bc4407c24ae516a56244b8d',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xf69f7faf5fdc1268d0911c08a1e51cc8738a642a',
    easeAmount: '718885653796869931026553',
    depositStart: '1649894400',
    vArmorAmount: '610850220900008829933197',
    proof: [
      '0x4d183e7fa5c6876dbe5b160f59d2940e469195836820e469cd20a31c4ed01164',
      '0x2d0735e9d71dc3024da4518e99630b3105008cb157e5b6a29faa399adb95cacb',
      '0x8c6bfd6e49629ff86ec70aa037e638af7f0d49f147635cd608731c48f9d7e058',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xf72c857953fcdab9a7a38bd3e9f5dc91065a1d78',
    easeAmount: '15829191920985688099403',
    depositStart: '1649894400',
    vArmorAmount: '13450352403798161902765',
    proof: [
      '0xd511f5c4f6c1c3aeb6a185e050993ba58bc3b9ce80498676fdb4a68c26b4493b',
      '0x3187a66d2cb42273c18163f8d547df7d6b9099f219d9903f0a18ff42f59fbd78',
      '0xa59c353926ba4fc36132f3b84ea91726d582fbed1a28f667b346c3e88a53d98b',
      '0x68d4ce5836cbb8bab3ae6f569afff0b7e6ebc44bac19668f274bbae4b67e8f9a',
      '0x5e3ca6f35dd8632bea7dba7f7c7d913b9635fd98b31fd2232a1f342a054cee8f',
      '0x10ae6df5b76285e69d48167fcceefa9cacaa828f1efa8515a2a225b365d7de39',
      '0xff203bd94a8d8e51fd4aed1c0108fbe1ce32ee438efcb711a8d0849fce55192d',
      '0x5eb458ff87ed3b1a90bf4582e2315cf9fdcd000a2997aa839ff828d580ccb41a'
    ]
  },
  {
    address: '0xf7b65d95de818ff98b996b188956b2253046fe57',
    easeAmount: '17673467405605232808368',
    depositStart: '1656936955',
    vArmorAmount: '15017466841581403278443',
    proof: [
      '0xa357ff878777c3c8a1eec4a39ef2b2adffe19f263ce1433e0ef75a5cfc828e36',
      '0x5db286e3c86d7a358427e10ecf3a0ac341086c8dd512660e838a23de0c951773',
      '0x85c6f7cdb70906fb722ce51c282b9c1fc3bf100f7a3a639792868f19bb8e285d',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xf86bd9edbc72ff0ba33e2fee58ad1e840c1b7c1c',
    easeAmount: '15711652931749674075582',
    depositStart: '1649894400',
    vArmorAmount: '13350477385900711210527',
    proof: [
      '0x2a50a68faa0c7a3771ed9c9ad0fe848d6f4147dc952a9d5e1d1b27d9a8513909',
      '0x0a14802dd107deabcc8effb4d592906baa5329dc3ff7841912889cc3e09492e4',
      '0x3d94121da30c2ba376fd293fc5f5dfc4c17d94cbeab3dec387bbc807e9a4da26',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfa760444a229e78a50ca9b3779f4ce4cce10e170',
    easeAmount: '82386373449176356858416',
    depositStart: '1649894400',
    vArmorAmount: '70005200625133300000000',
    proof: [
      '0x9b9c019fc98ec23b7b0a2afb12b235d52da8e7b7894b7b240c75029848870849',
      '0x2368ac8ab5e81257e0786b5800573901b633a4046a85c9b2a94b170f4532f216',
      '0xce9e0607dd3e91f01706b6d8a3649dbb9e06de2a4162aa62319053aadcb0d9ec',
      '0xe16dd233cc56e2df54e87b4cdcdf96331e6337d4a2da129b6337832ec3eb3c07',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfa7c9bcf937bfca83e4fbbf87c67e35fa8ae5015',
    easeAmount: '14187958076245063554838',
    depositStart: '1649894400',
    vArmorAmount: '12055766141972906864652',
    proof: [
      '0x87ac2109a08125578898f046ce1fce502e8fa189def17f940225211a2821e1ed',
      '0xfb12632824692e35764bf14ed6662ee34072b92fe0e43dc52e33631ff6de556e',
      '0xc3ba78039c56948fca407ace5e54e1717c45ece4f2e76d4481f4d68e89546780',
      '0xe0507cb2d3334fd135c84de47385b3cf27524234d0e3d33623e545d8f6cc14f7',
      '0xc778ab19cfee2574d24c528064993c84b310404b4dc16a83db68893f798f1bb1',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfacabd7e97410c71c687113451aedc111cb09168',
    easeAmount: '25825864653390175976226',
    depositStart: '1649894400',
    vArmorAmount: '21944707124333223710979',
    proof: [
      '0x76442366514bef26c07729f5e19af053c58d2410e00ed80b18aee3c6d0d11b55',
      '0x060e77cb92efa9a81def17fa042617c50429a7348b6ad2a89cfca54d639a2a0c',
      '0xbec0c245cafeb8c6d1ffebd48663aa60f7b941b4324c44f80f443197e25ef59a',
      '0x14ca9fd06b332e4fa93f3301f49a592432c1e82e9141330c4d948dd455ce50ed',
      '0x7dc8544c5e1a5abedc70cd599ac091db2ac8db9bed4e361d78af65d0cbdb6593',
      '0x0422f0b783c166b91e2a1bac7d345b3ea2ed8f754dc707f0c9b513e7944c7ba9',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfaf2867b6536001d1d732a66a61edf918193d1c6',
    easeAmount: '9287513349793198512432',
    depositStart: '1649894400',
    vArmorAmount: '7891769089241015634705',
    proof: [
      '0x29dd2384a910dbfc3dd8786aa423223f390165c94370e6c9d8b7fa6ee2a7c1c4',
      '0x0a14802dd107deabcc8effb4d592906baa5329dc3ff7841912889cc3e09492e4',
      '0x3d94121da30c2ba376fd293fc5f5dfc4c17d94cbeab3dec387bbc807e9a4da26',
      '0xba02205f120cbaf8424d785de09668a7cae18fe3aec8c55cf5a4e8257218df61',
      '0x9ee3a33bc2cf45bd30982448af38e2d6db1800642100998c6f811ae2017ff205',
      '0xb564c5c70067675eef400e5c76e635d363cf2d86a3060171be371a0004e8b77e',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfdc197e02b8c88cd7ac9e2230f969885f7792752',
    easeAmount: '11754607964491498216729',
    depositStart: '1649894400',
    vArmorAmount: '9988104274691116794071',
    proof: [
      '0xa38e8050e85eeac9ddd2591aff69da7080806866efb85a7e8cee8ff513fb73bd',
      '0x5db286e3c86d7a358427e10ecf3a0ac341086c8dd512660e838a23de0c951773',
      '0x85c6f7cdb70906fb722ce51c282b9c1fc3bf100f7a3a639792868f19bb8e285d',
      '0x54a98f0ecd08110c81ce1799324c390ab39a4c3700476e1ca1029e0c02d58342',
      '0x77a7e5541134effd3ca318a306cad7228662f8209efc071d560c97cc3b4eab92',
      '0x831b7cdecd55602429868145ec8d563d23311da74078256f513331947396642f',
      '0xcfe6da257fca7e22577f84573893973dbae2e3839022b5793b902d00474e6a80',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfe763070ff20407fcb5b00f2f7ef72cb3dc9cd1a',
    easeAmount: '8788121643505208430271',
    depositStart: '1649894400',
    vArmorAmount: '7467426869458942010695',
    proof: [
      '0x466d407e083b2815515ffe3da5a433da2d0388fd3eb5b5a29234c5260cda6415',
      '0x538b28bd7cae6b83315f406aaf793d7be0d30e39ce6e98395404e8752407f8d9',
      '0x8c6bfd6e49629ff86ec70aa037e638af7f0d49f147635cd608731c48f9d7e058',
      '0x088052c3bb4a00b902420d318bb70fe63bada858f3c13707605effcf68847400',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  },
  {
    address: '0xfffede3d3ce68ca7643b426a11207fa02620b106',
    easeAmount: '586918714756345591028581',
    depositStart: '1649894400',
    vArmorAmount: '498715511522180261317639',
    proof: [
      '0x5763a212d0b1714a47eb02472d1f262ebb5d5021f08532cbe106ce6640ed31be',
      '0xab0b96cb8b698da3dc44d539ef16a6716f7a9eda62ec02643f083c0ea41aafc9',
      '0x184cdf731725b000bb5cb354d9724110ff7b17870ad031ad2a73fc109337bd0e',
      '0xaa92cb3e9313f707c571bc6e6ec21506dc5f0fe51c497fa159098d05edb1e5c2',
      '0xf07a49364ba34955448caea0167ea6c56ef63b5a6acbf65b95355ef166b250ed',
      '0x42add22961d9ecf240d6334f594dd7d472a3a965ad468f4ef952e41aeeee5177',
      '0xcb660569c21c90dee1c06f7bfa94aa560e0bfb0c2b6498b5adc8fc6d51e0d9ee',
      '0x33e7a96fbb618fcb172d252664560ab1e7f6939ad75cad4226e337a1e496540f'
    ]
  }
]

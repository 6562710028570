import clsx from 'clsx'
import { ExclamationIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { XIcon } from '@heroicons/react/solid'

export const WarningAlert: React.FC<{
  className?: string
  children?: React.PropsWithChildren<any>
  closable?: boolean
}> = ({ children, className, closable = true }) => {
  const [hidden, setHidden] = useState(false)
  if (hidden) {
    return <></>
  }

  return (
    <div className={clsx(className, 'rounded-md bg-yellow-50 p-4')}>
      <div className="flex relative">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{children}</p>
          </div>
        </div>
        <div className={'absolute right-0'}>
          <XIcon
            className={'h-4 w-4 text-yellow-800 cursor-pointer'}
            onClick={() => setHidden(true)}
          />
        </div>
      </div>
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import { VaultsApi } from '../hooks/api/useGetVaults'
import { BigNumber } from 'ethers'

// Define a type for the slice state
interface VaultsState {
  withdrawalDelay: number
  vaults: VaultsApi.Vault[]
}

// Define the initial state using that type
const initialState: VaultsState = {
  withdrawalDelay: 0,
  vaults: []
}

export const vaultsSlice = createSlice({
  name: 'vaults',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setWithdrawalDelay: (state, action: PayloadAction<number>) => {
      state.withdrawalDelay = action.payload
    },
    setVaults: (state, action: PayloadAction<VaultsApi.Vault[]>) => {
      state.vaults = action.payload
    }
  }
})

export const { setWithdrawalDelay, setVaults } = vaultsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectWithdrawalDelay = (state: RootState) =>
  state.vaults.withdrawalDelay
export const selectWithdrawalDelayHours = (state: RootState) =>
  state.vaults.withdrawalDelay / 60 / 60
export const selectWithdrawalDelayDays = (state: RootState) =>
  state.vaults.withdrawalDelay / 60 / 60 / 24
export const selectVaults = (state: RootState) => state.vaults.vaults

export default vaultsSlice.reducer

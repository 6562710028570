import Contracts from '../../contracts'
import { makeWeb3Contract } from '../../classes/helpers'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'

type ContractMethodCallResponse<T> = [
  T,
  (poolIndex: number | string) => Promise<T>,
  Function
]

export const useGetDepositedEzTokenBalance = () =>
  useMasterChefUserInfoCall<number>()

const useMasterChefUserInfoCall = <T>(): ContractMethodCallResponse<T> => {
  const { account, library } = useWeb3React()
  let  defaultValue: unknown = undefined
  const [value, setValue] = useState(<T>defaultValue)
  const clear = () => setValue(defaultValue as T)

  const get = useCallback(
    async (poolIndex: number | string) => {
      const _contract = makeWeb3Contract(
        library.currentProvider,
        Contracts.masterChef,
        Contracts.masterChef.address
      )
      // const poolIndex = params? params[0] : 0
      let balance = await _contract.methods['userInfo'](poolIndex , account).call({
        from: account
      })
      setValue(balance.amount)
      return balance.amount
    },
    [account]
  )
  return [value as T, get, clear]
}

import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import './App.css'
import 'react-tippy/dist/tippy.css'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { Provider as ReduxProvider } from 'react-redux'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Dashboard } from './pages/dashboard'
import { Layout } from './components/Layout'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from './store'
import { TokenSwap } from './pages/tokenSwap'
import { TestCases } from './pages/testCases'
import { isDevelopmentUrl } from './classes/helpers'
import { GvDashboard } from './pages/gvDashboard'
import { GvBribing } from './pages/gvBribing'
import { TxHistory } from './pages/txHistory'

function App() {
  function getWeb3Library(
    provider: provider,
    connector?: AbstractConnector
  ): Web3 {
    return new Web3(provider)
  }
  const queryClient = new QueryClient()

  return (
    <ReduxProvider store={store}>
      <Web3ReactProvider getLibrary={getWeb3Library}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path={'/'} element={<Dashboard />} />
              </Routes>
              <Routes>
                <Route path={'/gv-dashboard'} element={<GvDashboard />} />
              </Routes>
              <Routes>
                <Route path={'/gv-bribing'} element={<GvBribing />} />
              </Routes>
              <Routes>
                <Route path={'/token-swap'} element={<TokenSwap />} />
              </Routes>
              <Routes>
                <Route path={'/tx-history'} element={<TxHistory />} />
              </Routes>
              {isDevelopmentUrl() && (
                <Routes>
                  <Route path={'/test-cases'} element={<TestCases />} />
                </Routes>
              )}
            </Layout>
          </BrowserRouter>
        </QueryClientProvider>
      </Web3ReactProvider>
    </ReduxProvider>
  )
}

export default App

import React, { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useWeb3React } from '@web3-react/core'
import { useWeb3Connect, useWeb3Disconnect } from '../hooks/useWeb3Connect'
import { connectorsByName } from '../config/connectorsByName'

export const WalletConnect: React.FC<{
  children?: React.PropsWithChildren<any>
  onClose: Function
  open: boolean
}> = ({ onClose, open }) => {
  const { account } = useWeb3React()
  const onConnect = useWeb3Connect()
  const onDisconnect = useWeb3Disconnect()

  const mocks = [
    { name: 'MetaMask', icon: 'metamask.svg' },
    { name: 'TrustWallet', icon: 'trustWallet.png' },
    { name: 'WalletConnect', icon: 'walletConnect.svg' },
    { name: 'GnosisSafeApps', icon: 'gnosisSafeApps.png' },
    { name: 'WalletLink', icon: 'walletLink.svg' },
    { name: 'Ledger', icon: 'ledger.svg' },
    { name: 'Trezor', icon: 'trezor.png' },
    { name: 'Torus', icon: 'torus.jpg' },
    { name: 'Autheruem', icon: 'authereum.svg' }
  ]

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-30"
        onClose={() => onClose()}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {' '}
                        Connect your Wallet{' '}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div className="h-full flex flex-col justify-between">
                        <div>
                          {connectorsByName.map((connector) => (
                            <button
                              key={connector.name}
                              type="button"
                              onClick={() => {
                                onClose()
                                onConnect(connector.injectedConnector)
                              }}
                              className="w-full mb-4 justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-bold rounded-md text-white bg-white border-blue-900 text-blue-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {connector.name}
                              <img
                                src={`/icons/${connector.icon}`}
                                className="ml-3 -mr-1 h-5 w-5"
                                aria-hidden="true"
                                alt={connector.name}
                              />
                            </button>
                          ))}
                        </div>
                        <div>
                          {account && (
                            <button
                              type="button"
                              className="w-full justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-900 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => {
                                onDisconnect()
                                onClose()
                              }}
                            >
                              Disconnect
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import React from 'react'

export const TooltipHtml: React.FC<{
  title: string
  link?: string
  name?: string
}> = ({ title, link, name }) => {
  return (
    <div>
      {title}{' '}
      <div>
        {link && (
          <>
            <a
              className="text-orange-500 underline"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more {name && <span>about {name}</span>} here.
            </a>
          </>
        )}
      </div>
    </div>
  )
}

import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from './redux'
import { selectImpersonationAddress } from '../store/globalSlice'

export type Web3ReactData = { account: string; chainId: number; library: any }
export const useImpersonatableWeb3React = (): Web3ReactData => {
  const { account, chainId, library } = useWeb3React()
  const impersonationAddress = useAppSelector(selectImpersonationAddress)

  if (impersonationAddress != '') {
    return {
      account: impersonationAddress,
      chainId,
      library
    } as const as Web3ReactData
  }

  return { account, chainId, library } as const as Web3ReactData
}

import { PageTitle } from './PageTitle'
import React from 'react'

export const PageLoading: React.FC<{
  children: React.PropsWithChildren<any>
  title: string
}> = ({ children, title }) => {
  return (
    <div>
      <div className={'w-full container-sm mx-auto'}>
        {/*<PageTitle className={'mt-4'}>{title}</PageTitle>*/}
        <div className={'mt-4 mx-2 md:mx-none mb-16'}>
          <div className={'p-7 text-center text-lg flex flex-col items-center'}>
            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-100">
              <svg
                className="animate-spin h-4 w-4 text-blue-900"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </div>
            <div className={'pt-5 text-white'}>
              {children}
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

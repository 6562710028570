import { ContractInterface } from '../contracts'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'
import Web3 from 'web3'
import { BigNumber, BigNumberish } from 'ethers'

const useGetNonce = (): [number, Function] => {
  const { account, library } = useWeb3React()
  const [value, setValue] = useState<number>(0)

  const get = useCallback(
    async (address: string) => {
      if (!account) {
        return BigNumber.from('0')
      }
      const web3 = new Web3(library.currentProvider)
      const nonce = await web3.eth.getTransactionCount(address)
      // console.log(methodName, result)

      console.log('nonce', nonce, 'address', address)
      setValue(nonce)
      return nonce
    },
    [account, library]
  )

  return [value, get]
}

export default useGetNonce

import { useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ContractInterface } from '../contracts'
import {
  capitalize,
  makeWeb3Contract,
  parseWeb3Error
} from '../classes/helpers'
import { TransactionReceipt } from 'web3-core'
import { notifyError, notifyPending, notifySuccess } from '../components/Layout'
import { BigNumber, BytesLike, ethers } from 'ethers'
import { BuildSignMessageParams, signEncryptionKey } from '../utils/metamask'

export interface UseSignMessageProps {
  onSuccess: (val: { v: number; r: string; s: string }) => void
  onError: (error: Error) => void
}

const useSignMessage = (props: UseSignMessageProps) => {
  const { account, library, error } = useWeb3React()

  return useCallback(
    async (params: BuildSignMessageParams) => {
      if (account) {
        const web3Provider = new ethers.providers.Web3Provider(
          library.currentProvider
        )
        const providerRequest = web3Provider?.provider?.request

        try {
          signEncryptionKey(params, providerRequest as any)
            .then((res) => {
              props.onSuccess(res)
              return true
            })
            .catch((err) => {
              props.onError(err)
            })
        } catch {
          props.onError(new Error('no account'))
        }
      } else {
        props.onError(new Error('no account'))
      }
    },
    [account, library, props.onError, props.onSuccess]
  )
}

export default useSignMessage

import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Func } from 'mocha'
import {
  classNames,
  countDecimals,
  formatDecimals,
  weiToEth
} from '../classes/helpers'
import { Button } from './Button'

export interface SliderModalData {
  isOpen: boolean
  title: string
  buttonText: string
  symbol: string
  maxAmount: string
  children?: ReactNode
  onConfirm: () => void
}

export const SliderModal: React.FC<{
  children: React.PropsWithChildren<any>
  title: ReactNode | string
  confirmText: string
  maxAmount: string
  symbol: string
  onChange: Function
  onConfirm: Function
  onClose: Function
  open: boolean
}> = ({
  children,
  title = 'Confirm',
  confirmText = 'OK',
  symbol,
  onConfirm,
  onChange,
  onClose,
  maxAmount,
  open = false
}) => {
  const cancelButtonRef = useRef(null)
  const [value, setValue] = useState<number>(50)

  useEffect(() => {
    onChange(value)
  }, [value])

  useEffect(() => {
    if (open) {
      setValue(50)
    }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-4">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 font-poppins"
                  >
                    {title}
                  </Dialog.Title>

                  <div className={'flex justify-center mt-4'}>
                    <div className={'flex gap-x-2 items-center'}>
                      <input
                        id="default-range"
                        type="range"
                        value={value}
                        min="0"
                        max="100"
                        onChange={(e) => {
                          e.preventDefault()
                          setValue(Number(e.target.value))
                        }}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 text-green-500"
                      />
                      <div>{symbol}</div>
                    </div>
                  </div>

                  {children}

                  <div className={'text-sm pt-4'}>
                    <span className={'font-bold'}>Current Balance:</span>{' '}
                    <span
                      className={'text-pink-500 cursor-pointer'}
                      onClick={() => setValue(100)}
                    >
                      {formatDecimals(weiToEth(maxAmount), 3, 0)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <Button
                  onClick={() => onConfirm()}
                  disabled={Number(maxAmount) == 0}
                  classes={'sm:col-start-2 capitalize font-normal'}
                >
                  {confirmText}
                </Button>
                <Button
                  onClick={() => onClose()}
                  buttonRef={cancelButtonRef}
                  variant={'white'}
                  classes={'capitalize font-normal'}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

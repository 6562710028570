import clsx from 'clsx'
import {
  ExclamationIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
import React, { useState } from 'react'
import { XIcon } from '@heroicons/react/solid'

export const InfoAlert: React.FC<{
  className?: string
  children?: React.PropsWithChildren<any>
  closable?: boolean
}> = ({ children, className, closable = true }) => {
  const [hidden, setHidden] = useState(false)
  if (hidden) {
    return <></>
  }

  return (
    <div className={clsx(className, 'rounded-md bg-green-50 p-4')}>
      <div className="relative flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="w-5 h-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <div className="mt-2 text-sm text-green-700">
            <p>{children}</p>
          </div>
        </div>
        <div className={'absolute right-0'}>
          <XIcon
            className={'h-4 w-4 text-green-800 cursor-pointer'}
            onClick={() => setHidden(true)}
          />
        </div>
      </div>
    </div>
  )
}

import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { Transition } from '@headlessui/react'

import { JoyrideTooltip } from '../components/gvDashboard/JoyrideTooltip'
import { useJoyrideSteps } from '../hooks/gvDashboard/useJoyrideSteps'
import { AppDispatch } from '../store'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { setOpenDepositPanel, setTabPanelIndex } from '../store/gvJoyrideSlice'
import { PageTitle } from '../components/PageTitle'
import { VaultsApi } from '../hooks/api/useGetVaults'
import { PageLoading } from '../components/PageLoading'
import { selectConfig } from '../store/globalSlice'
import { Tooltip } from 'react-tippy'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { WrongChainAlert } from '../components/WrongChainAlert'
import { useGetUserStats } from '../hooks/gvDashboard/useGetUserStats'
import useContractMethodSend from '../hooks/useContractMethodSend'
import Contracts from '../contracts'
import { ConnectWalletPage } from '../components/ConnectWalletPage'
import { LoadingModal } from '../components/LoadingModal'
import { AmountModal, AmountModalData } from '../components/AmountModal'
import {
  formatDecimals,
  isDevelopmentUrl,
  weiToEth,
  scrollToTop
} from '../classes/helpers'
import { Button } from '../components/Button'
import { ConnectToWalletTabPanel } from '../components/dashboard/ConnectToWalletTabPanel'
import useGetEase, {
  EaseEventsApi,
  EaseStatsApi,
  EaseUsersApi
} from '../hooks/api/useGetEase'
import { SliderModal, SliderModalData } from '../components/SliderModal'
import { useImmer } from 'use-immer'
import { formatUnits } from 'ethers/lib/utils'
import { PercentageOfModalStat } from '../components/gvDashboard/PercentageOfModalStat'
import { TenderlyRpcAlert } from '../components/dashboard/TenderlyRpcAlert'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { StakePanel } from '../components/gvDashboard/Panels/StakePanel'
import { LeasePanel } from '../components/gvDashboard/Panels/LeasePanel'
import { selectVaults } from '../store/vaultSlice'
import { BigNumber } from 'ethers'
import { StatsCard } from '../components/gvDashboard/StatsCard'
import { SuccessAlert } from '../components/SuccessAlert'
import moment from 'moment'
import { TooltipHtml } from '../components/TooltipHtml'
export const GvDashboard: React.FC = () => {
  const vaults = useAppSelector(selectVaults)
  const { isLoading: isEaseEventsLoading, data: easeEvents } = useGetEase()
  const { account, chainId } = useImpersonatableWeb3React()

  let stakedLength = 0
  if (easeEvents && easeEvents.users && account) {
    Object.keys(easeEvents.users).map((k: any, i) => {
      if (k.toLowerCase() == account?.toLowerCase()) {
        if (easeEvents.users[k] && easeEvents.users[k].vaults) {
          for (let v of Object.keys(easeEvents.users[k].vaults)) {
            const vault = easeEvents.users[k].vaults[v as any]
            if (vault.percentageStaked) stakedLength++
          }
        }
      }
    })
  }

  const stats = useGetUserStats()
  const [isLoading, setIsLoading] = useState(true)
  const [modalValue, setModalValue] = useState('0')
  const amountModalRef = useRef('0')
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const globalConfig = useAppSelector(selectConfig)
  const [userEaseStats, setUserEaseStats] = useState<EaseStatsApi.User>(
    {} as EaseStatsApi.User
  )
  const [userEvents, setUserEvents] = useState<EaseEventsApi.Event[]>(
    {} as EaseEventsApi.Event[]
  )
  const [userVaults, setUserVaults] = useState<EaseUsersApi.Vault[]>(
    [] as EaseUsersApi.Vault[]
  )
  const [easeStats, setEaseStats] = useState<EaseStatsApi.Response>(
    {} as EaseStatsApi.Response
  )
  const [amountModalObj, setAmountModalObj] = useImmer<AmountModalData>(
    {} as AmountModalData
  )
  const [sliderModalObj, setSliderModalObj] = useImmer<SliderModalData>(
    {} as SliderModalData
  )
  const isOnWrongChain = useMemo(() => chainId && chainId != 1, [chainId])

  const [stepIndex, setStepIndex] = useState(0)
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false)
  const { steps, setOverrideSteps } = useJoyrideSteps(account, stakedLength)
  const [gvJoyrideSeenTour, setGvJoyrideSeenTour] = useLocalStorage(
    'gv_joyride_seen_tour',
    false
  )
  const dispatch: AppDispatch = useAppDispatch()

  useEffect(() => {
    if (!gvJoyrideSeenTour) {
      setIsJoyrideRunning(true)
    }
  }, [])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step, lifecycle } = data

    // clicked "Not Now" or "Never" on intro
    if (type == EVENTS.STEP_AFTER && action == ACTIONS.CLOSE) {
      setIsJoyrideRunning(false)
      setGvJoyrideSeenTour(true)
      return
    }

    // automatically jump to the start of deposit tab when clicking previous from farming tab -- much cleaner feel
    // if (
    //   type == EVENTS.STEP_AFTER &&
    //   action == ACTIONS.PREV &&
    //   step.target == '.farming-tab'
    // ) {
    //   setStepIndex(findStepIndex('.deposit-tab'))
    //   dispatch(setTabPanelIndex(0))
    //   return
    // }

    if (type == EVENTS.STEP_AFTER || type == EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (
      status == STATUS.FINISHED ||
      status == STATUS.SKIPPED ||
      status == STATUS.PAUSED
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsJoyrideRunning(false)
      setGvJoyrideSeenTour(true)
      dispatch(setOpenDepositPanel(false))
      scrollToTop()
    }

    switch (step.target) {
      case '.first-step':
        dispatch(setOpenDepositPanel(true))
        dispatch(setTabPanelIndex(0))
        break
      case '.second-step':
        dispatch(setTabPanelIndex(1))
        break
      case '.third-step':
        dispatch(setTabPanelIndex(2))
        break
      case '.fourth-step':
        dispatch(setTabPanelIndex(3))
        scrollToTop()
        dispatch(setOpenDepositPanel(false))
        break
    }

    // console.groupCollapsed(type)
    // console.log(data) //eslint-disable-line no-console
    // console.groupEnd()
  }

  useEffect(() => {
    if (easeEvents && easeEvents.users && account) {
      Object.keys(easeEvents.users).map((k, i) => {
        if (k.toLowerCase() == account?.toLowerCase()) {
          // @ts-ignore
          setUserEaseStats(easeEvents.users[k])
          // @ts-ignore
        }
      })
    }
  }, [easeEvents, account])

  useEffect(() => {
    if (easeEvents) {
      setEaseStats(easeEvents)
    }
  }, [easeEvents, chainId])

  useEffect(() => {
    if (account && chainId == 1) {
      setIsLoading(true)
      Promise.all([
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/events?q=${account}`)
          .then((r) => r.json())
          .then((j: EaseEventsApi.Event[]) => {
            setUserEvents(j)
          }),
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/${account}`)
          .then((r) => r.json())
          .then((j: EaseUsersApi.Response) => {
            setUserVaults(j.vaults)
          }),
        stats.easeBalance.get([account]),
        stats.earningsPerWeek.get(['1000000000000000000']),
        stats.bribePotBalance.get([account]),
        stats.gvTokenBalanceOf.get([account]),
        stats.easeEarned.get([account]),
        stats.totalEaseDeposits.get([account]),
        stats.withdrawRequests.get([account])
      ])
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
        })
        .then(() => {
          setIsLoading(false)
        })
    }
  }, [account, chainId])

  // smart contract callbacks and event handlers
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }
  const onFinalizeWithdraw = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Finalize Withdraw')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to finalize withdraw for{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(
              Number(formatUnits(stats.withdrawRequests.value.amount, 18)),
              4,
              2
            )}
          </span>{' '}
          $Ease
        </div>
      </>
    )
    withdrawFinalize().catch((err) => console.error(err))
  }

  const onFinalizeWithdrawSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.easeBalance.get([account]),
      stats.withdrawRequests.get([account])
    ])
      .catch((err) => console.error(err))
      .then(() => {
        //console.log('loaded')
      })
  }

  const withdrawFinalize = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'withdrawFinalize',
    onSuccess: onFinalizeWithdrawSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const canWithdraw = () => {
    if (stats.withdrawRequests.value.endTime == 0) {
      return false
    }
    return moment(stats.withdrawRequests.value.endTime * 1000)
      .utc()
      .isBefore(moment().utc())
  }

  if (!vaults) {
    return <PageLoading title={'gvDashboard'}>Loading gvDashboard...</PageLoading>
  }

  return (
    <div>
      <Transition.Root show={isEaseEventsLoading}>
        <PageLoading title={'gvDashboard'}>Loading gvDashboard...</PageLoading>
      </Transition.Root>

      <Transition
        show={!isEaseEventsLoading}
        as={Fragment}
        enter="transition ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className={'w-full container-lg mx-auto gv-ease-app mb-12'}>
          {isDevelopmentUrl() && (
            <TenderlyRpcAlert className={'mt-4'}>
              When using the development version of Ease, please ensure you are
              using the most up-to-date Tenderly RPC:{' '}
              <span className={'font-bold'}>
                {/* added test to work around an issue where simulation_fork comes up as undefined */}
                {globalConfig.simulation_fork &&
                  globalConfig.simulation_fork.rpc}
              </span>
            </TenderlyRpcAlert>
          )}

          {isOnWrongChain && (
            <WrongChainAlert
              chainId={chainId ? chainId : 0}
              className={'mb-4 mt-8'}
            />
          )}

          {stats.withdrawRequests && stats.withdrawRequests.value.endTime > 0 && (
            <div className={'mt-4 mb-4'}>
              <SuccessAlert
                title={'Pending Withdrawal Request'}
                closeable={false}
              >
                <div className={'flex justify-between items-center'}>
                  <div>
                    You have a pending withdrawal request. You can withdraw your{' '}
                    <span className={'font-bold'}>
                      {formatDecimals(
                        Number(
                          formatUnits(stats.withdrawRequests.value.amount, 18)
                        ),
                        2,
                        2
                      )}
                    </span>{' '}
                    {canWithdraw() ? (
                      <span>now! </span>
                    ) : (
                      <span>
                        {moment(
                          stats.withdrawRequests.value.endTime * 1000
                        ).fromNow()}
                      </span>
                    )}
                  </div>
                  {canWithdraw() && (
                    <div className={'flex justify-end'}>
                      <Button
                        onClick={() => onFinalizeWithdraw()}
                        size={'sm'}
                        variant={'green'}
                      >
                        Finalize Withdrawal
                      </Button>
                    </div>
                  )}
                </div>
              </SuccessAlert>
            </div>
          )}

          {!account && (
            <ConnectWalletPage
              title={<></>}
              text={'Please connect wallet to view the $gvEase dashboard'}
            />
          )}

          <PageTitle>
            <div className={'mt-4 flex gap-x-2 justify-center items-center'}>
              gvDashboard{' '}
              <Tooltip
                position="top"
                trigger="mouseenter"
                interactive
                html={
                  <TooltipHtml
                    title="gvEase is Growing Vote Ease. It is the basis for Ease’s DAO and staking systems."
                    link="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/what-is-gvease/"
                    name="gvEase"
                  />
                }
              >
                <InformationCircleIcon
                  onClick={() => setIsJoyrideRunning(true)}
                  className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
                />
              </Tooltip>
            </div>
          </PageTitle>
          <Joyride
            steps={steps}
            run={isJoyrideRunning}
            stepIndex={stepIndex}
            scrollOffset={150}
            tooltipComponent={JoyrideTooltip}
            callback={handleJoyrideCallback}
            continuous={true}
          />

          <StatsCard
            stats={stats}
            onUpdate={() => {
              Promise.all([
                stats.gvTokenBalanceOf.get([account]),
                stats.easeEarned.get([account]),
                stats.totalEaseDeposits.get([account]),
                stats.withdrawRequests.get([account])
              ]).catch((err) => console.error(err))
            }}
          />

          {vaults && (
            <>
              <div
                className={
                  'text-xl font-bold text-white mt-2 mb-2 flex gap-x-2 items-center'
                }
              >
                Leasing{' '}
                <Tooltip
                  interactive
                  position="top"
                  trigger="mouseenter"
                  html={
                    <TooltipHtml
                      title="Leasing allows other users to pay you rewards in exchange for your $gvEase power. You cannot use leased tokens for your own staking, but you can still use them for governance voting. APR changes depending on $gvEase power."
                      link="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/what-are-bribes-and-leases/"
                      name="Leasing"
                    />
                  }
                >
                  <InformationCircleIcon
                    onClick={() => {}}
                    className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
                  />
                </Tooltip>
              </div>
              <div className={'second-step bg-blackop-50 rounded-lg p-2'}>
                <LeasePanel
                  userEvents={userEvents}
                  stats={stats}
                  userEaseStats={userEaseStats}
                  vaults={vaults}
                  easeStats={easeStats}
                />
              </div>

              <div className={'flex justify-between items-center'}>
                <div
                  className={
                    'text-xl font-bold text-white mt-2 mb-2 flex gap-x-2 items-center'
                  }
                >
                  Staking{' '}
                  <Tooltip
                    interactive
                    html={
                      <TooltipHtml
                        title="Staking allows users to lower the maximum fee of a vault for any particular hack. They can stake their $gvEase on one or more Uninsurance vaults."
                        link="https://ease.org/ease-announces-gv-tokenomics/#Staking"
                        name="Staking"
                      />
                    }
                    position="top"
                    trigger="mouseenter"
                  >
                    <InformationCircleIcon
                      onClick={() => {}}
                      className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
                    />
                  </Tooltip>
                </div>
                <div className={'text-base text-white'}>
                  Stakeable:{' '}
                  <span className={'font-bold'}>
                    {}
                    {userEaseStats.gvEaseStakeable &&
                      formatDecimals(
                        weiToEth(BigNumber.from(userEaseStats.gvEaseStakeable)),
                        3
                      )}{' '}
                  </span>
                  $gvEase
                </div>
              </div>
              <div className={'third-step bg-blackop-50 rounded-lg p-2'}>
                <StakePanel
                  stats={stats}
                  userStats={userEaseStats}
                  vaults={vaults as VaultsApi.Vault[]}
                  easeStats={easeStats}
                />
              </div>
            </>
          )}

          {/* {!account && (
          <div className={'bg-blackop-50 rounded-r-lg rounded-bl-lg '}>
            <ConnectToWalletTabPanel
              text={'Please connect your wallet to view leasing features'}
            />
            <ConnectToWalletTabPanel
              text={'Please connect your wallet to view your active stakes'}
            />
            <ConnectToWalletTabPanel
              text={'Please connect your wallet to view your active bribes'}
            />
            <ConnectToWalletTabPanel
              text={'Please connect your wallet to view your current power'}
            />
          </div>
        )} */}

          {amountModalObj.isOpen && (
            <AmountModal
              title={amountModalObj.title}
              confirmText={amountModalObj.buttonText}
              onConfirm={() => amountModalObj.onConfirm()}
              onClose={() =>
                setAmountModalObj((v) => {
                  v.isOpen = false
                  return v
                })
              }
              maxAmount={amountModalObj.maxAmount}
              decimals={18}
              onChange={(e: any) => {
                setModalValue(e.toString())
                amountModalRef.current = e.toString()
              }}
              open={amountModalObj.isOpen}
              symbol={amountModalObj.symbol}
            >
              &nbsp;
            </AmountModal>
          )}

          {sliderModalObj.isOpen && (
            <SliderModal
              title={sliderModalObj.title}
              confirmText={sliderModalObj.buttonText}
              onConfirm={() => sliderModalObj.onConfirm()}
              onClose={() =>
                setSliderModalObj((v) => {
                  v.isOpen = false
                  return v
                })
              }
              maxAmount={sliderModalObj.maxAmount}
              onChange={(e: any) => {
                setModalValue(e)
                setSliderModalObj((v) => {
                  v.symbol = `${e}%`
                  return v
                })
              }}
              open={sliderModalObj.isOpen}
              symbol={sliderModalObj.symbol}
            >
              <PercentageOfModalStat
                value={Number(formatUnits(stats.gvTokenBalanceOf.value, 18))}
                percent={parseInt(modalValue)}
                token={'$gvEase'}
              />
            </SliderModal>
          )}

          <LoadingModal
            open={loadingModalOpen}
            title={loadingModalTitle}
            onClose={() => {}}
          >
            {loadingModalBody}
          </LoadingModal>
        </div>
      </Transition>
    </div>
  )
}

import { Tab } from '@headlessui/react'
import { capitalize, classNames, formatInfo } from '../../classes/helpers'
import React from 'react'
import { VaultsApi } from '../../hooks/api/useGetVaults'
import { Tooltip } from 'react-tippy'

export const InfoTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  vault: VaultsApi.Vault
}> = ({ vault }) => {
  return (
    <Tab.Panel
      className={classNames(
        'text-white rounded-xl py-4 px-1 info-tab',
        'focus:outline-none focus:ring-transparent'
      )}
    >
      <div className={'grid grid-cols-4 items-center gap-y-2 gap-x-3'}>
        <div className={'self-start'}>
          <div className={'info-tab-protocol'}>
            <div className={'text-base font-bold'}>Protocol type</div>
            <div className={'text-sm'}>
              <a
                href={vault.type_link}
                target={'_blank'}
                rel={'nofollow noreferrer'}
                className={'text-white underline underline-offset-2'}
              >
                {vault.protocol_type}
              </a>
            </div>
          </div>
          <div className={'info-tab-underlying-protocols'}>
            <div className={'text-base font-bold mb-2 mt-6'}>
              Coverage Stack
            </div>
            {Object.keys(vault.protocols).map((p: any) => (
              <div key={p} className={'flex gap-x-2 mb-2'}>
                <Tooltip
                  title={capitalize(p)}
                  position="top"
                  trigger="mouseenter"
                >
                  <img src={vault.protocols[p]} className={'h-6 w-6'} alt={p} />
                </Tooltip>
                <div className={'hidden md:flex'}>{capitalize(p)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={'text-lg mb-2 col-span-3 self-start info-tab-instructions'}>
          <div className={'font-bold'}>Strategy</div>
          <div className={'text-base leading-tight'} dangerouslySetInnerHTML={{__html: formatInfo(vault.info, vault.info_link)}} />
          <div className={'font-bold mt-4'}>Additional Links</div>
          <div className={'token-links leading-tight text-base grid'}>
            <a href={vault.info_link} target="_blank" rel="noreferrer">- Get Underlying Token</a>
            <a href={`https://etherscan.io/address/${vault.token.address}`} target="_blank" rel="noreferrer">- Underlying token Address</a>
            <a href={`https://etherscan.io/address/${vault.address}`} target="_blank" rel="noreferrer">- Uninsurance Vault address</a>
          </div>
        </div>
      </div>
    </Tab.Panel>
  )
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'

// Define a type for the slice state
interface GlobalState {
  eth_price: number
  config: ConfigApi.Response
  isDevelopment: boolean
  impersonationAddress: string
}

// Define the initial state using that type
const initialState: GlobalState = {
  eth_price: 0,
  config: {} as ConfigApi.Response,
  isDevelopment: false,
  impersonationAddress: ''
}

export const fetchConfig = createAsyncThunk<ConfigApi.Response>(
  'config/get',
  async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/config`
    )
    return response.json()
  }
)

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEthPrice: (state, action: PayloadAction<number>) => {
      state.eth_price = action.payload
    },
    setImpersonationAddress: (state, action: PayloadAction<string>) => {
      state.impersonationAddress = action.payload
    },
    setIsDevelopment: (state, action: PayloadAction<boolean>) => {
      state.isDevelopment = action.payload
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(
      fetchConfig.fulfilled,
      (state, action: PayloadAction<ConfigApi.Response>) => {
        state.config = action.payload
      }
    )
  }
})

export const { setEthPrice, setIsDevelopment, setImpersonationAddress } =
  globalSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectEthPrice = (state: RootState) => state.global.eth_price
export const selectConfig = (state: RootState) => state.global.config
export const selectImpersonationAddress = (state: RootState) =>
  state.global.impersonationAddress
export const selectIsDevelopment = (state: RootState) =>
  state.global.isDevelopment

export default globalSlice.reducer

declare module ConfigApi {
  export interface Accounts {
    [key: string]: string
  }

  export interface SimulationFork {
    id: string
    alias: string
    network_id: string
    rpc: string
    api: string
    accounts: Accounts
  }

  export interface Response {
    simulation_fork: SimulationFork
  }
}

import { ContractInterface } from '../index'

const GvToken = (address: string): ContractInterface =>
  Object.freeze({
    address: address,
    abi: [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'address[]',
            name: 'vaults',
            type: 'address[]'
          },
          {
            indexed: false,
            internalType: 'uint256[]',
            name: 'percents',
            type: 'uint256[]'
          }
        ],
        name: 'AdjustStakes',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'previousAdmin',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'address',
            name: 'newAdmin',
            type: 'address'
          }
        ],
        name: 'AdminChanged',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'beacon',
            type: 'address'
          }
        ],
        name: 'BeaconUpgraded',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'delegator',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'fromDelegate',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'toDelegate',
            type: 'address'
          }
        ],
        name: 'DelegateChanged',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'delegate',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'previousBalance',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'newBalance',
            type: 'uint256'
          }
        ],
        name: 'DelegateVotesChanged',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'Deposited',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint8',
            name: 'version',
            type: 'uint8'
          }
        ],
        name: 'Initialized',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address'
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address'
          }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'RedeemFinalize',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'endTime',
            type: 'uint256'
          }
        ],
        name: 'RedeemRequest',
        type: 'event'
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'implementation',
            type: 'address'
          }
        ],
        name: 'Upgraded',
        type: 'event'
      },
      {
        inputs: [],
        name: 'DELEGATION_TYPEHASH',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'DOMAIN_TYPEHASH',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'MAX_GROW',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'MAX_PERCENT',
        outputs: [
          {
            internalType: 'uint64',
            name: '',
            type: 'uint64'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'WEEK',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address[]',
            name: 'vaults',
            type: 'address[]'
          },
          {
            internalType: 'uint256[]',
            name: 'percents',
            type: 'uint256[]'
          }
        ],
        name: 'adjustStakes',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address'
          }
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          },
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        name: 'checkpoints',
        outputs: [
          {
            internalType: 'uint32',
            name: 'fromBlock',
            type: 'uint32'
          },
          {
            internalType: 'uint128',
            name: 'votes',
            type: 'uint128'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'claimAndDepositReward',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'claimReward',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'decimals',
        outputs: [
          {
            internalType: 'uint8',
            name: '',
            type: 'uint8'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'delegatee',
            type: 'address'
          }
        ],
        name: 'delegate',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'delegatee',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'nonce',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'expiry',
            type: 'uint256'
          },
          {
            internalType: 'uint8',
            name: 'v',
            type: 'uint8'
          },
          {
            internalType: 'bytes32',
            name: 'r',
            type: 'bytes32'
          },
          {
            internalType: 'bytes32',
            name: 's',
            type: 'bytes32'
          }
        ],
        name: 'delegateBySig',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'delegator',
            type: 'address'
          }
        ],
        name: 'delegated',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'delegator',
            type: 'address'
          }
        ],
        name: 'delegates',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
              },
              {
                internalType: 'uint8',
                name: 'v',
                type: 'uint8'
              },
              {
                internalType: 'bytes32',
                name: 'r',
                type: 'bytes32'
              },
              {
                internalType: 'bytes32',
                name: 's',
                type: 'bytes32'
              }
            ],
            internalType: 'struct GvToken.PermitArgs',
            name: 'permit',
            type: 'tuple'
          }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'depositStart',
            type: 'uint256'
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
              },
              {
                internalType: 'uint8',
                name: 'v',
                type: 'uint8'
              },
              {
                internalType: 'bytes32',
                name: 'r',
                type: 'bytes32'
              },
              {
                internalType: 'bytes32',
                name: 's',
                type: 'bytes32'
              }
            ],
            internalType: 'struct GvToken.PermitArgs',
            name: 'permit',
            type: 'tuple'
          }
        ],
        name: 'deposit',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'depositToPot',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
              },
              {
                internalType: 'uint8',
                name: 'v',
                type: 'uint8'
              },
              {
                internalType: 'bytes32',
                name: 'r',
                type: 'bytes32'
              },
              {
                internalType: 'bytes32',
                name: 's',
                type: 'bytes32'
              }
            ],
            internalType: 'struct GvToken.PermitArgs',
            name: 'permit',
            type: 'tuple'
          }
        ],
        name: 'depositWithArmor',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'vArmorAmt',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'depositStart',
            type: 'uint256'
          },
          {
            internalType: 'bytes32[]',
            name: 'proof',
            type: 'bytes32[]'
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'deadline',
                type: 'uint256'
              },
              {
                internalType: 'uint8',
                name: 'v',
                type: 'uint8'
              },
              {
                internalType: 'bytes32',
                name: 'r',
                type: 'bytes32'
              },
              {
                internalType: 'bytes32',
                name: 's',
                type: 'bytes32'
              }
            ],
            internalType: 'struct GvToken.PermitArgs',
            name: 'permit',
            type: 'tuple'
          }
        ],
        name: 'depositWithVArmor',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'genesis',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          }
        ],
        name: 'getCurrentVotes',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'account',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: 'blockNumber',
            type: 'uint256'
          }
        ],
        name: 'getPriorVotes',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address'
          }
        ],
        name: 'getUserDeposits',
        outputs: [
          {
            components: [
              {
                internalType: 'uint128',
                name: 'amount',
                type: 'uint128'
              },
              {
                internalType: 'uint128',
                name: 'start',
                type: 'uint128'
              }
            ],
            internalType: 'struct GvToken.Deposit[]',
            name: '',
            type: 'tuple[]'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '_pot',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '_stakingToken',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '_rcaController',
            type: 'address'
          },
          {
            internalType: 'address',
            name: '_tokenSwap',
            type: 'address'
          },
          {
            internalType: 'uint256',
            name: '_genesis',
            type: 'uint256'
          }
        ],
        name: 'initialize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'leasedAmount',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'name',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'nonces',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'numCheckpoints',
        outputs: [
          {
            internalType: 'uint32',
            name: '',
            type: 'uint32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'pot',
        outputs: [
          {
            internalType: 'contract IBribePot',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'proxiableUUID',
        outputs: [
          {
            internalType: 'bytes32',
            name: '',
            type: 'bytes32'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'rcaController',
        outputs: [
          {
            internalType: 'contract IRcaController',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'time',
            type: 'uint256'
          }
        ],
        name: 'setDelay',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'bytes32',
            name: 'root',
            type: 'bytes32'
          }
        ],
        name: 'setPower',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'newTotalSupply',
            type: 'uint256'
          }
        ],
        name: 'setTotalSupply',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'stakingToken',
        outputs: [
          {
            internalType: 'contract IERC20Permit',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'tokenSwap',
        outputs: [
          {
            internalType: 'contract ITokenSwap',
            name: '',
            type: 'address'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'user',
            type: 'address'
          }
        ],
        name: 'totalDeposit',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalDeposited',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newOwner',
            type: 'address'
          }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newImplementation',
            type: 'address'
          }
        ],
        name: 'upgradeTo',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'newImplementation',
            type: 'address'
          },
          {
            internalType: 'bytes',
            name: 'data',
            type: 'bytes'
          }
        ],
        name: 'upgradeToAndCall',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
      },
      {
        inputs: [],
        name: 'withdrawFinalize',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'withdrawFromPot',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256'
          }
        ],
        name: 'withdrawRequest',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address'
          }
        ],
        name: 'withdrawRequests',
        outputs: [
          {
            internalType: 'uint128',
            name: 'amount',
            type: 'uint128'
          },
          {
            internalType: 'uint128',
            name: 'endTime',
            type: 'uint128'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      },
      {
        inputs: [],
        name: 'withdrawalDelay',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256'
          }
        ],
        stateMutability: 'view',
        type: 'function'
      }
    ]
  })

export default GvToken

import { classNames } from '../classes/helpers'
import React from 'react'

export const Button: React.FC<{
  children: React.PropsWithChildren<any>
  onClick: Function
  disabled?: boolean
  classes?: string
  size?: 'base' | 'sm' | 'xs' | 'lg'
  variant?: 'orange' | 'gray' | 'pink' | 'white' | 'blue' | 'blackop' | 'green'
  hover?: boolean
  block?: boolean
  buttonRef?: React.Ref<any>
  dataTestId?: string
}> = ({
  children,
  onClick,
  disabled = false,
  classes = '',
  size = 'base',
  variant = 'pink',
  hover = true,
  block = false,
  buttonRef,
  dataTestId = ''
}) => {
  let sizeClasses = ''
  let variantClasses = ''
  switch (size) {
    case 'xs':
      sizeClasses = 'px-1'
      break
    case 'sm':
      sizeClasses = 'px-2 py-1'
      break
    case 'lg':
      sizeClasses = 'px-4 py-3 leading-5 text-base text-lg'
      break
    case 'base':
      sizeClasses = 'px-3 py-2 leading-5'
      break
  }
  switch (variant) {
    case 'orange':
      variantClasses = `bg-orange-500 text-white ${
        hover ? 'hover:bg-pink-500' : ''
      }`
      break
    case 'green':
      variantClasses = `bg-blue-900 text-white ${hover ? 'hover:bg-black' : ''}`
      break
    case 'gray':
      variantClasses = `bg-gray-500 text-white ${
        hover ? 'hover:bg-gray-600' : ''
      }`
      break
    case 'blackop':
      variantClasses = `bg-blackop-40 text-white ${
        hover ? 'hover:bg-blackop-50' : ''
      }`
      break
    case 'pink':
      variantClasses = `bg-pink-500 text-white ${
        hover ? 'hover:bg-orange-500' : ''
      }`
      break
    case 'white':
      variantClasses = `border border-gray-300 bg-white text-gray-700 ${
        hover ? 'hover:bg-gray-50' : ''
      }`
      break
    case 'blue':
      variantClasses = `bg-blue-900 text-white ${hover ? 'hover:bg-black' : ''}`
      break
  }

  return (
    <button
      type="button"
      disabled={disabled}
      data-testid={dataTestId}
      ref={buttonRef}
      className={classNames(
        classes,
        disabled ? 'bg-gray-400 text-gray-800 cursor-default' : variantClasses,
        'inline-flex font-bold uppercase items-center justify-center border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
        sizeClasses,
        block ? 'w-full block' : ''
      )}
      onClick={(e) => onClick(e)}
    >
      {children}
    </button>
  )
}

import { Link } from 'react-router-dom'
import React from 'react'
import clsx from 'clsx'

export const NavLink: React.FC<{
  children: React.PropsWithChildren<any>
  active?: boolean
  url: string
}> = ({ children, active = false, url }) => {
  return (
    <Link
      to={url}
      className={clsx(
        ' block relative rounded-xl text-blue-900 p-1 pl-4 mb-1 text-lg transition duration-300 ease-in-out hover:bg-blackop-5 hover:font-bold  ',
        active ? ' font-bold bg-blackop-10 hover:bg-blackop-10 ' : ''
      )}
    >
      {children}
    </Link>
  )
}

import { Step } from 'react-joyride'

export const secondSteps: Array<Step> = [
  {
    target: '.bribes',
    placement: 'top',
    content: 'This is the overview of existing bribes.It shows the amount of $Ease tokens you pay per week, the Power you get in return, the Max fee as a result of the bribe and the start and end dates.',
    disableBeacon: true
  }
]

import { PageTitle } from './PageTitle'
import { ConnectToWalletDiv } from './ConnectWalletDiv'
import React, { ReactNode } from 'react'

export const ConnectWalletPage: React.FC<{
  children?: React.PropsWithChildren<any>
  title: ReactNode
  text?: string
}> = ({ children, title, text = 'Please connect your wallet' }) => {
  return (
    <div>
      <PageTitle className={'mt-8'}>
        <div className={'flex gap-x-2 justify-center items-center'}>
          {title}
        </div>
      </PageTitle>

      <div className={'pt-8 md:pt-8 w-full container-lg mx-auto ease-app'}>
        <ConnectToWalletDiv text={text} />
      </div>
    </div>
  )
}

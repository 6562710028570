import React from 'react'
import { Step } from 'react-joyride'

export const firstSteps = (): Array<Step> => {
  let _steps: Array<Step> = []

  _steps.push({
    target: '.first-step',
    placement: 'top',
    content: 'The gvEase app consists of 3 sections: the first one is your dashboard, which has 4 columns.',
    disableBeacon: true
  })

  _steps.push({
    target: '.gvd-0',
    placement: 'top',
    content: 'The first column shows the amount of unstaked $Ease tokens you currently have in your wallet.',
    disableBeacon: true
  })

  _steps.push({
    target: '.gvd-1',
    placement: 'top',
    content: (
      <div>
        <div>
          The second column shows the amount of $Ease you have deposited to get $gvEase.
        </div>
        <div>
          This is also the amount of $Ease you can withdraw. If you click the [WITHDRAW] button a transaction will start to initiate the withdrawal.
        </div>
        <br />
        <div>
          Once the transaction confirms, the button will change and show [PENDING] as the withdrawal delay is one week.
        </div>
        <div>
          After a week the button will change to [FINALIZE]. Then you can finalize the $Ease withdrawal and they will be in your wallet.
        </div>
      </div>
    ),
    disableBeacon: true
  })

  _steps.push({
    target: '.gvd-2',
    placement: 'top',
    content: 'This is the total $gvEase you own and can be used for staking, leasing and voting. This amount grows as long as you don’t withdraw it back to $Ease.',
    disableBeacon: true
  })

  _steps.push({
    target: '.gvd-3',
    placement: 'top',
    content: (
      <div>
        <div>
          This section shows the amount of $Ease you have earned.
        </div>
        <div>
          You can click [COMPOUND] to deposit all $Ease rewards into $gvEase, or you can click [CLAIM] to withdraw the $Ease rewards into your wallet.
        </div>
      </div>
    ),
    disableBeacon: true
  })

  return _steps
}

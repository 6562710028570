import { Step } from 'react-joyride'
import React, { useMemo, useState } from 'react'
import { firstSteps } from './firstSteps'
import { secondSteps } from './secondSteps'
import { thirdSteps } from './thirdSteps'
import { fourthSteps } from './fourthSteps'

export const useJoyrideSteps = (account: string | null | undefined, stakedLength: number) => {
  const [overrideSteps, setOverrideSteps] = useState<Array<Step> | null>([])

  const steps = useMemo(() => {
    if (overrideSteps && overrideSteps.length > 0) {
      return overrideSteps
    }

    // initialize the first step (start the tour)
    let _steps: Array<Step> = [
      {
        target: '.gv-ease-app',
        content: (
          <div className={'text-center p-4'}>
            <div>
              You can read instructions for this page{' '}
              <a
                href={
                  'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/how-to-use-the-gvease-app'
                }
                className={'text-pink-500 underline font-bold'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                here.
              </a>
            </div>
            <div className={'mt-2'}>Would you like to get a guided tour?</div>
            <div className={'text-gray-600 mt-2 text-sm'}>
              You can stop the tour at any moment by clicking outside the tour
              overlay area.
            </div>
          </div>
        ),
        placement: 'center',
        disableBeacon: true,
        title: (
          <div className={'text-center text-pink-500 text-lg font-bold'}>
            This is the gvEase app.
          </div>
        )
      }
    ]

    // add remaining steps
    _steps.push(...firstSteps())
    _steps.push(...secondSteps())
    stakedLength &&_steps.push(...thirdSteps)
    _steps.push(...fourthSteps)

    return _steps
  }, [overrideSteps])

  return { steps, setOverrideSteps }
}

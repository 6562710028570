import React, { useState, useEffect } from 'react'
import { ArrowIcon } from '../components/icons/ArrowIcon'
import { ConnectWalletPage } from '../components/ConnectWalletPage'
import { HistoryTable } from '../components/txHistory/historyTable'
import { PageTitle } from '../components/PageTitle'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import getTxs, { TxApi } from '../hooks/api/useGetTxs'

export const TxHistory: React.FC = () => {
  const { account } = useImpersonatableWeb3React()
  
  const [sortByType, setSortByType] = useState('APY')
  const [sortDirection, setSortDirection] = useState('desc')
  const [txEvents, setTxEvents] = useState<TxApi.Event[]>([])

  useEffect(() => {
    if (account) {
      getTxs(account).then((res) => {
        setTxEvents(res.events)
      })
    }
  }, [account])

  const setSortBy = (column: string = 'Name') => {
    if (column == sortByType) {
      if (sortDirection == 'asc') {
        setSortDirection('desc')
        return
      }

      setSortDirection('asc')
      return
    }
    setSortDirection('desc')
    setSortByType(column)
  }

  return (
    <div className={'w-full container-sm mx-auto'}>
      <PageTitle>
        <div className={'mt-4 flex gap-x-2 justify-center items-center'}>
          Transaction History
        </div>
      </PageTitle>
      {!account ? (
        <ConnectWalletPage
          title={<></>}
          text={'Please connect wallet to see transaction history'}
        />
      ) : (
        <>
          <div
            className={
              'grid md:grid-cols-9 mt-4 font-poppins text-sm'
            }
          >
            <div
              className={
                'text-white flex gap-x-1 items-center cursor-pointer ml-2 md:ml-none'
              }
              onClick={() => setSortBy('id')}
            >
              #{' '}
              <ArrowIcon
                selected={sortByType == 'id'}
                sortDirection={sortDirection}
              />
            </div>
            <div
              className={
                'text-white flex md:col-span-2 md:flex items-center cursor-pointer'
              }
              onClick={() => setSortBy('Vault')}
            >
              Vault{' '}
              <ArrowIcon
                selected={sortByType == 'Vault'}
                sortDirection={sortDirection}
              />
            </div>
            <div
              className={'text-white hidden md:col-span-2 md:flex gap-x-1 items-center cursor-pointer'}
              onClick={() => setSortBy('Date')}
            >
              Date{' '}
              <ArrowIcon
                selected={sortByType == 'Date'}
                sortDirection={sortDirection}
              />
            </div>
            <div
              className={
                'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
              }
              onClick={() => setSortBy('Action')}
            >
              Action{' '}
              <ArrowIcon
                selected={sortByType == 'Action'}
                sortDirection={sortDirection}
              />
            </div>
            <div
              className={
                'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
              }
              onClick={() => setSortBy('Amount')}
            >
              Amount{' '}
              <ArrowIcon
                selected={sortByType == 'Amount'}
                sortDirection={sortDirection}
              />
            </div>
            <div
              className={
                'text-white hidden md:col-span-2 md:flex gap-x-1 items-center cursor-pointer'
              }
              onClick={() => setSortBy('TxID')}
            >
              TxID{' '}
              <ArrowIcon
                selected={sortByType == 'TxID'}
                sortDirection={sortDirection}
              />
            </div>
          </div>
          <div className={'mt-4 rounded-2xl bg-blackop-50 text-white pb-4 py-4 mx-2'}>
            {txEvents && txEvents?.map((item, index) => (
              <HistoryTable
                index={index}
                transaction={item}
                key={`tx-${index}`}
              />
            ))}
          </div>
          <div className={'h-24'}></div>
        </>
      )}
    </div>
  )
}
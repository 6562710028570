import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import BN from 'bn.js'
import { BigNumber } from 'ethers'

// Define a type for the slice state
export interface GvState {
  easeTokenBalance: string
}

// Define the initial state using that type
const initialState: GvState = {
  easeTokenBalance: '0'
}

export const gvSlice = createSlice({
  name: 'gv',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEaseTokenBalance: (state, action: PayloadAction<string>) => {
      state.easeTokenBalance = action.payload
    }
  }
})

export const { setEaseTokenBalance } = gvSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectEaseTokenBalance = (state: RootState) =>
  state.gv.easeTokenBalance

export default gvSlice.reducer

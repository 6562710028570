import { UpArrowIcon } from './UpArrowIcon'
import { DownArrowIcon } from './DownArrowIcon'
import { UpDownArrowsIcon } from './UpDownArrowsIcon'
import React from 'react'

export const ArrowIcon: React.FC<{
  selected: boolean
  sortDirection: string
}> = ({ selected, sortDirection = 'desc' }) => {
  if (selected) {
    if (sortDirection == 'asc') {
      return <UpArrowIcon />
    } else {
      return <DownArrowIcon />
    }
  }

  return <UpDownArrowsIcon />
}

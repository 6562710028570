import {
  authereum,
  injected,
  ledger,
  torus,
  trezor,
  walletconnect,
  walletlink
} from './connectors'

export const connectorsByName = [
  {
    name: 'MetaMask',
    injectedConnector: injected,
    icon: 'metamask.svg'
  },
  {
    name: 'TrustWallet',
    injectedConnector: injected,
    icon: 'trustWallet.png'
  },
  {
    name: 'WalletConnect',
    injectedConnector: walletconnect,
    icon: 'walletConnect.svg'
  },
  // {
  //   name: 'GnosisSafeApps',
  //   injectedConnector: gnosisSafe,
  //   icon: 'gnosisSafeApps.png',
  // },
  {
    name: 'WalletLink',
    injectedConnector: walletlink,
    display: 'Coinbase Wallet',
    icon: 'walletLink.svg'
  },
  {
    name: 'Ledger',
    injectedConnector: ledger,
    icon: 'ledger.svg'
  },
  {
    name: 'Trezor',
    injectedConnector: trezor,
    icon: 'trezor.png'
  },
  {
    name: 'Torus',
    injectedConnector: torus,
    icon: 'torus.jpg'
  },
  {
    name: 'Authereum',
    injectedConnector: authereum,
    icon: 'authereum.svg'
  }
]

import { Step } from 'react-joyride'
import React, { useMemo, useState } from 'react'
import { firstSteps } from './firstSteps'
import { secondSteps } from './secondSteps'

export const useJoyrideSteps = (account: string | null | undefined) => {
  const [overrideSteps, setOverrideSteps] = useState<Array<Step> | null>([])

  const steps = useMemo(() => {
    if (overrideSteps && overrideSteps.length > 0) {
      return overrideSteps
    }

    // initialize the first step (start the tour)
    let _steps: Array<Step> = [
      {
        target: '.gv-bribe-app',
        content: (
          <div className={'text-center p-4'}>
            <div>
              <div>
                You can bribe to influence the maximum fee of a vault.
              </div>
              <div>
                You offer a bribe in $Ease to direct leased $gvEase to the vault of your choice, lowering its maximum fee.
              </div>
              <br />
              Read more about {' '}
              <a
                href={
                  'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/what-are-bribes-and-leases/'
                }
                className={'text-pink-500 underline font-bold'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                bribing here.
              </a>
              <br />
              You can read instructions for this page {' '}
              <a
                href={
                  'https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/how-to-use-the-gvease-app'
                }
                className={'text-pink-500 underline font-bold'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                here.
              </a>
            </div>
            <div className={'mt-2'}>Would you like to get a guided tour?</div>
            <br />
            <div className={'text-gray-600 mt-2 text-sm'}>
              You can stop the tour at any moment by clicking outside the tour overlay area.
            </div>
          </div>
        ),
        placement: 'center',
        disableBeacon: true,
        title: (
          <div className={'text-center text-pink-500 text-lg font-bold'}>
            This is the Bribing section.
          </div>
        )
      }
    ]

    // add remaining steps
    _steps.push(...firstSteps)
    _steps.push(...secondSteps)

    return _steps
  }, [overrideSteps])

  return { steps, setOverrideSteps }
}

import { ChevronDownIcon } from '@heroicons/react/solid'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { Disclosure, Tab, Transition } from '@headlessui/react'
import {
  classNames, flexibleDecimals,
  formatDecimals,
  formatShort, makeDecimalUnits, unifiedFormat
} from '../../../classes/helpers'
import React, { useEffect, useMemo } from 'react'
import { DepositTabPanel } from '../DepositTabPanel'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { useWeb3React } from '@web3-react/core'
import { WithdrawTabPanel } from '../WithdrawTabPanel'
import { FarmingTabPanel } from '../FarmingTabPanel'
import { InfoTabPanel } from '../InfoTabPanel'
import { InstructionTabPanel } from '../InstructionTabPanel'
import { ConnectToWalletTabPanel } from '../ConnectToWalletTabPanel'
import { formatUnits } from 'ethers/lib/utils'
import moment from 'moment'
import { Tooltip } from 'react-tippy'

export const ActiveDepositRow: React.FC<{
  children?: React.PropsWithChildren<any>
  vault: VaultsApi.Vault
  onUpdate: Function
  withdrawalDelay: string
  index: number
  total: number
}> = ({ children, vault, withdrawalDelay, onUpdate, index, total }) => {
  const tabs = ['Deposit', 'Withdraw', 'Farming', 'Info', 'Instructions']
  const { account } = useWeb3React()

  const memoizedBalance = useMemo(() => {
    return formatUnits(vault.balance ?? '0', vault.decimals ?? '18')
  }, [vault.balance, vault.decimals])

  const memoizedDepositedEzTokenBalance = useMemo(
    () => formatUnits(vault.deposited_ez_balance , vault.decimals),
    [vault]
  )

  const memoizedAssets = useMemo(
    () => Number(formatUnits(vault.token?.assets?.toString() ?? '0', 18)),
    [vault.token?.assets]
  )

  const balanceDollarValue = () => {
    if(!vault.token?.priceUSD) return 0
    let valueInDollars =
      Number(vault.token?.priceUSD) * Number(memoizedBalance) +
      Number(vault.token?.priceUSD) * Number(memoizedDepositedEzTokenBalance)
    return valueInDollars < 0.001 ? 0: unifiedFormat(valueInDollars)
  }

  const totalAssetsDollarValue = () =>
    vault.token?.priceUSD
      ? formatShort(Number(vault.token?.priceUSD) * Number(memoizedAssets))
      : 0

  const isAvailableToWithdraw = () =>
    Number(vault.request?.endTime) > 0 &&
    moment(Number(vault.request?.endTime?.toString()) * 1000).isBefore(moment())

  return (
    <React.Fragment>
      <div data-testid={'active-deposit-row-disclosure'}>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  'w-full grid grid-cols-5 md:grid-cols-7 text-white font-medium pb-4 py-4 hover:bg-blackop-15',
                  index == 0 ? 'rounded-t-2xl' : '',
                  index == total - 1 ? 'rounded-b-2xl' : ''
                )}
              >
                <div
                  className={
                    'flex gap-x-2 col-span-3 md:col-span-3 items-center ml-4'
                  }
                >
                  <img src={vault.icon} className={'h-6 w-6 rounded-full'} />
                  {vault.display_name}
                  {isAvailableToWithdraw() && (
                    <Tooltip
                      // options
                      title={
                        'You have a pending withdrawal that can now be finalized'
                      }
                      position="top"
                      trigger="mouseenter"
                    >
                      <ExclamationCircleIcon
                        className={'h-5 w-5 text-white mt-1'}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className={'text-left flex justify-between'}>
                  <span>{vault.token?.apy ?? '0'}%</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-6 h-6 text-white mr-2 md:hidden`}
                  />
                </div>
                <div className={'text-left hidden md:flex'}>
                  {formatDecimals(vault.maxFee, 2, 0)}%
                </div>
                <div className={'text-left hidden md:flex'}>
                  {unifiedFormat(
                    Number(memoizedBalance) + Number(memoizedDepositedEzTokenBalance)
                  )}
                </div>
                <div
                  className={'text-left hidden md:flex justify-between mr-4'}
                >
                  <span>${balanceDollarValue()}</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-6 h-6 text-white`}
                  />
                </div>
              </Disclosure.Button>
              <Transition
                enter="transition duration-[300ms] ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-[50ms] ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="w-full px-4  sm:px-4 bg-blackop-10 rounded-2xl rounded-t-none">
                  <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                      {tabs.map((title) => (
                        <Tab
                          key={title}
                          className={({ selected }) =>
                            classNames(
                              'uppercase w-full py-2.5 text-sm leading-5 font-medium font-bold',
                              'focus:outline-none focus:ring-transparent flex justify-center items-center gap-x-2',
                              selected
                                ? 'border-b-2 border-orange-500 text-white'
                                : 'hover:bg-blackop-5 text-gray-400'
                            )
                          }
                        >
                          {title}{' '}
                          {isAvailableToWithdraw() && title == 'Withdraw' && (
                            <Tooltip
                              // options
                              title={
                                'You have a pending withdrawal that can now be finalized'
                              }
                              position="top"
                              trigger="mouseenter"
                            >
                              <ExclamationCircleIcon
                                className={'h-5 w-5 text-white mt-1'}
                              />
                            </Tooltip>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels>
                      {account && (
                        <>
                          <Tab.Panel
                            className={classNames(
                              'text-white rounded-xl py-4 px-1 panel-sm mx-auto focus:outline-none focus:ring-transparent relative'
                            )}
                          >
                            <DepositTabPanel
                              vault={vault}
                              withdrawalDelay={withdrawalDelay}
                              onUpdate={onUpdate}
                            />
                          </Tab.Panel>
                          <WithdrawTabPanel
                            vault={vault}
                            withdrawalDelay={withdrawalDelay}
                            onUpdate={onUpdate}
                          />
                          <FarmingTabPanel
                            vault={vault}
                            onUpdate={onUpdate}
                          />
                          <InfoTabPanel vault={vault} />
                          <InstructionTabPanel vault={vault} />
                        </>
                      )}
                      {!account && (
                        <>
                          <ConnectToWalletTabPanel />
                          <ConnectToWalletTabPanel />
                          <FarmingTabPanel
                            vault={vault}
                            onUpdate={onUpdate}
                          />
                          <InfoTabPanel vault={vault} />
                          <InstructionTabPanel vault={vault} />
                        </>
                      )}
                    </Tab.Panels>
                  </Tab.Group>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </React.Fragment>
  )
}

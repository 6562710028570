import { useWeb3React } from '@web3-react/core'
import { useEffect } from 'react'
import { injected } from '../config/connectors'

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React()

  useEffect((): any => {
    const { ethereum } = window as any
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected).catch((err) => console.error(err))
      }
      const handleChainChanged = (chainId: string | number) => {
        activate(injected).catch((err) => console.error(err))
      }
      const handleAccountsChanged = (accounts: string[]) => {
        activate(injected).catch((err) => console.error(err))
      }
      const handleNetworkChanged = (networkId: string | number) => {
        activate(injected).catch((err) => console.error(err))
      }

      ethereum.on('connect', handleConnect)
      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)
      ethereum.on('networkChanged', handleNetworkChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect)
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
          ethereum.removeListener('networkChanged', handleNetworkChanged)
        }
      }
    }
  }, [active, error, suppress, activate])
}

import { Step } from 'react-joyride'
import React from 'react'

export const depositTabSteps = (
  account: string | null | undefined
): Array<Step> => {
  let _steps: Array<Step> = []

  _steps.push({
    target: '.deposit-tab',
    placement: 'top',
    content: (
      <div>
        <div>
          This opens the deposit screen where you can deposit your tokens in
          exchange for ez-tokens.
        </div>
        {!account && (
          <div className={'mt-4'}>
            Connect to your wallet and come back to this guide to view a more
            in-depth explanation of this screen.
          </div>
        )}
      </div>
    ),
    disableBeacon: true
  })
  if (account) {
    _steps.push({
      target: '.deposit-tab-token',
      placement: 'top',
      content:
        'The left shows the tokens that can be deposited in the current vault.',
      disableBeacon: true
    })
    _steps.push({
      target: '.deposit-tab-balance',
      placement: 'top',
      content: 'The top right shows the tokens you have in your wallet.',
      disableBeacon: true
    })
    _steps.push({
      target: '.deposit-tab-ez-token',
      placement: 'top',
      content:
        'The second part of the Deposit tab shows the balance of ez-Tokens you currently have in your wallet and the amount that you will receive when depositing the amount above.',
      disableBeacon: true
    })
    _steps.push({
      target: '.deposit-tab-remaining-capacity',
      placement: 'top',
      content:
        'The capacity remaining is the amount that can be deposited in the vault.',
      disableBeacon: true
    })
    _steps.push({
      target: '.deposit-tab-call-to-action',
      placement: 'top',
      content:
        'You can click [Deposit] to deposit the amount entered or click [Deposit Max] to do a one-click deposit of all your tokens for this vault.',
      disableBeacon: true
    })
  }

  return _steps
}

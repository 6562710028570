import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './globalSlice'
import vaultsReducer from './vaultSlice'
import joyrideReducer from './joyrideSlice'
import gvReducer from './gvSlice'

export const store = configureStore({
  reducer: {
    global: globalReducer,
    vaults: vaultsReducer,
    joyride: joyrideReducer,
    gv: gvReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

import React, { useEffect, useMemo } from 'react'
import { WrongChainAlert } from '../components/WrongChainAlert'
import { PageTitle } from '../components/PageTitle'
import { useWeb3React } from '@web3-react/core'
import { testCases } from '../__tests__/cases'
import { Button } from '../components/Button'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { InformationAlert } from '../components/InformationAlert'
import { useImmer } from 'use-immer'

const hash = require('object-hash')

export const TestCases: React.FC<{}> = () => {
  const { account, chainId, connector } = useWeb3React()
  const [localTestCaseValues, setLocalTestCaseValues] = useLocalStorage(
    'test_cases_values',
    [] as number[][]
  )
  const [testCaseValues, setTestCaseValues] =
    useImmer<number[][]>(localTestCaseValues)
  const isOnWrongChain = useMemo(() => chainId && chainId != 1, [chainId])
  const casesHash = () => {
    return hash.sha1(testCases)
  }
  const [localHash, setLocalHash] = useLocalStorage('test_cases_hash', '')

  useEffect(() => {
    if (localHash == '') {
      setLocalHash(casesHash())
    }

    if (localTestCaseValues.length == 0) {
      let builtTestCaseValues = buildTestCaseValues()
      setTestCaseValues(builtTestCaseValues)
      setLocalTestCaseValues(builtTestCaseValues)
    }
  }, [testCases])

  useEffect(() => {
    setLocalTestCaseValues(testCaseValues)
  }, [testCaseValues])

  const isChecked = (groupIndex: number, testIndex: number) => {
    if (testCaseValues[groupIndex]) {
      return testCaseValues[groupIndex][testIndex] == 1
    }

    return false
  }

  const resetTestCases = () => {
    setLocalHash(casesHash)
    setTestCaseValues(buildTestCaseValues())
  }

  const buildTestCaseValues = () => {
    if (!testCases) return [] as number[][]

    let cases = new Array(testCases.length)
    for (let i = 0; i < testCases.length; i++) {
      cases[i] = []
      for (let j = 0; j < testCases[i].tests.length; j++) {
        cases[i][j] = 0
      }
    }
    return cases
  }

  const updateTestCase = (groupIndex: number, testIndex: number, e: string) => {
    setTestCaseValues((draft) => {
      if (draft[groupIndex]) {
        draft[groupIndex][testIndex] = draft[groupIndex][testIndex] == 0 ? 1 : 0
      } else {
        let group = []
        group[testIndex] = 1
        draft[groupIndex] = group
      }
    })
  }

  return (
    <div>
      <div className={'pt-4 md:pt-4 w-full container-lg mx-auto'}>
        {isOnWrongChain && (
          <WrongChainAlert
            chainId={chainId ? chainId : 0}
            className={'mb-4 mt-8'}
          />
        )}
        {localHash != casesHash() && (
          <InformationAlert>
            <div>
              The test cases have changed, please reset your test cases to
              update the local hash
            </div>
          </InformationAlert>
        )}
        <PageTitle>
          <div className={'flex justify-between'}>
            <div className={'flex gap-x-2 justify-center items-center'}>
              Test Cases
            </div>

            <div>
              <Button
                onClick={() => resetTestCases()}
                variant={'blue'}
                size={'sm'}
              >
                Reset Test Cases
              </Button>
            </div>
          </div>
        </PageTitle>
        <div className={'pt-4 md:pt-4 mx-auto'}>
          {testCaseValues &&
            testCases.map((t, groupIndex) => (
              <div
                className={
                  'bg-blackop-50 text-white rounded-xl py-4 px-4 text-lg mb-4'
                }
                key={t.group}
              >
                <div className={'text-xl capitalize font-sans underline mb-1'}>
                  {t.group}
                </div>
                {t.tests.map((test, testIndex) => (
                  <div
                    key={`${t.group}${testIndex}`}
                    className={'flex items-center gap-x-2 capitalize py-1'}
                  >
                    <div className="relative flex items-center">
                      <div className="flex items-center h-5">
                        <input
                          id={`${t.group}${testIndex}`}
                          name={`${t.group}${testIndex}`}
                          type="checkbox"
                          checked={isChecked(groupIndex, testIndex)}
                          onChange={(e) =>
                            updateTestCase(
                              groupIndex,
                              testIndex,
                              e.target.value
                            )
                          }
                          className="focus:ring-blue-900 h-4 w-4 text-blue-900 rounded"
                        />
                      </div>
                      <div className="ml-3 text-base">
                        <label
                          htmlFor={`${t.group}${testIndex}`}
                          className="font-medium text-white"
                        >
                          {test}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

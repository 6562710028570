import { Step } from 'react-joyride'
import React, { useMemo } from 'react'

export const instructionsTabSteps: Array<Step> = [
  {
    target: '.instructions-tab',
    placement: 'top',
    content: 'Instructions on how to obtain the underlying tokens that you can deposit into this vault.\n' +
      'Please read the instructions carefully as each protocol has its own instructions.',
    disableBeacon: true
  },

]

import { Step } from 'react-joyride'

export const opportunitiesSteps: Array<Step> = [
  {
    target: '.opportunities-table',
    placement: 'top',
    content: 'This is the complete list of all current Uninsurance vaults.',
    disableBeacon: true
  },
  {
    target: '.opportunities-top-vaults',
    placement: 'top',
    content: 'This is a list of every protocol’s vault that has the highest APY.',
    disableBeacon: true
  },
  {
    target: '.opportunities-filters',
    placement: 'top',
    content:
      'You can filter the vaults using the Protocol dropdown list or you can search by tokens or protocols using the search box.',
    disableBeacon: true
  },
  {
    target: '.opportunities-sorting',
    placement: 'top',
    offset: 50,
    disableScrolling: true,
    disableScrollParentFix: true,
    content:
      'You can also sort the vault list alphabetically, by APY, the total deposits per vault, or the value of the tokens you currently have in your wallet that can be deposited.',
    disableBeacon: true
  }
]

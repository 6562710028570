import { classNames } from '../../../classes/helpers'
import { OpportunityRow } from './OpportunityRow'
import React, { useEffect, useState } from 'react'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { useAppSelector } from '../../../hooks/redux'
import { selectOpenDepositPanel } from '../../../store/joyrideSlice'

export const TopVaults: React.FC<{
  vaults: VaultsApi.Vault[]
  total: number
  onUpdate: Function
  withdrawDelay: string
}> = ({ vaults, total, onUpdate, withdrawDelay }) => {
  const [topVaultSelected, setTopVaultSelected] =
    useState<VaultsApi.Vault | null>()
  const [panelOpenedByJoyride, setPanelOpenedByJoyride] = useState(false)
  const openDepositPanel = useAppSelector(selectOpenDepositPanel)

  useEffect(() => {
    if (
      vaults &&
      vaults.length > 0 &&
      topVaultSelected == null &&
      openDepositPanel &&
      !panelOpenedByJoyride
    ) {
      setPanelOpenedByJoyride(true)
      setTopVaultSelected(vaults[0])
    }

    if (!openDepositPanel && topVaultSelected != null && panelOpenedByJoyride) {
      setPanelOpenedByJoyride(false)
      setTopVaultSelected(null)
    }
  }, [openDepositPanel, vaults])

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-x-2 text-white font-bold text-center px-2 text-lg gap-y-2">
        {vaults.length > 0 &&
          vaults.map((vault, index) => (
            <div
              key={`${index}-${vault.name}`}
              className={classNames(
                'rounded-md flex flex-col items-center w-1/2 sm:w-1/4 p-1 shadow-lg cursor-pointer gradient mx-auto',
                topVaultSelected === vault
                  ? 'border-orange-500'
                  : 'border-blue-900'
              )}
              onClick={() =>
                setTopVaultSelected((prev) => {
                  return prev != vault ? vault : null
                })
              }
            >
              <img
                src={vault.icon}
                className={'h-10 w-10 rounded-full mt-2 shadow-lg'}
              />
              <div className="mt-2">{vault.display_name}</div>
              <div>{vault.token?.apy ?? '0'}% APY</div>
            </div>
          ))}
      </div>
      <div className={'tab-panels'}>
        {topVaultSelected && (
          <div className="bg-blackop-50 rounded-2xl mt-4">
            <OpportunityRow
              index={0}
              total={total}
              key={`${topVaultSelected.address}${topVaultSelected.symbol}`}
              vault={topVaultSelected}
              onUpdate={onUpdate}
              withdrawalDelay={withdrawDelay}
              isOpen={true}
            />
          </div>
        )}
      </div>
    </>
  )
}

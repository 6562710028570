import React from 'react'
import moment from 'moment'
import { TxApi } from '../../hooks/api/useGetTxs'
import { classNames, shrinkSplit, formatShort } from '../../classes/helpers'

export const HistoryTable: React.FC<{
  children?: React.PropsWithChildren<any>
  index: number
  transaction: TxApi.Event
}> = ({ children, index, transaction }) => {
  return (
    <div className={'md:grid-cols-9'}>
      <div
        className={
          'grid md:grid-cols-9 mt-4 font-poppins text-sm'
        }
      >
        <div
          className={
            'text-white flex gap-x-1 items-center ml-2 md:ml-none'
          }
        >
          {index+1}
        </div>
        <div
          className={
            'text-white flex md:col-span-2 md:flex items-center'
          }
        >
          {shrinkSplit(transaction.vault, 4)}
        </div>
        <div
          className={'text-white hidden md:col-span-2 md:flex gap-x-1 items-center'}
        >
          {moment(transaction.eventTime).format('MMM Do YY, hh:mm:ss')}
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
        >
          {transaction.eventName}
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
        >
          {transaction.uAmount ? formatShort(parseFloat(transaction.uAmount)) : ''}
        </div>
        <div
          className={
            'text-white hidden md:col-span-2 md:flex gap-x-1 items-center'
          }
        >
          {shrinkSplit(transaction.transactionHash, 8)}
        </div>
      </div>
    </div>
  )
}
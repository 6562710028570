import DiscordIcon from './icons/DiscordIcon'
import TwitterIcon from './icons/TwitterIcon'
import RedditIcon from './icons/RedditIcon'
import GithubIcon from './icons/GithubIcon'
import LinkedInIcon from './icons/LinkedInIcon'
import React, { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'

import { Disclosure } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { MenuIcon } from '@heroicons/react/solid'
import { shrinkSplit } from '../classes/helpers'
import { WalletConnect } from './WalletConnect'
import { NavLink } from './NavLink'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../hooks/redux'
import {
  selectImpersonationAddress,
  selectIsDevelopment
} from '../store/globalSlice'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

export const Header: React.FC<{
  children?: React.PropsWithChildren<any>
}> = ({}) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openWallet, setOpenWallet] = useState(false)
  const { account } = useImpersonatableWeb3React()
  const impersonationAddress = useAppSelector(selectImpersonationAddress)
  const isDevelopment = useAppSelector(selectIsDevelopment)
  const location = useLocation()

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      current: location.pathname == '/'
    },
    {
      name: 'gvEase',
      href: '/gv-dashboard',
      current: location.pathname == '/gv-dashboard'
    },
    {
      name: 'Bribing',
      href: '/gv-bribing',
      current: location.pathname == '/gv-bribing'
    },
    {
      name: 'Token Swap',
      href: '/token-swap',
      current: location.pathname == '/token-swap'
    },
    //{
    //  name: 'Tx History',
    //  href: '/tx-history',
    //  current: location.pathname == '/tx-history'
    //},
  ]

  const sideLinks = [
    {
      name: 'About',
      href: 'https://ease.org/about-ease-defi/',
      current: false
    },
    { name: 'Learn', href: 'https://ease.org/learn/', current: false },
    {
      name: 'Get Involved',
      href: 'https://ease.org/get-involved/',
      current: false
    }
  ]

  return (
    <div className={'fixed lg:relative w-full z-10'}>
      <div className={'w-56 fixed z-30'}>
        <div className={'hidden bg-white min-h-screen flex-col lg:flex justify-between md:py-4 md:px-4 md:pl-4'}>
          <div className={'hidden flex-col lg:flex '}>
            <Link to={'/'} className={'content-center'}>
              <img src={'/logo.png'} alt={'ease.org'} className={'h-12 m-auto pr-4'} />
            </Link>
            <div className={' pt-4 flex flex-col'}>
              {navigation
                .filter(
                  (x) =>
                    isDevelopment ||
                    (!isDevelopment && x.name != 'Test Cases')
                )
                .map((item) => (
                  <NavLink
                    url={item.href}
                    active={item.current}
                    key={item.href}
                  >
                    {item.name}
                  </NavLink>
                ))}
            </div>
          </div>
          <div className={'hidden lg:flex flex-col gap-x-10 '}>
            <div className={'flex flex-col gap-x-4 text-base font-poppins '}>
              <a className={'mb-1'} href={'https://ease.org/about-ease-defi/'} target={'_blank'}  rel={'nofollow noreferrer'} >About</a>
              <a className={'mb-1'} href={'https://ease.org/learn/'} target={'_blank'}  rel={'nofollow noreferrer'}>Learn</a>
              <a className={'mb-1'} href={'https://ease.org/get-involved/'} target={'_blank'}  rel={'nofollow noreferrer'}>Get Involved</a>
            </div>
            <div className={'mt-4 hidden xl:flex justify-between gap-x-1'}>
              <a
                href={'https://discord.gg/9JVTdFXdgF'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <DiscordIcon classes={'fill-blue-900'} />
              </a>
              <a
                href={'https://twitter.com/easeDeFi'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <TwitterIcon classes={'fill-blue-900'} />
              </a>
              <a
                href={'https://www.reddit.com/r/easeDeFi'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <RedditIcon classes={'fill-blue-900'} />
              </a>
              <a
                href={'https://github.com/easeDeFi'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <GithubIcon classes={'fill-blue-900'} />
              </a>
              <a
                href={'https://www.linkedin.com/company/easeDeFi'}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                <LinkedInIcon classes={'fill-blue-900'} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={' block absolute lg:relative text-right top-0 right-0 p-2 z-20'}>
        {account && (
          <div
            className={clsx(
              ' inline-block mt-1 text-sm text-white px-3 py-1 rounded-md font-poppins cursor-pointer',
              impersonationAddress != '' ? 'bg-green-500' : 'bg-pink-500'
            )}
            onClick={() => setOpenWallet(true)}
          >
            <span>{shrinkSplit(account.toLowerCase(), 8)}</span>
          </div>
        )}
        {!account && (
          <div
            className=" connect-wallet inline-flex items-center px-3 py-1 text-sm font-medium font-bold text-white uppercase bg-pink-500 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            onClick={() => setOpenWallet(true)}
          >
            Connect
          </div>
        )}
      </div>

      <div className={' lg:hidden bg-white w-full flex justify-between items-center z-2'}>
        <Disclosure as="nav" className="w-full bg-white z-2 ">
          {({ open}) => (
            <>
              <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-12">
                  <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <img
                    src={'/logo.png'}
                    alt={'ease.org'}
                    className={'h-10 pl-10'}
                  />
                </div>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-in duration-300 transform"
                enterFrom="opacity-0 -translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Disclosure.Panel className="lg:hidden w-full fixed left-0 bg-white z-10 min-h-screen top-12 z-1">
                  <div className="px-2 pt-6 ">
                    <div className="pb-12 relative block " >
                      {navigation
                        .filter(
                          (x) =>
                            isDevelopment ||
                            (!isDevelopment && x.name != 'Test Cases')
                        )
                        .map((item) => (
                          <Disclosure.Button
                            key={item.href}
                            className={'block relative w-full text-left'}
                          >
                            <NavLink
                              url={item.href}
                              active={item.current}
                            >
                              {item.name}
                            </NavLink>
                          </Disclosure.Button>
                        ))}
                    </div>


                    <div className={'pt-4 block relative border-t-2 border-black-50' }>
                      <div>
                        <div className={'flex flex-col gap-x-4 text-base font-poppins pl-4 '}>
                          <a className={'mb-1'} href={'https://ease.org/about-ease-defi/'} target={'_blank'}  rel={'nofollow noreferrer'} >About</a>
                          <a className={'mb-1'} href={'https://ease.org/learn/'} target={'_blank'}  rel={'nofollow noreferrer'}>Learn</a>
                          <a className={'mb-1'} href={'https://ease.org/get-involved/'} target={'_blank'}  rel={'nofollow noreferrer'}>Get Involved</a>
                        </div>
                      </div>

                      <div
                        className={
                          'flex  justify-center gap-x-4 w-2/3 mx-auto gap-y-2 mt-12'
                        }
                      >
                        <a
                          href={'https://discord.gg/9JVTdFXdgF'}
                          target={'_blank'}
                          rel={'nofollow noreferrer'}
                          className={'flex items-center gap-x-1'}
                        >
                          <DiscordIcon classes={'fill-blue-900'} />
                        </a>
                        <a
                          href={'https://twitter.com/easeDeFi'}
                          target={'_blank'}
                          rel={'nofollow noreferrer'}
                          className={'flex items-center gap-x-1'}
                        >
                          <TwitterIcon classes={'fill-blue-900'} />
                        </a>
                        <a
                          href={'https://www.reddit.com/r/easeDeFi'}
                          target={'_blank'}
                          rel={'nofollow noreferrer'}
                          className={'flex items-center gap-x-1'}
                        >
                          <RedditIcon classes={'fill-blue-900'} />
                        </a>
                        <a
                          href={'https://github.com/easeDeFi'}
                          target={'_blank'}
                          rel={'nofollow noreferrer'}
                          className={'flex items-center gap-x-1'}
                        >
                          <GithubIcon classes={'fill-blue-900'} />
                        </a>
                        <a
                          href={'https://www.linkedin.com/company/easeDeFi'}
                          target={'_blank'}
                          rel={'nofollow noreferrer'}
                          className={'flex items-center gap-x-1'}
                        >
                          <LinkedInIcon classes={'fill-blue-900'} />
                        </a>
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        <WalletConnect onClose={() => setOpenWallet(false)} open={openWallet} />
      </div>
    </div>

  )
}

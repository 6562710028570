import { Step } from 'react-joyride'
import React, { useMemo } from 'react'

export const infoTabSteps: Array<Step> = [
  {
    target: '.info-tab',
    placement: 'top',
    content: 'This tab shows detailed information about the vault and the underlying protocols.',
    disableBeacon: true
  },
  {
    target: '.info-tab-protocol',
    placement: 'top',
    content: 'What type of protocol is it?',
    disableBeacon: true
  },
  {
    target: '.info-tab-underlying-protocols',
    placement: 'top',
    content:
      'Which underlying protocols are covered (all stacked risk is covered in Uninsurance!)',
    disableBeacon: true
  },
  {
    target: '.info-tab-instructions',
    placement: 'top',
    content:
      'Information about the underlying vault with links to the token and vault addresses.',
    disableBeacon: true
  }
]

import { ContractInterface } from '../contracts'
import { useWeb3React } from '@web3-react/core'
import { useCallback, useState } from 'react'
import { makeWeb3Contract } from '../classes/helpers'

export type ContractMethodCallResponse<T> = [
  T,
  (params?: unknown[] | undefined) => Promise<T>,
  Function
]

const useContractMethodCall = <T>(
  contract: ContractInterface,
  methodName: string,
  defaultValue: unknown = undefined,
  address?: string
): ContractMethodCallResponse<T> => {
  const { account, library } = useWeb3React()
  const [value, setValue] = useState(<T>defaultValue)

  const clear = () => setValue(defaultValue as T)

  const get = useCallback(
    async (params?: unknown[]) => {
      if (!account) {
        return defaultValue
      }

      const _contract = makeWeb3Contract(
        library.currentProvider,
        contract,
        address
      )

      let result = undefined
      if (!params) {
        params = []
      }
      result = await _contract.methods[methodName](...params).call({
        from: account
      })

      // console.log(methodName, result)

      setValue(result)
      return result
    },
    [account, library, contract, methodName, defaultValue]
  )

  return [value as T, get, clear]
}

export default useContractMethodCall

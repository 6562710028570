import { Tab } from '@headlessui/react'
import { classNames, formatInfo } from '../../classes/helpers'
import React from 'react'
import { VaultsApi } from '../../hooks/api/useGetVaults'

export const InstructionTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  vault: VaultsApi.Vault
}> = ({ vault }) => {
  return (
    <Tab.Panel
      className={classNames(
        'text-white rounded-xl py-4 px-1 instructions-tab ',
        'focus:outline-none focus:ring-transparent'
      )}
    >
      <div className={'items-center gap-y-2 gap-x-3'}>
        {vault.instruction && (
          <span
            className={'text-lg mb-2 self-start '}
            dangerouslySetInnerHTML={{
              __html: formatInfo(vault.instruction, vault.info_link)
            }}
          />
        )}
      </div>
    </Tab.Panel>
  )
}

import {CheckIcon, CogIcon, SelectorIcon} from '@heroicons/react/solid'
import React, {Fragment, useState} from 'react'
import {Listbox, Menu, Transition} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import {
  selectConfig,
  selectImpersonationAddress,
  setImpersonationAddress
} from '../store/globalSlice'
import { Modal } from './Modal'
import { countDecimals } from '../classes/helpers'
import { Button } from './Button'
import clsx from 'clsx'
import { ethers } from 'ethers'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import moment from 'moment'
import { notifyError, notifySuccess } from './Layout'
import {FaucetsApi, requestTap} from '../hooks/api/useGetFaucets'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const DevelopmentCog: React.FC<{
  faucets: FaucetsApi.Faucet[],
  children?: React.PropsWithChildren<any>
}> = ({ faucets, children }) => {
  const dispatch = useAppDispatch()
  const impersonationAddress = useAppSelector(selectImpersonationAddress)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isIncreaseTimeModalOpen, setIsIncreaseTimeModalOpen] = useState(false)
  const [modalValue, setModalValue] = useState('')
  const globalConfig = useAppSelector(selectConfig)
  const [tenderlyRpcUrl, setTenderlyRpcUrl] = useState(
    globalConfig.simulation_fork && globalConfig.simulation_fork.rpc
  )
  const [timeInSeconds, setTimeInSeconds] = useState(0)
  const { account, library } = useImpersonatableWeb3React()

  const startImpersonating = () => {
    dispatch(setImpersonationAddress(modalValue))
    setIsModalOpen(false)
  }
  // Faucet states
  const [isFaucetModalOpen, setIsFaucetModalOpen] = useState(false)
  const [tapSymbol, setTapSymbol] = useState('Select')
  const [tapAmount, setTapAmount] = useState(1)


  const tapFromFaucet = async () => {
    console.log(`Tap ${tapAmount} ${tapSymbol} from faucet to ${account}`)
    await requestTap({ symbol: tapSymbol, amount: tapAmount, to: account})
    setIsFaucetModalOpen(false)
  }
  return (
    <div className={'absolute z-10 right-0 top-1/2'}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex justify-center flex-col items-center text-center w-full px-1 text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-transparent">
            <CogIcon
              className={clsx(
                'h-12 w-12 cursor-pointer',
                impersonationAddress == ''
                  ? 'text-blackop-40 hover:text-blackop-80'
                  : 'text-white hover:text-white'
              )}
            />
            {impersonationAddress != '' && (
              <div
                className={
                  'bg-blackop-50 text-white rounded text-xs px-1 ml-0.5'
                }
              >
                imposter
              </div>
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={() => setIsIncreaseTimeModalOpen(true)}
                  >
                    Increase Time
                  </a>
                )}
              </Menu.Item>
              {impersonationAddress == '' && (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Impersonate Address
                    </a>
                  )}
                </Menu.Item>
              )}
              {impersonationAddress != '' && (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={() => dispatch(setImpersonationAddress(''))}
                    >
                      Stop Impersonating
                    </a>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                    <a
                        href="#"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                        )}
                        onClick={() => setIsFaucetModalOpen(true)}
                    >
                      Tap From Faucet
                    </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Modal
        open={isModalOpen}
        title={'Set Impersonation Address'}
        onClose={() => setIsModalOpen(false)}
      >
        <div className={'flex justify-center mt-4'}>
          <div className={'flex gap-x-2 items-center w-full'}>
            <input
              type="text"
              name="price"
              id="price"
              disabled={false}
              value={modalValue}
              autoComplete={'off'}
              onChange={(e) => {
                setModalValue((v) => {
                  if (countDecimals(modalValue) > 10) {
                    return parseFloat(e.target.value).toFixed(10)
                  }
                  return e.target.validity.valid ? e.target.value : v
                })
              }}
              className={classNames(
                modalValue == '0' ? 'bg-gray-200 text-gray-500' : '',
                'font-medium text-black border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
              )}
              placeholder="0x"
            />
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            onClick={() => startImpersonating()}
            disabled={modalValue == ''}
            classes={'sm:col-start-2 capitalize font-normal'}
          >
            Start Impersonating
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            variant={'white'}
            classes={'capitalize font-normal'}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        open={isIncreaseTimeModalOpen}
        title={'Increase Tenderly Time'}
        onClose={() => setIsIncreaseTimeModalOpen(false)}
      >
        <div className={'flex flex-col justify-center mt-4'}>
          <div className={'w-full'}>
            <label htmlFor={'tenderlyRpcUrl'}>Tenderly RPC URL:</label>
            <input
              type="text"
              name="tenderlyRpcUrl"
              id="tenderlyRpcUrl"
              value={tenderlyRpcUrl}
              autoComplete={'off'}
              onChange={(e) => setTenderlyRpcUrl(e.target.value)}
              className={classNames(
                modalValue == '0' ? 'bg-gray-200 text-gray-500' : '',
                'font-medium text-black border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
              )}
              placeholder="0x"
            />
          </div>
          <div className={'w-full'}>
            <label htmlFor={'timeInSeconds'}>Time in seconds:</label>
            <input
              type="text"
              name="timeInSeconds"
              id="timeInSeconds"
              value={timeInSeconds}
              autoComplete={'off'}
              onChange={(e) => setTimeInSeconds(Number(e.target.value))}
              className={classNames(
                modalValue == '0' ? 'bg-gray-200 text-gray-500' : '',
                'font-medium text-black border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
              )}
              placeholder="0x"
            />
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
            onClick={() => {
              const web3Provider = new ethers.providers.JsonRpcProvider(
                tenderlyRpcUrl
              )
              web3Provider
                .send('evm_increaseTime', [
                  ethers.utils.hexValue(timeInSeconds)
                ])
                .then((r) => {
                  setIsIncreaseTimeModalOpen(false)
                  notifySuccess(
                    'Increased Tenderly Time',
                    'Successfully increased time by ' +
                      timeInSeconds +
                      ' seconds.'
                  )
                })
                .catch((err) =>
                  notifyError(
                    'Increased Tenderly Time',
                    'Failed to increase time.'
                  )
                )
            }}
            disabled={Number.isNaN(timeInSeconds) || tenderlyRpcUrl == ''}
            classes={'sm:col-start-2 capitalize font-normal'}
          >
            Increase Time
          </Button>
          <Button
            onClick={() => setIsIncreaseTimeModalOpen(false)}
            variant={'white'}
            classes={'capitalize font-normal'}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
          open={isFaucetModalOpen}
          title={'Tap From Faucet'}
          onClose={() => setIsModalOpen(false)}
      >
        <div className={'flex justify-center mt-4'}>
          {/* start listbox */}

          <div className={'flex gap-x-2 items-center w-full'}>
            <label htmlFor={'tenderlyRpcUrl'}>Token:</label>
            <Listbox value={tapSymbol} onChange={setTapSymbol}>
              {({open}) => (
                  <>
                    <div className="relative w-56">
                      <Listbox.Button
                          className="py-2 px-4 text-base relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-base">
                        <span className="block truncate">{tapSymbol}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                      >
                        <Listbox.Options
                            className='font-medium text-black border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'>
                          {faucets.map((faucet) => (
                              <Listbox.Option
                                  key={faucet.symbol}
                                  className={({active}) =>
                                      classNames(
                                          active
                                              ? 'text-white bg-indigo-600'
                                              : 'text-gray-900',
                                          'cursor-default select-none relative py-2 pl-3 pr-9'
                                      )
                                  }
                                  value={faucet.symbol}
                              >
                                {({selected, active}) => (
                                    <>
                                  <span
                                      className={classNames(
                                          selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                          'block truncate'
                                      )}
                                  >
                                    {faucet.symbol}
                                  </span>

                                      {selected ? (
                                          <span
                                              className={classNames(
                                                  active
                                                      ? 'text-white'
                                                      : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                          >
                                      <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                      />
                                    </span>
                                      ) : null}
                                    </>
                                )}
                              </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
              )}
            </Listbox>

          </div>

          {/* end listbox */}
          <div className={'flex gap-x-2 items-center w-full'}>
            <label htmlFor={'tenderlyRpcUrl'}>Amount:</label>
            <input
                type="text"
                name="amount"
                id="amount"
                disabled={false}
                value={tapAmount}
                autoComplete={'off'}
                onChange={(e) => setTapAmount(Number(e.target.value))}
                className={classNames(
                    modalValue == '0' ? 'bg-gray-200 text-gray-500' : '',
                    'font-medium text-black border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                )}
                placeholder="0"
            />
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <Button
              onClick={() => tapFromFaucet().then(r => console.log(r))}
              disabled={tapAmount == 0}
              classes={'sm:col-start-2 capitalize font-normal'}
          >
            Tap
          </Button>
          <Button
              onClick={() => setIsFaucetModalOpen(false)}
              variant={'white'}
              classes={'capitalize font-normal'}
          >
            Close
          </Button>
        </div>
      </Modal>

    </div>
  )
}

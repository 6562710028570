import { Tab } from '@headlessui/react'
import { classNames } from '../../classes/helpers'
import React, { useState } from 'react'
import { WalletConnect } from '../WalletConnect'
import { ConnectWalletButton } from '../ConnectWalletButton'

export const ConnectToWalletTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  text?: string
}> = ({ children, text }) => {
  return (
    <>
      <Tab.Panel
        className={classNames(
          'text-white rounded-xl py-4 px-1 text-center text-lg',
          'focus:outline-none focus:ring-transparent'
        )}
      >
        <div>
          {text ? (
            text
          ) : (
            <>Please connect your wallet to display your balance.</>
          )}
        </div>
        <ConnectWalletButton className={'mt-4'}>Connect</ConnectWalletButton>
      </Tab.Panel>
    </>
  )
}

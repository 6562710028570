import useContractMethodCall from '../useContractMethodCall'
import Contracts from '../../contracts'
import BN from 'bn.js'
import { BigNumber, BigNumberish } from 'ethers'

export interface WithdrawRequest {
  amount: number
  endTime: number
}

export interface GetUserStatsResponse {
  deposits: GetUserStatsObject
  easeEarned: GetUserStatsObject
  easeBalance: GetUserStatsObject
  easeAllowance: GetUserStatsObject
  unusedGvToken: GetUserStatsObject
  leasedGvToken: GetUserStatsObject
  gvTokenBalanceOf: GetUserStatsObject
  armorBalance: GetUserStatsObject
  vArmorBalance: GetUserStatsObject
  armorAllowance: GetUserStatsObject
  withdrawRequests: GetUserStatsObject
  vArmorAllowance: GetUserStatsObject
  bribePotBalance: GetUserStatsObject
  totalEaseDeposits: GetUserStatsObject
  earningsPerWeek: GetUserStatsObject
  expectedGvAmount: GetUserStatsObject
}

export interface GetUserStatsObject {
  value: any
  get: Function
  clear: Function
}

export interface UserDeposit {
  amount: BigNumber
  start: BigNumber
}

export const useGetUserStats = (): GetUserStatsResponse => {
  const [userDeposits, getUserDeposits, clearUserDeposits] =
    useContractMethodCall<Array<UserDeposit>>(
      Contracts.gvToken,
      'getUserDeposits',
      [] as Array<UserDeposit>
    )

  const [earningsPerWeek, getEarningsPerWeek, clearEarningsPerWeek] =
    useContractMethodCall<BigNumber>(
      Contracts.bribePot,
      'earningsPerWeek',
      BigNumber.from('0')
    )

  const [easeBalance, getEaseBalance, clearEaseBalance] =
    useContractMethodCall<BigNumber>(
      Contracts.easeToken,
      'balanceOf',
      BigNumber.from('0')
    )
  const [easeAllowance, getEaseAllowance, clearEaseAllowance] =
    useContractMethodCall<BigNumber>(
      Contracts.easeToken,
      'allowance',
      BigNumber.from('0')
    )

  const [easeEarned, getEaseEarned, clearEaseEarned] =
    useContractMethodCall<BigNumber>(
      Contracts.bribePot,
      'earned',
      BigNumber.from('0')
    )

  const [unusedGvEase, getUnusedGvEase, clearUnusedGvEase] =
    useContractMethodCall<BigNumber>(
      Contracts.gvToken,
      'powerAvailableForStake',
      BigNumber.from('0')
    )
  const [withdrawRequests, getWithdrawRequests, clearWithdrawRequests] =
    useContractMethodCall<WithdrawRequest>(
      Contracts.gvToken,
      'withdrawRequests',
      {} as WithdrawRequest
    )

  const [leasedGvEase, getLeasedGvEase, clearLeasedGvEase] =
    useContractMethodCall<BigNumber>(
      Contracts.gvToken,
      'bribedAmount',
      BigNumber.from('0')
    )

  const [bribePotBalance, getBribePotBalance, clearBribePotBalance] =
    useContractMethodCall<BigNumber>(
      Contracts.bribePot,
      'balanceOf',
      BigNumber.from('0')
    )

  const [gvTokenBalanceOf, getGvTokenBalanceOf, clearGvTokenBalanceOf] =
    useContractMethodCall<BigNumber>(
      Contracts.gvToken,
      'balanceOf',
      BigNumber.from('0')
    )

  const [totalEaseDeposits, getTotalEaseDeposits, clearTotalEaseDeposits] =
    useContractMethodCall<BigNumber>(
      Contracts.gvToken,
      'totalDeposit',
      BigNumber.from('0')
    )

  const [armorBalance, getArmorBalance, clearArmorBalance] =
    useContractMethodCall<BigNumber>(
      Contracts.armorToken,
      'balanceOf',
      BigNumber.from('0')
    )

  const [vArmorBalance, getVArmorBalance, clearVArmorBalance] =
    useContractMethodCall<BigNumber>(
      Contracts.vArmorToken,
      'balanceOf',
      BigNumber.from('0')
    )

  const [armorAllowance, getArmorAllowance, clearArmorAllowance] =
    useContractMethodCall<BigNumber>(
      Contracts.armorToken,
      'allowance',
      BigNumber.from('0')
    )

  const [expectedGvAmount, getExpectedGvAmount, clearExpectedGvAmount] =
    useContractMethodCall<BigNumber>(
      Contracts.bribePot,
      'expectedGvAmount',
      BigNumber.from('0')
    )

  const [vArmorAllowance, getVArmorAllowance, clearVArmorAllowance] =
    useContractMethodCall<BigNumber>(
      Contracts.vArmorToken,
      'allowance',
      BigNumber.from('0')
    )

  return {
    deposits: {
      value: userDeposits,
      get: getUserDeposits,
      clear: clearUserDeposits
    },
    earningsPerWeek: {
      value: earningsPerWeek,
      get: getEarningsPerWeek,
      clear: clearEarningsPerWeek
    },
    easeBalance: {
      value: easeBalance,
      get: getEaseBalance,
      clear: clearEaseBalance
    },
    easeEarned: {
      value: easeEarned,
      get: getEaseEarned,
      clear: clearEaseEarned
    },
    unusedGvToken: {
      value: unusedGvEase,
      get: getUnusedGvEase,
      clear: clearUnusedGvEase
    },
    leasedGvToken: {
      value: leasedGvEase,
      get: getLeasedGvEase,
      clear: clearLeasedGvEase
    },
    gvTokenBalanceOf: {
      value: gvTokenBalanceOf,
      get: getGvTokenBalanceOf,
      clear: clearGvTokenBalanceOf
    },
    armorBalance: {
      value: armorBalance,
      get: getArmorBalance,
      clear: clearArmorBalance
    },
    vArmorBalance: {
      value: vArmorBalance,
      get: getVArmorBalance,
      clear: clearVArmorBalance
    },
    armorAllowance: {
      value: armorAllowance,
      get: getArmorAllowance,
      clear: clearArmorAllowance
    },
    vArmorAllowance: {
      value: vArmorAllowance,
      get: getVArmorAllowance,
      clear: clearVArmorAllowance
    },
    easeAllowance: {
      value: easeAllowance,
      get: getEaseAllowance,
      clear: clearEaseAllowance
    },
    bribePotBalance: {
      value: bribePotBalance,
      get: getBribePotBalance,
      clear: clearBribePotBalance
    },
    totalEaseDeposits: {
      value: totalEaseDeposits,
      get: getTotalEaseDeposits,
      clear: clearTotalEaseDeposits
    },
    withdrawRequests: {
      value: withdrawRequests,
      get: getWithdrawRequests,
      clear: clearWithdrawRequests
    },
    expectedGvAmount: {
      value: expectedGvAmount,
      get: getExpectedGvAmount,
      clear: clearExpectedGvAmount
    }
  }
}

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon, TrashIcon } from '@heroicons/react/solid'
import React, { Fragment, useState } from 'react'
import { classNames, formatDecimals } from '../../../classes/helpers'
import { formatUnits } from 'ethers/lib/utils'
import { Button } from '../../Button'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { StakedVault } from './StakePanel'
import { GetUserStatsResponse } from '../../../hooks/gvDashboard/useGetUserStats'
import clsx from 'clsx'

export const StakedVaultRow: React.FC<{
  index: number
  vault: StakedVault
  vaults: VaultsApi.Vault[]
  stats: GetUserStatsResponse
  stakedPercentage: number
  onDelete: Function
  onUpdate: Function
  maxFee: number
}> = ({
  index,
  vault,
  vaults,
  stats,
  onDelete,
  onUpdate,
  stakedPercentage,
  maxFee
}) => {
  const [selected, setSelected] = useState(
    vault.displayName ? vault.displayName : 'Choose Vault'
  )
  const [selectedPercentage, setSelectedPercentage] = useState(
    vault.percentage > 0 ? vault.percentage / 1000 : '0'
  )
  const getVaultByDisplayName = (displayName: string): VaultsApi.Vault => {
    if (vaults && displayName) {
      let v = vaults.filter(
        (v) => v.display_name.toLowerCase() == displayName.toLowerCase()
      )
      if (v.length > 0) {
        return v[0]
      }
    }

    return {} as VaultsApi.Vault
  }
  const percentageArray = new Array(21).fill(0)

  return (
    <div
      key={`${vault.address}${vault.index}`}
      className={
        'grid grid-cols-6 mt-2 bg-blackop-30 p-3 rounded-lg text-white items-center'
      }
    >
      <div className={`gap-x-2 items-center w-full col-span-2 vault-${index}`}>
        <Listbox
          value={selected}
          onChange={(e) => {
            setSelected(e)
            onUpdate(
              vault.index,
              getVaultByDisplayName(e),
              Number(selectedPercentage) * 1000
            )
          }}
        >
          {({ open }) => (
            <>
              <div className="relative">
                <Listbox.Button className="relative w-full px-4 py-2 pl-3 pr-10 text-base text-left text-white rounded-md shadow-sm cursor-default bg-blackop-50 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 sm:text-base">
                  <span className="block truncate">{selected}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {vaults.map((vault) => (
                      <Listbox.Option
                        key={vault.address}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'text-white bg-orange-500'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={vault.display_name}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}
                            >
                              {vault.display_name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-orange-500',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      <div
        className={`gap-x-2 items-center mx-auto w-full px-2 sm:px-8 staked-${index}`}
      >
        <div>
          <Listbox
            value={selectedPercentage}
            onChange={(e) => {
              setSelectedPercentage(e)
              onUpdate(
                vault.index,
                getVaultByDisplayName(vault.displayName),
                Number(e) * 1000
              )
            }}
          >
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button
                    className={clsx(
                      'bg-blackop-50 px-4 text-white text-base relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500 sm:text-base',
                      stakedPercentage > 100000
                        ? 'border border-red-500'
                        : stakedPercentage == 100000
                        ? 'border border-green-500'
                        : ''
                    )}
                  >
                    <span className="block truncate">
                      {selectedPercentage}%
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {percentageArray.map((pct, idx) => (
                        <Listbox.Option
                          key={100 - idx * 5}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-orange-500'
                                : 'text-gray-900',
                              'cursor-default select-none relative py-2 pl-3 pr-9'
                            )
                          }
                          value={100 - idx * 5}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {100 - idx * 5}%
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-orange-500',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className={`text-center amount-${index}`}>
        {vault.amount &&
          formatDecimals(
            Number(formatUnits(vault.amount.toString(), 18)),
            0,
            0
          )}{' '}
      </div>
      <div className={`font-bold text-center max-fee-${index}`}>
        {formatDecimals(maxFee, 2, 0)}%
      </div>
      <div className={'flex justify-end'}>
        {!vault.isApi && (
          <TrashIcon
            className={
              'h-6 w-6 text-white-50 hover:text-red-500 cursor-pointer'
            }
            onClick={() => onDelete()}
          />
        )}
      </div>
    </div>
  )
}

import { Combobox, Transition, Listbox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import React, { Fragment, useMemo, useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { useWeb3React } from '@web3-react/core'
import { OpportunityRow } from './OpportunityRow'
import { capitalize, classNames } from '../../../classes/helpers'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { SubTitle } from '../SubTitle'
import { ArrowIcon } from '../../icons/ArrowIcon'
import { formatUnits } from 'ethers/lib/utils'
import { WalletConnect } from '../../WalletConnect'
import { TopVaults } from './TopVaults'
import { BigNumber } from 'ethers'
import {
  dedupeVaults,
  sortByApyAndBalance,
  sortByAvailableToDeposit,
  sortByCapacity,
  sortBySymbol,
  sortByTotalAssets,
  sortByMaxFee
} from '../../../utils/vaults'

export const Opportunities: React.FC<{
  vaults: VaultsApi.Vault[]
  topVaults: VaultsApi.Vault[]
  onUpdate: Function
  withdrawalHours: string
}> = ({ vaults, topVaults, withdrawalHours, onUpdate }) => {
  const { account } = useWeb3React()
  const [selected, setSelected] = useState('All Protocols')
  const [query, setQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortByType, setSortByType] = useState('APY')
  const [sortDirection, setSortDirection] = useState('desc')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [topVaultSelected, setTopVaultSelected] =
    useState<VaultsApi.Vault | null>()

  const setSortBy = (column: string = 'Name') => {
    if (column == sortByType) {
      if (sortDirection == 'asc') {
        setSortDirection('desc')
        return
      }

      setSortDirection('asc')
      return
    }

    setSortDirection('desc')
    setSortByType(column)
  }

  const memoizedProtocols = useMemo(() => {
    let _protocols = ['All Protocols']
    if (vaults) {
      vaults.forEach((v) => {
        if (!_protocols.includes(capitalize(v.top_protocol))) {
          _protocols.push(capitalize(v.top_protocol))
        }
      })
    }
    return _protocols
  }, [vaults])

  const filteredProtocols = useMemo(
    () =>
      query === ''
        ? memoizedProtocols
        : memoizedProtocols.filter((protocol) =>
            protocol
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''))
          ),
    [memoizedProtocols, query]
  )

  const filteredVaults = useMemo(() => {
    let _vaults: VaultsApi.Vault[] = []
    if (vaults) {
      _vaults = [...vaults]

      if (selected == 'All Protocols') {
        return _vaults
      }

      _vaults = _vaults.filter(
        (v) => v.top_protocol.toLowerCase() == selected.toLowerCase()
        // v.protocols.map((p) => p.toLowerCase()).includes(selected.toLowerCase())
      )
    }

    return _vaults
  }, [selected, vaults])

  const vaultAttributes = [
    'symbol',
    'name',
    'address',
    'display_name',
    'protocols'
  ]
  const search = (q: string, v: any, fields: string[]) => {
    return (
      !q ||
      fields
        .map((a) => v[a])
        .some((s) => s && s.toString().toLowerCase().includes(q.toLowerCase()))
    )
  }

  const tokenAttributes = ['symbol', 'name', 'address']
  const searchTokens = (q: string, v: any, fields: string[]) => {
    return (
      !q ||
      fields
        .map((a) => v['token'][a])
        .some((s) => s && s.toString().toLowerCase().includes(q.toLowerCase()))
    )
  }

  const queriedVaults = useMemo(() => {
    if (searchQuery === '') {
      return filteredVaults
    }

    let _vaults = [
      ...filteredVaults.filter((v) => search(searchQuery, v, vaultAttributes)),
      ...filteredVaults.filter((v) =>
        searchTokens(searchQuery, v, tokenAttributes)
      )
    ]

    return dedupeVaults(_vaults)
  }, [dedupeVaults, filteredVaults, searchQuery])

  const calcTotalAssets = (vault: VaultsApi.Vault) => {
    return BigNumber.from(vault.token.assets).mul(
      BigNumber.from((Number(vault.token.priceUSD) * 100).toFixed(0))
    )
  }

  const sortedVaults = useMemo(() => {
    let sorted = queriedVaults

    switch (sortByType) {
      case 'Name':
        return sortBySymbol(sorted, sortDirection)
      case 'APY':
        return sortByApyAndBalance(sorted, sortDirection)
      case 'Total Assets':
        return sortByTotalAssets(sorted, sortDirection)
      case 'Available to Deposit':
        return sortByAvailableToDeposit(sorted, sortDirection)
      case 'Capacity':
        return sortByCapacity(sorted, sortDirection)
      case 'Max Fee':
        return sortByMaxFee(sorted, sortDirection)
      case '':
      default:
        return queriedVaults
    }
  }, [queriedVaults, sortDirection, sortByType])

  return (
    <div className={'w-full container-md mx-auto'}>
      <div className="text-lg text-white font-bold border-t border-blue-900 mt-4 pt-2">
        <SubTitle
          title={'Opportunities'}
          classes={'px-2 mb-2 md:mb-none md:px-none'}
          tooltip={
            'A list of all Uninsurance vaults available for deposits that you are not currently contributing to.'
          }
        />
      </div>

      <div className={'opportunities-top-vaults'}>
        <TopVaults
          vaults={topVaults}
          total={sortedVaults.length}
          onUpdate={onUpdate}
          withdrawDelay={withdrawalHours}
        />
      </div>

      <div className={'opportunities-filters'}>
        <div
          className={'flex flex-col md:flex-row pt-4 items-center justify-end'}
        >
          <div
            className={
              'flex flex-col md:flex-row gap-y-2 md:gap-y-none gap-x-2 items-center'
            }
          >
            <div>
              <Listbox value={selected} onChange={setSelected}>
                {({ open }) => (
                  <>
                    <div className="relative w-56">
                      <Listbox.Button className="bg-blackop-50 py-2 px-4 text-white text-base relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-base">
                        <span className="block truncate">{selected}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {filteredProtocols.map((protocol) => (
                            <Listbox.Option
                              key={protocol}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'text-white bg-indigo-600'
                                    : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-3 pr-9'
                                )
                              }
                              value={protocol}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {protocol}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            <div className="relative rounded-md shadow-sm md:mr-2">
              <input
                type={'text'}
                className={
                  'rounded-lg bg-blackop-50 py-2 px-4 text-white text-base pl-3 border-none focus:ring-transparent'
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={'Vault/Token'}
              />
              <div
                className={classNames(
                  'absolute inset-y-0 right-0 pr-3 block flex items-center',
                  searchQuery == '' ? 'pointer-events-none' : ''
                )}
              >
                {searchQuery == '' && (
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                {searchQuery != '' && (
                  <XIcon
                    className="h-5 w-5 text-gray-400 cursor-pointer z-20"
                    aria-hidden="true"
                    onClick={() => setSearchQuery('')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          'grid grid-cols-5 md:grid-cols-7 mt-4 font-poppins text-sm opportunities-sorting'
        }
      >
        <div
          className={
            'text-white col-span-3 md:col-span-2 flex gap-x-1 items-center cursor-pointer ml-2 md:ml-none'
          }
          onClick={() => setSortBy('Name')}
        >
          Name{' '}
          <ArrowIcon
            selected={sortByType == 'Name'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={'text-white flex gap-x-1 items-center cursor-pointer'}
          onClick={() => setSortBy('APY')}
        >
          APY{' '}
          <ArrowIcon
            selected={sortByType == 'APY'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Total Assets')}
        >
          Total Assets{' '}
          <ArrowIcon
            selected={sortByType == 'Total Assets'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Max Fee')}
        >
          Max Fee{' '}
          <ArrowIcon
            selected={sortByType == 'Max Fee'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Available to Deposit')}
        >
          Available to Deposit{' '}
          <ArrowIcon
            selected={sortByType == 'Available to Deposit'}
            sortDirection={sortDirection}
          />
        </div>
        <div
          className={
            'text-white hidden md:flex gap-x-1 items-center cursor-pointer'
          }
          onClick={() => setSortBy('Capacity')}
        >
          Capacity{' '}
          <ArrowIcon
            selected={sortByType == 'Capacity'}
            sortDirection={sortDirection}
          />
        </div>
      </div>
      <div className={'opportunities-table'}>
        <div
          className={
            'mt-4 rounded-2xl bg-blackop-50 text-white mx-2 md:mx-none'
          }
        >
          {sortedVaults &&
            sortedVaults.map((vault, index) => (
              <OpportunityRow
                index={index}
                total={sortedVaults.length}
                key={`${vault.address}${vault.symbol}${index}`}
                onUpdate={onUpdate}
                vault={vault}
                withdrawalDelay={withdrawalHours}
              />
            ))}
          {!account && sortedVaults.length == 0 ? (
            <div
              className={
                'p-4 text-white text-center text-lg flex flex-col items-center'
              }
            >
              Please connect your wallet to display vaults
              <div
                className="cursor-pointer inline-flex font-bold uppercase items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-500 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 mt-4"
                onClick={() => setIsModalOpened(true)}
              >
                Connect
              </div>
            </div>
          ) : (
            sortedVaults.length == 0 && (
              <div className={'p-4 text-white text-center text-lg'}>
                No Vaults Found
              </div>
            )
          )}
        </div>
      </div>
      <WalletConnect
        onClose={() => setIsModalOpened(false)}
        open={isModalOpened}
      />
    </div>
  )
}

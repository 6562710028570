import { useQuery } from 'react-query'

const getTxs = async (address: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/api/v1/stats/${address}`
  )
  return response.json()
}

export default getTxs

export declare module TxApi {
  export interface Tx {
    events: Event[]
  }
  export interface Event {
    eventName: string
    eventTime: string
    transactionHash: string
    vault: string,
    uAmount: string
  }
  export interface Stat {
    redeemed: StatItem
    balance: StatItem
    deposited: StatItem
    gains: StatItem
  }

  export interface StatItem {
    rcaAmount: string
    uAmount: string
    usd: number
  }
}
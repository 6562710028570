import { BigNumberish, ethers } from 'ethers'

export interface AddEthereumChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string // 2-6 characters long
    decimals: 18
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[] // Currently ignored.
}

export const changeNetwork = async ({
  networkName,
  rpcUrl,
  chainId,
  setError
}: {
  networkName: string
  rpcUrl: string
  chainId: number
  setError: Function
}) => {
  try {
    // @ts-ignore
    if (!window.ethereum) return

    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${chainId.toString(16)}`,
          chainName: networkName,
          rpcUrls: [rpcUrl],
          nativeCurrency: { name: 'Ethereum', symbol: 'ETH', decimals: 18 },
          blockExplorerUrls: ['https://etherscan.io']
        }
      ] as AddEthereumChainParameter[]
    })
  } catch (err: Error | any) {
    setError(err.message)
  }
}

export interface BuildSignMessageParams {
  owner: string
  contract: string
  name: string
  spender: string
  value: BigNumberish
  nonce: number
  deadline: BigNumberish
}

function buildMsgParams(params: BuildSignMessageParams) {
  return JSON.stringify({
    domain: {
      name: params.name,
      version: '1',
      chainId: 1,
      verifyingContract: params.contract
    },
    message: {
      owner: params.owner,
      spender: params.spender,
      value: params.value.toString(),
      nonce: params.nonce,
      deadline: params.deadline.toString()
    },
    primaryType: 'Permit',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ],
      Permit: [
        {
          name: 'owner',
          type: 'address'
        },
        {
          name: 'spender',
          type: 'address'
        },
        {
          name: 'value',
          type: 'uint256'
        },
        {
          name: 'nonce',
          type: 'uint256'
        },
        {
          name: 'deadline',
          type: 'uint256'
        }
      ]
    }
  })
}

export async function signEncryptionKey(
  params: BuildSignMessageParams,
  providerRequest: (request: {
    method: string
    params?: Array<any>
  }) => Promise<any>
): Promise<any> {
  const msgParams = buildMsgParams(params)
  console.log(msgParams)
  const res = await providerRequest({
    method: 'eth_signTypedData_v4',
    params: [params.owner, msgParams],
    // @ts-ignore
    from: params.owner
  })
  const { v, r, s } = ethers.utils.splitSignature(res)

  return { v, r, s }
}

import React, { useState } from 'react'
import { WalletConnect } from './WalletConnect'

export const ConnectWalletButton: React.FC<{
  children?: React.PropsWithChildren<any>
  className?: string
}> = ({ children, className }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  return (
    <div className={className}>
      <div
        className="cursor-pointer inline-flex font-bold uppercase items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-500 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        onClick={() => setIsModalOpened(true)}
      >
        {children}
      </div>
      <WalletConnect
        onClose={() => setIsModalOpened(false)}
        open={isModalOpened}
      />
    </div>
  )
}

import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react'
import {
  classNames,
  weiToEth,
  flexibleDecimals,
  relevantDigits
} from '../classes/helpers'
import { Button } from './Button'
import clsx from 'clsx'
import { parseUnits } from 'ethers/lib/utils'
import { BigNumber } from 'ethers'

export interface AmountModalData {
  isOpen: boolean
  title: string
  buttonText: string
  symbol: string
  maxAmount: string
  onConfirm: Function
}

export const AmountModal: React.FC<{
  children: React.PropsWithChildren<any>
  title: ReactNode | string
  confirmText: string
  maxAmount: string
  decimals: number
  symbol: string
  onChange: Function
  onConfirm: Function
  onClose: Function
  open: boolean
}> = ({
  children,
  title = 'Confirm',
  confirmText = 'OK',
  symbol,
  onConfirm,
  onChange,
  onClose,
  maxAmount,
  decimals,
  open = false
}) => {
  const cancelButtonRef = useRef(null)
  const getMax = () => {
    let max = weiToEth(maxAmount).toString()
    // to avoid Numeric Fault error, reduce the amount of decimals to maximum 17
    const decimals = max.split('.')[1]
    if( decimals && decimals.length > 17) max = relevantDigits(Number(max), 17)
    return max
  }
  const [value, setValue] = useState(
    maxAmount != '' ? getMax() : '0'
  )
  const valRegexMatch = /^\d{1,}(\.\d{0,18})?$/
  const disableConfirm = () => {
    return Number(maxAmount) == 0 || value === '0' || value === '' || !value.match(valRegexMatch)
  }

  useEffect(() => {
    if (open) {
      if (maxAmount != '') setValue( getMax() )
    }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 font-poppins"
                  >
                    {title}
                  </Dialog.Title>

                  <div className={'flex justify-center mt-4'}>
                    <div className={'flex gap-x-2 items-center'}>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        disabled={false}
                        value={value}
                        autoComplete={'off'}
                        onChange={(e) => {
                          let val = e.target.value
                          if(val.includes(',')) val = val.replace(',','.')

                          if(val.match(valRegexMatch)){

                            if ( parseUnits(val, decimals).gt(BigNumber.from(maxAmount)) ) {
                              val = getMax()
                            }
                            onChange(val)
                          }
                          setValue(val)

                        }}
                        className={classNames(
                          'font-medium text-black text-right border border-orange-500 focus:ring-orange-500 focus:border-orange-500 block w-full sm:text-base rounded-2xl'
                        )}
                        placeholder="0.00"
                        aria-describedby="price-currency"
                      />
                      <div>{symbol}</div>
                    </div>
                  </div>

                  <div className={'text-sm pt-4'}>
                    <span className={'font-bold'}>Current Balance:</span>{' '}
                    <span
                      className={'text-pink-500 cursor-pointer'}
                      onClick={() =>
                        setValue( getMax() )
                      }
                    >
                      {maxAmount != '' &&
                        getMax() }
                    </span>
                  </div>
                </div>
              </div>
              {children}
              <div
                className={clsx(
                  'sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
                  !children ? 'mt-5 sm:mt-6' : ''
                )}
              >
                <Button
                  onClick={() => onConfirm()}
                  disabled={ disableConfirm() }
                  classes={'sm:col-start-2 capitalize font-normal'}
                >
                  {confirmText}
                </Button>
                <Button
                  onClick={() => onClose()}
                  buttonRef={cancelButtonRef}
                  variant={'white'}
                  classes={'capitalize font-normal'}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import mainnetConfig from './mainnet-config'
import rinkebyConfig from './rinkeby-config'

const branch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF

const cnf = branch === 'rinkeby' ? rinkebyConfig : mainnetConfig

const config = { branch, ...cnf }

export default config

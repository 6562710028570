import { Step } from 'react-joyride'

export const fourthSteps: Array<Step> = [
  {
    target: '.fourth-step',
    placement: 'top',
    content: 'Press this button to add a new stake. You can add as many stakes as you like, as long as the total allocation remains 100%.',
    disableBeacon: true
  },
  {
    target: '.assign-stakes',
    placement: 'top',
    content: 'Once you are done with choosing your preferred staked, click the [ASSIGN STAKES] button. This will start a transaction that you will need to confirm to finalize the changes. ',
    disableBeacon: true
  }
]

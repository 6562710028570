import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { JoyrideTooltip } from '../components/bribes/JoyrideTooltip'
import { useJoyrideSteps } from '../hooks/bribes/useJoyrideSteps'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { AppDispatch } from '../store'
import { PageTitle } from '../components/PageTitle'
import { VaultsApi } from '../hooks/api/useGetVaults'
import { PageLoading } from '../components/PageLoading'
import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { setOpenDepositPanel, setTabPanelIndex } from '../store/gvJoyrideSlice'
import { selectConfig } from '../store/globalSlice'
import { WrongChainAlert } from '../components/WrongChainAlert'
import { useGetUserStats } from '../hooks/gvDashboard/useGetUserStats'
import useContractMethodSend from '../hooks/useContractMethodSend'
import Contracts from '../contracts'
import { ConnectWalletPage } from '../components/ConnectWalletPage'
import { LoadingModal } from '../components/LoadingModal'
import { AmountModal, AmountModalData } from '../components/AmountModal'
import { formatDecimals, isDevelopmentUrl, weiToEth, scrollToTop } from '../classes/helpers'
import { LightningBoltIcon, LockOpenIcon } from '@heroicons/react/solid'
import { Button } from '../components/Button'
import { ConnectToWalletTabPanel } from '../components/dashboard/ConnectToWalletTabPanel'
import useGetEase, {
  EaseEventsApi,
  EaseStatsApi,
  EaseUsersApi
} from '../hooks/api/useGetEase'
import { SliderModal, SliderModalData } from '../components/SliderModal'
import { useImmer } from 'use-immer'
import { formatUnits } from 'ethers/lib/utils'
import { PercentageOfModalStat } from '../components/gvDashboard/PercentageOfModalStat'
import { TenderlyRpcAlert } from '../components/dashboard/TenderlyRpcAlert'
import { useImpersonatableWeb3React } from '../hooks/useImpersonatableWeb3React'
import { BribePanel } from '../components/gvDashboard/Panels/BribePanel'
import { selectVaults } from '../store/vaultSlice'
import { BigNumber } from 'ethers'
import useSignMessage from '../hooks/useSignMessage'
import useContractMethodCall from '../hooks/useContractMethodCall'
import useGetNonce from '../hooks/useGetNonce'
import { Tooltip } from 'react-tippy'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { TooltipHtml } from '../components/TooltipHtml'
import { Transition } from '@headlessui/react'
import { SuccessAlert } from '../components/SuccessAlert'
import moment from 'moment/moment'
import { StatsCard } from '../components/gvDashboard/StatsCard'
import { LeasePanel } from '../components/gvDashboard/Panels/LeasePanel'
import { StakePanel } from '../components/gvDashboard/Panels/StakePanel'

interface StatData {
  name: string
  stat: string
  button: string
  buttonVariant: 'orange' | 'gray' | 'blackop' | 'pink' | 'white' | 'blue'
  buttonClick: Function
  buttonDisabled: boolean
  icon: ReactNode
  title?: string
}

export const GvBribing: React.FC<{}> = () => {
  const vaults = useAppSelector(selectVaults)
  const {
    isLoading: isEaseEventsLoading,
    isError: isEaseEventsError,
    data: easeEvents,
    error: eventError
  } = useGetEase()
  const { account, chainId, library } = useImpersonatableWeb3React()
  const stats = useGetUserStats()
  const [modalValue, setModalValue] = useState('0')
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const globalConfig = useAppSelector(selectConfig)
  const [isLoading, setIsLoading] = useState(true)
  const [userEaseStats, setUserEaseStats] = useState<EaseStatsApi.User>(
    {} as EaseStatsApi.User
  )
  const [userEvents, setUserEvents] = useState<EaseEventsApi.Event[]>(
    {} as EaseEventsApi.Event[]
  )
  const [easeStats, setEaseStats] = useState<EaseStatsApi.Response>(
    {} as EaseStatsApi.Response
  )

  const [stepIndex, setStepIndex] = useState(0)
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false)
  const { steps, setOverrideSteps } = useJoyrideSteps(account)
  const [bribeJoyrideSeenTour, setBribeJoyrideSeenTour] = useLocalStorage(
    'bribe_joyride_seen_tour',
    false
  )
  const dispatch: AppDispatch = useAppDispatch()

  useEffect(() => {
    if (!bribeJoyrideSeenTour) {
      setIsJoyrideRunning(true)
    }
  }, [])

  const [amountModalObj, setAmountModalObj] = useImmer<AmountModalData>(
    {} as AmountModalData
  )
  const [sliderModalObj, setSliderModalObj] = useImmer<SliderModalData>(
    {} as SliderModalData
  )
  const isOnWrongChain = useMemo(() => chainId && chainId != 1, [chainId])

  const userWeeklyBribes = useMemo(() => {
    if (account && userEaseStats && Object.keys(userEaseStats).length > 0) {
      return Object.entries(userEaseStats).reduce((prev: BigNumber, cur) => {
        if (cur[1].bribes) {
          const n: number = Object.keys(cur[1].bribes).length
          if (n > 0) {
            prev = prev.add(BigNumber.from(cur[1].bribes[n - 1].bribePerWeek))
          }
        }
        return prev
      }, BigNumber.from('0'))
    }
    return BigNumber.from('0')
  }, [account, userEaseStats])

  useEffect(() => {
    if (easeEvents && easeEvents.users && account) {
      Object.keys(easeEvents.users).map((k, i) => {
        if (k.toLowerCase() == account?.toLowerCase()) {
          // @ts-ignore
          setUserEaseStats(easeEvents.users[k])
        }
      })
    }
  }, [easeEvents, account])

  useEffect(() => {
    if (easeEvents) {
      setEaseStats(easeEvents)
    }
  }, [easeEvents, chainId])

  useEffect(() => {
    if (account && chainId == 1) {
      setIsLoading(true)
      Promise.all([
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/events?q=${account}`)
          .then((r) => r.json())
          .then((j: EaseEventsApi.Event[]) => {
            setUserEvents(j)
          }),
        stats.easeBalance.get([account]),
        stats.gvTokenBalanceOf.get([account]),
        stats.bribePotBalance.get([account]),
        stats.totalEaseDeposits.get([account]),
        stats.expectedGvAmount.get(['1000000000000000000']),
        stats.easeEarned.get([account]),
        getTokenName
        // currentBribes.get([account])
      ])
        .catch((err) => {
          console.error('error loading', err)
          setIsLoading(false)
        })
        .then((res) => {
          console.log('loaded')
          setIsLoading(false)
        })
    }
  }, [account, chainId])

  // smart contract callbacks and event handlers
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }
  const hasEaseEarned = () => {
    return stats.easeEarned.value && Number(stats.easeEarned.value) > 0
  }

  const onClaimRewardSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.deposits.get([account]),
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account])
    ])
      .catch((err) => console.error(err))
      .then((res) => {
        //console.log('loaded')
      })
  }

  const onDeposit = () => {
    setAmountModalObj((v) => {
      v.maxAmount = stats.easeBalance.value
      v.title = 'Deposit Unstaked Ease'
      v.isOpen = true
      v.onConfirm = () => onDepositConfirm()
      return v
    })
  }
  const [tokenNonce, getTokenNonce] = useGetNonce()

  const onDepositConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })
    setLoadingModalOpen(true)
    setLoadingModalTitle('Deposit Ease')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction for depositing ease
        </div>
      </>
    )

    Promise.all([getTokenNonce(Contracts.gvToken.address), getTokenName]).then(
      async (res) => {
        console.log(res)
        signMessage({
          contract: Contracts.easeToken.address,
          deadline: BigNumber.from('1671909748'),
          name: 'Ease',
          nonce: res[0],
          owner: account,
          spender: Contracts.gvToken.address,
          value: stats.easeBalance.value
        }).catch((err) => {
          console.log(err)
          setLoadingModalOpen(false)
        })
      }
    )
  }

  const [tokenName, getTokenName] = useContractMethodCall<string>(
    Contracts.easeToken,
    'name',
    ''
  )

  const onDepositSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.deposits.get([account]),
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account])
    ])
      .catch((err) => console.error(err))
      .then((res) => {
        //console.log('loaded')
      })
  }

  const signMessage = useSignMessage({
    onError(error: Error): void {
      setLoadingModalOpen(false)
    },
    onSuccess(val: { v: number; r: string; s: string }): void {
      setLoadingModalTitle('Deposit')
      setLoadingModalBody(
        <>
          <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
            Please confirm transaction to deposit ease
          </div>
        </>
      )
      deposit([
        stats.easeBalance.value,
        [BigNumber.from('1671909748').toString(), val.v, val.r, val.s]
      ]).then(() => {})
    }
  })

  const deposit = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'deposit',
    onSuccess: onDepositSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const onClaimAndDepositRewards = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Claim Rewards and Deposit')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to claim rewards and deposit
        </div>
      </>
    )
    claimRewardsAndDeposit().then(() => {})
  }

  const claimRewardsAndDeposit = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'claimAndDepositReward',
    onSuccess: onClaimRewardSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const statsData: StatData[] = useMemo(
    () => [
      {
        name: '$Ease Balance',
        stat: stats.easeBalance
          ? formatDecimals(weiToEth(stats.easeBalance.value), 3, 0)
          : '0',
        button: '',
        buttonVariant: 'pink',
        buttonClick: () => onDeposit(),
        buttonDisabled:
          !stats.easeBalance || Number(stats.easeBalance.value) == 0,
        icon: <LockOpenIcon className={'w-8 h-8 text-blackop-30'} />,
        title: 'This is the amount of $Ease that is available to be used for bribes.'
      },
      {
        name: 'Total Weekly Bribes',
        stat: userWeeklyBribes
          ? formatDecimals(weiToEth(userWeeklyBribes), 3, 0)
          : '0',
        button: '',
        buttonVariant: 'blackop',
        buttonClick: () => {},
        buttonDisabled: false,
        icon: <LightningBoltIcon className={'w-8 h-8 text-blackop-30'} />,
        title: 'The total amount of $Ease you spend weekly on bribes.'
      }
    ],
    [stats.easeBalance, stats.easeEarned, stats.gvTokenBalanceOf]
  )

  if (!vaults) {
    return (
      <PageLoading title={'Bribing gvToken Power'}>
        Loading Bribes...
      </PageLoading>
    )
  }

  const tabs = ['Lease gvEase', 'Current Stake', 'Bribe', 'Power']

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step, lifecycle } = data

    // clicked "Not Now" or "Never" on intro
    if (type == EVENTS.STEP_AFTER && action == ACTIONS.CLOSE) {
      setIsJoyrideRunning(false)
      setBribeJoyrideSeenTour(true)
      return
    }

    if (type == EVENTS.STEP_AFTER || type == EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (
      status == STATUS.FINISHED ||
      status == STATUS.SKIPPED ||
      status == STATUS.PAUSED
    ) {
      // Need to set our running state to false, so we can restart if we click start again.
      setIsJoyrideRunning(false)
      setBribeJoyrideSeenTour(true)
      dispatch(setOpenDepositPanel(false))
      scrollToTop()
    }

    switch (step.target) {
      case '.bribe-0':
        dispatch(setOpenDepositPanel(true))
        dispatch(setTabPanelIndex(0))
        break
      case '.bribes':
        dispatch(setTabPanelIndex(1))
        scrollToTop()
        dispatch(setOpenDepositPanel(false))
        break
    }
  }

  return (
    <div>
      <Transition.Root show={ isEaseEventsLoading}>
        <PageLoading title={'Bribing gvToken Power'}>Loading Bribes...</PageLoading>
      </Transition.Root>

      <Transition
        show={ !isEaseEventsLoading}
        as={Fragment}
        enter="transition ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >

        <div className={'w-full container-lg mx-auto gv-bribe-app mb-12'}>
        {isDevelopmentUrl() && (
          <TenderlyRpcAlert className={'mt-4 mb-4'}>
            When using the development version of Ease, please ensure you are
            using the most up-to-date Tenderly RPC:{' '}
            <span className={'font-bold'}>
              {globalConfig.simulation_fork && globalConfig.simulation_fork.rpc}
            </span>
          </TenderlyRpcAlert>
        )}

        {isOnWrongChain && (
          <WrongChainAlert
            chainId={chainId ? chainId : 0}
            className={'mb-4 mt-8'}
          />
        )}

        {!account && (
          <ConnectWalletPage
            title={<></>}
            text={'Please connect wallet to view $gvBribes dashboard'}
          />
        )}

        <PageTitle>
          <div className={'mt-4 flex gap-x-2 justify-center items-center'}>
            Bribing gvEase Power{' '}
            <Tooltip
              interactive
              position="top"
              trigger="mouseenter"
              html={
                <TooltipHtml
                  title="You can bribe to influence the maximum fee of a vault. You bribe with your $Ease to direct $gvEase to the vault of your choice, therefore lowering the maximum fee."
                  link="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/what-are-bribes-and-leases/"
                  name="bribing"
                />
              }
            >
              <InformationCircleIcon
                onClick={() => {setIsJoyrideRunning(true)}}
                className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
              />
            </Tooltip>
          </div>
        </PageTitle>
        <Joyride
          steps={steps}
          run={isJoyrideRunning}
          stepIndex={stepIndex}
          scrollOffset={150}
          tooltipComponent={JoyrideTooltip}
          callback={handleJoyrideCallback}
          continuous={true}
        />

        <div>
          <div>
            <dl className="grid grid-cols-1 mt-5 overflow-hidden divide-y divide-black rounded-lg shadow bg-blackop-50 md:grid-cols-2 md:divide-y-0 md:divide-x">
              {statsData.map((item, index) => (
                <div key={item.name} className={`px-4 py-5 sm:p-6 bribe-${index}`}>
                  <div className={'flex items-center'}>
                    <div className={'pr-4'}>{item.icon}</div>
                    <div className={'flex-grow'}>
                      <dt className="flex text-base font-normal text-white">
                        {item.name}
                        {item.title && (
                          <Tooltip
                            interactive
                            className="ml-1"
                            position="top"
                            trigger="mouseenter"
                            title={item.title}
                          >
                            <InformationCircleIcon
                              onClick={() => {}}
                              className={
                                'h-4 w-4 text-blue-900 mt-1 cursor-pointer'
                              }
                            />
                          </Tooltip>
                        )}

                      </dt>
                      <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-orange-500">
                          {item.stat}
                        </div>

                        {item.button != '' && (
                          <Button
                            onClick={item.buttonClick}
                            variant={item.buttonVariant}
                            disabled={item.buttonDisabled}
                            size={'xs'}
                            classes={'capitalize font-normal'}
                          >
                            {item.button}
                          </Button>
                        )}
                      </dd>
                    </div>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div
          className={
            'bribes bg-blackop-50 rounded-r-lg rounded-l-lg rounded-bl-lg p-2 mt-8'
          }
        >
          <BribePanel
            stats={stats}
            userStats={userEaseStats}
            vaults={vaults as VaultsApi.Vault[]}
            easeStats={easeStats}
          />
        </div>

        {/* {!account && (
          <div className={'bg-blackop-50 rounded-r-lg rounded-bl-lg '}>
            <ConnectToWalletTabPanel
              text={'Please connect your wallet to view leasing features'}
            />
          </div>
        )} */}

        {amountModalObj.isOpen && (
          <AmountModal
            title={amountModalObj.title}
            confirmText={amountModalObj.buttonText}
            onConfirm={() => amountModalObj.onConfirm()}
            onClose={() =>
              setAmountModalObj((v) => {
                v.isOpen = false
                return v
              })
            }
            maxAmount={amountModalObj.maxAmount}
            decimals={18}
            onChange={(e: any) => setModalValue(e)}
            open={amountModalObj.isOpen}
            symbol={amountModalObj.symbol}
          >
            &nbsp;
          </AmountModal>
        )}

        {sliderModalObj.isOpen && (
          <SliderModal
            title={sliderModalObj.title}
            confirmText={sliderModalObj.buttonText}
            onConfirm={() => sliderModalObj.onConfirm()}
            onClose={() =>
              setSliderModalObj((v) => {
                v.isOpen = false
                return v
              })
            }
            maxAmount={sliderModalObj.maxAmount}
            onChange={(e: any) => {
              setModalValue(e)
              setSliderModalObj((v) => {
                v.symbol = `${e}%`
                return v
              })
            }}
            open={sliderModalObj.isOpen}
            symbol={sliderModalObj.symbol}
          >
            <PercentageOfModalStat
              value={Number(formatUnits(stats.gvTokenBalanceOf.value, 18))}
              percent={parseInt(modalValue)}
              token={'gvEase'}
            />
          </SliderModal>
        )}

        <LoadingModal
          open={loadingModalOpen}
          title={loadingModalTitle}
          onClose={() => {}}
        >
          {loadingModalBody}
        </LoadingModal>
      </div>

      </Transition>

    </div>
  )
}

import React from 'react'
import { Tooltip } from 'react-tippy'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { classNames } from '../../classes/helpers'

export const SubTitle: React.FC<{
  title: string
  tooltip: string
  classes?: string
}> = ({ title, tooltip, classes = '' }) => {
  return (
    <div className={classNames('flex gap-x-2 items-center', classes)}>
      {title}{' '}
      <Tooltip
        // options
        title={tooltip}
        position="top"
        trigger="mouseenter"
      >
        <InformationCircleIcon className={'h-5 w-5 text-blue-900 mt-1'} />
      </Tooltip>
    </div>
  )
}

import { BigNumber, BigNumberish } from 'ethers'
import useContractMethodSend from '../../../hooks/useContractMethodSend'
import Contracts from '../../../contracts'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'
import { SliderModalData } from '../../SliderModal'
import { Button } from '../../Button'
import { EaseStatsApi, EaseUsersApi } from '../../../hooks/api/useGetEase'
import { GetUserStatsResponse } from '../../../hooks/gvDashboard/useGetUserStats'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { LoadingModal } from '../../LoadingModal'
import { useImpersonatableWeb3React } from '../../../hooks/useImpersonatableWeb3React'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { StakedVaultRow } from './StakedVaultRow'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Tooltip } from 'react-tippy'
import { TooltipHtml } from '../../TooltipHtml'
import { formatEther, formatUnits } from 'ethers/lib/utils'

const TYPE_STAKE = 'stake'
const TYPE_UNSTAKE = 'unstake'

export interface StakedVault {
  address: string
  icon: string
  displayName: string
  stakes?: EaseUsersApi.Stake[]
  bribes?: EaseUsersApi.Bribes[]
  percentage: number
  amount?: BigNumberish
  status: string
  isApi: boolean
  index: number
  maxFee: number
}

export const StakePanel: React.FC<{
  userStats: EaseStatsApi.User
  stats: GetUserStatsResponse
  vaults: VaultsApi.Vault[]
  easeStats: EaseStatsApi.Response
}> = ({ userStats, stats, vaults = [], easeStats }) => {
  const [selectedVault, setSelectedVault] = useImmer('0x')
  const [modalValue, setModalValue] = useState<string>('50')
  const [sliderModalObj, setSliderModalObj] = useImmer<SliderModalData>(
    {} as SliderModalData
  )
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const [stakedVaults, setStakedVaults] = useImmer<StakedVault[]>([])
  const [stakedPercentage, setStakedPercentage] = useImmer(0)

  useEffect(() => {
    if (userStats && userStats.vaults) {
      let staked: StakedVault[] = []
      let index = 0
      for (let v of Object.keys(userStats.vaults)) {
        const vault = userStats.vaults[v as any]
        if (vault.percentageStaked) {
          let vaultDetails = getVault(v)
          staked.push({
            address: v,
            percentage: Number(vault.percentageStaked),
            amount: BigNumber.from(vault.gvEaseStaked || 0),
            displayName: vaultDetails?.display_name as string,
            icon: vaultDetails?.icon as string,
            isApi: true,
            status: 'active',
            bribes: [],
            index: index++,
            maxFee: vaultDetails?.maxFee as number
          })
          setStakedPercentage(
            staked.reduce((prev, cur) => prev + cur.percentage, 0)
          )
        }
      }
      setStakedVaults(staked)
    }
  }, [userStats])

  const calculateMaxFee = (vaultAddress: string, newPercent: number) => {
    const stakedVaultDetails = userStats.vaults
      ? userStats.vaults[vaultAddress as any]
      : ({} as any)
    let oldPercent = 0
    if (stakedVaultDetails) {
      oldPercent = Number(stakedVaultDetails.percentageStaked || 0)
    }
    const PERCENTAGE_BUFFER = 100000
    oldPercent = oldPercent / PERCENTAGE_BUFFER
    newPercent = newPercent / PERCENTAGE_BUFFER
    let maxFee = 0

    if (easeStats?.rcaAmountEth && vaultAddress) {
      const totalEth = Number(formatEther(easeStats?.rcaAmountEth || 0))
      let totalStaked = Number(
        formatUnits(BigNumber.from(easeStats?.gvEaseStaked || 0), 18)
      )
      if (BigNumber.from(userStats.gvEaseStaked || 0).eq(0)) {
        totalStaked += Number(
          formatEther(BigNumber.from(userStats.gvEaseStakeable || 0))
        )
      }
      const currVault = easeStats?.vaults[vaultAddress]

      const vaultEth = Number(formatEther(currVault?.rcaAmountEth || 0))

      const vaultStaked = Number(formatEther(currVault?.gvEaseStaked || 0))

      const newStakeAmount =
        Number(formatEther(BigNumber.from(userStats?.gvEaseStakeable || 0))) *
        newPercent

      const oldStakeAmount =
        Number(formatEther(BigNumber.from(userStats?.gvEaseStakeable || 0))) *
        oldPercent

      const newStakeOnVault = vaultStaked + newStakeAmount - oldStakeAmount

      const weeklyBribe =
        currVault?.weeklyBribes && currVault?.weeklyBribes[easeStats.week]
          ? currVault?.weeklyBribes[easeStats.week]
          : ({} as EaseStatsApi.WeeklyBribe)
      const bribesOnVault = Number(formatEther(weeklyBribe.ease || 0))

      const totalBribes = Number(
        formatEther(
          (easeStats?.weeklyBribes &&
            easeStats?.weeklyBribes[easeStats.week] &&
            easeStats?.weeklyBribes[easeStats.week].ease) ||
            0
        )
      )

      const leasedAmount = Number(formatEther(easeStats.leasedAmount || 0))
      const gvEaseBribedForVault =
        totalBribes != 0 ? (bribesOnVault * leasedAmount) / totalBribes : 0

      const newGvEasePower = newStakeOnVault + gvEaseBribedForVault

      const totalPower = totalStaked + leasedAmount
      maxFee =
        (0.33 - 0.13 * (newGvEasePower / totalPower) * (totalEth / vaultEth)) *
        100
    }
    return maxFee > 0 ? (maxFee < 33 ? maxFee : 33) : 0
  }

  const onStake = () => {
    setLoadingModalOpen(true)
    setLoadingModalTitle('Assign Stakes')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to adjust stakes.
        </div>
      </>
    )
    let _vaults: string[] = []
    let _percentages: number[] = []
    for (let vault of stakedVaults) {
      if (vault.percentage !== 0) {
        _vaults.push(vault.address)
        _percentages.push(vault.percentage)
      }
    }

    adjustStakes([_vaults, _percentages])
      .then(() => {})
      .catch((err) => console.error(err))
  }

  const isVaultsUpdateable = useMemo(() => {
    if (stakedVaults.length == 0) {
      return false
    }
    if (stakedPercentage != 100000) {
      return false
    }

    return (
      stakedVaults.filter((v) => v.address != '').length == stakedVaults.length
    )
  }, [stakedVaults, stakedPercentage])

  const onStakeSuccess = () => {
    setLoadingModalOpen(false)
  }
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }
  const adjustStakes = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'adjustStakes',
    onSuccess: onStakeSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const onUnstake = (address: string, percentage: number) => {
    setSelectedVault(address)
    setLoadingModalOpen(true)
    setLoadingModalTitle('Unstaking $gvEase')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to unstake
        </div>
      </>
    )

    unstake([percentage, selectedVault])
      .then(() => {})
      .catch((err) => console.error(err))
  }
  const updateStakedVault = (
    idx: number,
    vault: VaultsApi.Vault,
    percentage: number
  ) => {
    setStakedVaults((draft) => {
      const index = draft.findIndex((v) => v.index === idx)
      if (index !== -1) {
        draft[index].address = vault.address
        draft[index].displayName = vault.display_name
        draft[index].icon = vault.icon
        draft[index].maxFee = calculateMaxFee(draft[index].address, percentage)
        draft[index].amount = BigNumber.from(userStats.gvEaseStakeable || 0)
          .mul(percentage)
          .div(100000)
        draft[index].percentage = percentage
        setStakedPercentage(
          draft.reduce((prev, cur) => prev + cur.percentage, 0)
        )
      }
      return draft
    })
  }
  const deleteStakedVault = (idx: number) => {
    setStakedVaults((draft) => {
      const index = draft.findIndex((v) => v.index === idx)
      if (index !== -1) {
        draft.splice(index, 1)
        setStakedPercentage(
          draft.reduce((prev, cur) => prev + cur.percentage, 0)
        )
      } else {
        return draft
      }

      return draft
    })
  }

  const onUnstakeConfirm = () => {
    setSliderModalObj((v) => {
      v.isOpen = false
      return v
    })
    setLoadingModalOpen(true)
    setLoadingModalTitle('Unstaking $gvEase')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to unstake
        </div>
      </>
    )

    let percs = Number(modalValue) * 1000
    console.log('percentage', percs)

    unstake([percs, selectedVault])
      .then(() => {})
      .catch((err) => console.error(err))
  }

  const unstake = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'unStake',
    onSuccess: onStakeSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const getVault = (address: string) => {
    if (vaults) {
      let v = vaults.filter(
        (v) => v.address.toLowerCase() == address.toLowerCase()
      )
      if (v.length > 0) {
        return v[0]
      }
    }

    return null
  }

  return (
    <div>
      <div className={'grid grid-cols-6 pl-1 items-center'}>
        <div className={'text-white font-bold  col-span-2'}>Vault</div>
        <div className={'text-white font-bold text-center'}>
          Staked ({stakedPercentage / 1000}%)
        </div>
        <div className={'text-white font-bold text-center'}>
          Stake Amount ($gvEase)
        </div>
        <div className={'text-white font-bold text-center flex'}>
          Max Fee
          <Tooltip
            interactive
            className="flex items-center ml-2"
            html={
              <TooltipHtml
                title="This is the maximum fee you pay for any particular hack. The more tokens that are staked to a vault, the lower the maximum fee."
                link="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/token-documentation-get-defi-cover-at-ease/what-is-gvease/"
                name="Max Fee"
              />
            }
            position="top"
            trigger="mouseenter"
          >
            <InformationCircleIcon
              onClick={() => {}}
              className={'h-4 w-4 text-blue-900 mt-1 cursor-pointer'}
            />
          </Tooltip>
        </div>
        <div className={'assign-stakes text-right'}>
          <Tooltip
            // options
            title={'You have to allocate exactly 100% of your $gvEase.'}
            position="top"
            trigger="mouseenter"
          >
            <Button
              onClick={() => onStake()}
              variant={'orange'}
              disabled={!isVaultsUpdateable || !stats.easeBalance.value}
              size={'sm'}
            >
              Assign Stakes
            </Button>
          </Tooltip>
        </div>
      </div>

      {stakedVaults &&
        stakedVaults.map((vault, i) => (
          <StakedVaultRow
            key={`${vault.address}${vault.index}${i}`}
            index={i}
            vault={vault}
            vaults={vaults}
            stats={stats}
            stakedPercentage={stakedPercentage}
            onDelete={() => deleteStakedVault(vault.index)}
            onUpdate={(idx: number, vault: VaultsApi.Vault, pct: number) =>
              updateStakedVault(idx, vault, pct)
            }
            maxFee={vault.maxFee}
          />
        ))}

      {(!stakedVaults || stakedVaults.length == 0) && (
        <div
          className={
            'flex gap-x-4 justify-center text-center text-white bg-blackop-20 rounded-lg p-4 mt-2'
          }
        >
          <InformationCircleIcon className={'h-6 w-6'} />
          <a
            href="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/how-to-stake-gvease/"
            target={'_blank'}
            rel={'nofollow noreferrer'}
          >
            You may stake your $gvEase to lower the maximum fee on a chosen
            vault. Read more here.
          </a>
        </div>
      )}

      <div
        className={
          'fourth-step bg-blackop-50 p-3 mt-2 rounded-lg text-white flex justify-center gap-x-2 items-center cursor-pointer hover:bg-blackop-60'
        }
        onClick={() => {
          setStakedVaults((old) => {
            let vault: StakedVault = {
              address: '',
              displayName: 'Choose Vault',
              icon: '',
              percentage: 0,
              status: 'active',
              bribes: [],
              isApi: false,
              index: old.reduce(
                (prev, cur) => Math.max(cur.index + 1, prev),
                0
              ),
              amount: 0,
              maxFee: 0
            }
            old.push(vault)
            return old
          })
        }}
      >
        <PlusCircleIcon className={'h-4 w-4 text-white'} /> Add New Stake{' '}
      </div>

      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => {}}
      >
        {loadingModalBody}
      </LoadingModal>
    </div>
  )
}

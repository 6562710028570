import { formatDecimals, weiToEth } from '../../../classes/helpers'
import { Button } from '../../Button'
import React, { ReactNode, useRef, useState } from 'react'
import { EaseEventsApi, EaseStatsApi } from '../../../hooks/api/useGetEase'
import { GetUserStatsResponse } from '../../../hooks/gvDashboard/useGetUserStats'
import { VaultsApi } from '../../../hooks/api/useGetVaults'
import { useImpersonatableWeb3React } from '../../../hooks/useImpersonatableWeb3React'
import { useImmer } from 'use-immer'
import { AmountModal, AmountModalData } from '../../AmountModal'
import useContractMethodSend from '../../../hooks/useContractMethodSend'
import Contracts from '../../../contracts'
import { LoadingModal } from '../../LoadingModal'
import { formatEther, formatUnits, parseUnits } from 'ethers/lib/utils'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { Tooltip } from 'react-tippy'
import { BigNumber } from 'ethers'

export const LeasePanel: React.FC<{
  children?: React.PropsWithChildren<any>
  userEvents: EaseEventsApi.Event[]
  stats: GetUserStatsResponse
  vaults: VaultsApi.Vault[]
  userEaseStats: EaseStatsApi.User
  easeStats: EaseStatsApi.Response
}> = ({
  children,
  userEvents,
  stats,
  vaults = [],
  userEaseStats,
  easeStats
}) => {
  const { account, chainId } = useImpersonatableWeb3React()
  const [selectedVault, setSelectedVault] = useImmer('0x')
  const [modalValue, setModalValue] = useState<string>('50')
  const [amountModalObj, setAmountModalObj] = useImmer<AmountModalData>(
    {} as AmountModalData
  )
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false)
  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')
  const amountModalRef = useRef('0')
  const onError = (err: Error) => {
    setLoadingModalOpen(false)
  }

  const onLease = () => {
    amountModalRef.current = formatUnits(userEaseStats.gvEaseStakeable || 0, 18)
    setAmountModalObj((v) => {
      v.maxAmount = BigNumber.from(userEaseStats.gvEaseBalance || 0)
        .sub(BigNumber.from(userEaseStats.leasedAmount || 0))
        .toString()
      v.title = 'Lease $gvEase'
      v.isOpen = true
      v.buttonText = 'Confirm Lease'
      v.onConfirm = () => onLeaseConfirm()
      return v
    })
  }

  const onLeaseConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })

    setLoadingModalOpen(true)
    setLoadingModalTitle('Lease $gvEase')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to lease{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(amountModalRef.current), 4, 2)}
          </span>{' '}
          $gvEase
        </div>
      </>
    )

    await depositToPot([parseUnits(amountModalRef.current, 18)]).catch((err) =>
      console.error(err)
    )
  }

  const onLeaseSuccess = () => {
    setLoadingModalOpen(false)
    Promise.all([
      stats.easeEarned.get([account]),
      stats.easeBalance.get([account]),
      stats.totalEaseDeposits.get([account])
    ]).catch((err) => console.error(err))
  }

  const depositToPot = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'depositToPot',
    onSuccess: onLeaseSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const withdrawFromPot = useContractMethodSend({
    contract: Contracts.gvToken,
    methodName: 'withdrawFromPot',
    onSuccess: onLeaseSuccess,
    onError: onError,
    address: Contracts.gvToken.address
  })

  const onWithdrawFromPot = () => {
    amountModalRef.current = formatUnits(stats.bribePotBalance.value, 18)
    setAmountModalObj((v) => {
      v.maxAmount = stats.bribePotBalance.value
      v.title = 'Unlease gvEase'
      v.isOpen = true
      v.buttonText = 'Confirm Unlease'
      v.onConfirm = () => onWithdrawFromPotConfirm()
      return v
    })
  }

  const onWithdrawFromPotConfirm = () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })

    setLoadingModalOpen(true)
    setLoadingModalTitle('Lease Withdrawal')
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm transaction to withdraw your $gvEase
        </div>
      </>
    )
    withdrawFromPot([parseUnits(amountModalRef.current, 18)]).then(() => {})
  }

  const getVault = (address: string) => {
    if (vaults) {
      let v = vaults.filter(
        (v) => v.address.toLowerCase() == address.toLowerCase()
      )
      if (v.length > 0) {
        return v[0]
      }
    }

    return null
  }
  const getEstRewards = () => {
    let estRewards = 0
    if (stats && easeStats) {
      // This will be a big number
      const userLeased = BigNumber.from(
        stats.bribePotBalance.value
      ) as BigNumber
      const totalLeased = easeStats.leasedAmount
      let nextWeekRewards = '0'
      if (easeStats.weeklyBribes) {
        const weeklyBribe = easeStats.weeklyBribes[easeStats.week + 1]
        if (weeklyBribe) {
          nextWeekRewards = weeklyBribe.ease
        }
      }
      if (userLeased && totalLeased && nextWeekRewards) {
        const totalLeasedBn = BigNumber.from(totalLeased)
        const nextWeekRewardsBn = BigNumber.from(nextWeekRewards)
        const estRewardsBn = userLeased
          .mul(nextWeekRewardsBn)
          .div(totalLeasedBn)
        estRewards = Number(formatEther(estRewardsBn))
      }
    }
    return estRewards
  }
  const getEstApr = () => {
    let apr = 0
    const totalLeased = BigNumber.from(easeStats.leasedAmount || 0) as BigNumber
    if (totalLeased.gt(0)) {
      let nextWeekRewards: any = BigNumber.from(0)
      if (easeStats.weeklyBribes) {
        const weeklyBribe = easeStats.weeklyBribes[easeStats.week + 1]
        if (weeklyBribe) {
          nextWeekRewards = BigNumber.from(weeklyBribe.ease || 0)
        }
      }

      apr = nextWeekRewards.mul(52).mul(10000).div(totalLeased) / 100
    }
    return apr
  }

  return (
    <>
      <div className={'grid grid-cols-4 pl-1 items-center'}>
        <div className={'text-white font-bold'}>Leased ($gvEase)</div>
        <div className={'text-white font-bold'}>
          Est. Weekly Rewards ($Ease)
        </div>
        <div className={'text-white font-bold'}>Est. APR</div>
        <div className={'w-full flex justify-end'}>&nbsp;</div>
      </div>

      {!userEvents || userEvents.length == 0}

      {stats &&
        stats.bribePotBalance &&
        stats.easeEarned &&
        stats.earningsPerWeek && (
          <div
            className={
              'grid grid-cols-4 mt-2 bg-blackop-30 p-3 rounded-lg text-white'
            }
          >
            <div className={'lease-1 flex gap-x-1 items-center font-bold'}>
              {stats.bribePotBalance.value &&
                formatDecimals(
                  Number(formatUnits(stats.bribePotBalance.value, 18)),
                  4,
                  2
                )}{' '}
            </div>
            <div className={'lease-2 font-bold items-center flex'}>
              {formatDecimals(getEstRewards(), 4, 2)}
            </div>
            <div className={'lease-3 items-center flex font-bold'}>
              {formatDecimals(getEstApr(), 4, 2)}%-
              {formatDecimals(getEstApr() * 2, 4, 2)}%
            </div>
            <div className={'lease-4 flex-col sm:flex sm:flex-row justify-end gap-x-4'}>
              <Tooltip
                html={
                  <a
                    className="text-orange-500 underline"
                    href="https://ease.org/learn-crypto-defi/get-defi-cover-at-ease/product-guides/how-to-lease-gvease-users/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to learn how to lease
                  </a>
                }
                position="top"
                trigger="mouseenter"
                interactive
              >
                <Button
                  onClick={() => onLease()}
                  variant={'pink'}
                  size={'xs'}
                  disabled={Number(userEaseStats.gvEaseStakeable) == 0}
                >
                  Lease
                </Button>
              </Tooltip>

              <div>
                <Button
                  onClick={() => onWithdrawFromPot()}
                  variant={'orange'}
                  size={'xs'}
                  disabled={
                    stats.bribePotBalance.value &&
                    Number(stats.bribePotBalance.value) == 0
                  }
                >
                  Unlease
                </Button>
              </div>
            </div>
          </div>
        )}

      {amountModalObj.isOpen && (
        <AmountModal
          title={amountModalObj.title}
          confirmText={amountModalObj.buttonText}
          onConfirm={() => amountModalObj.onConfirm()}
          onClose={() =>
            setAmountModalObj((v) => {
              v.isOpen = false
              return v
            })
          }
          maxAmount={amountModalObj.maxAmount}
          decimals={18}
          onChange={(e: any) => {
            amountModalRef.current = e.toString()
          }}
          open={amountModalObj.isOpen}
          symbol={amountModalObj.symbol}
        >
          &nbsp;
        </AmountModal>
      )}

      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => {}}
      >
        {loadingModalBody}
      </LoadingModal>
    </>
  )
}

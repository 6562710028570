import React from 'react'
import { Tab } from '@headlessui/react'
import { classNames } from '../classes/helpers'
import { ConnectWalletButton } from './ConnectWalletButton'

export const ConnectToWalletDiv: React.FC<{
  children?: React.PropsWithChildren<any>
  text?: string
}> = ({ children, text }) => {
  return (
    <div
      className={classNames(
        'bg-blackop-50 text-white rounded-xl py-4 px-1 text-center text-lg'
      )}
    >
      <div>
        {text ? text : <>Please connect your wallet to display your balance.</>}
      </div>
      <ConnectWalletButton className={'mt-4'}>Connect</ConnectWalletButton>
    </div>
  )
}

import { Tab } from '@headlessui/react'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '../Button'

import {
  classNames,
  flexibleDecimals,
  formatDecimals,
  formatShort,
  makeDecimalUnits,
  unifiedFormat
} from '../../classes/helpers'

import { LoadingModal } from '../LoadingModal'
import { useWeb3React } from '@web3-react/core'
import Contracts from '../../contracts'
import BN from 'bn.js'
import useContractMethodSend from '../../hooks/useContractMethodSend'
import useContractMethodCall from '../../hooks/useContractMethodCall'
import { useGetDepositedEzTokenBalance } from '../../hooks/farming/useGetDepositedEzTokenBalance'
import { useGetTokens, VaultsApi } from '../../hooks/api/useGetVaults'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useImmer } from 'use-immer'
import { AmountModal, AmountModalData } from '../AmountModal'
import { LoadingModalSteps, ModalStep } from '../LoadingModalSteps'

enum Steps {
  APPROVE = 1,
  INIT_DEPOSIT = 2,
  MINT_TO = 3
}

export const FarmingTabPanel: React.FC<{
  children?: React.PropsWithChildren<any>
  vault: VaultsApi.Vault
  onUpdate: Function
}> = ({ children, vault, onUpdate }) => {
  const { account, chainId, library } = useWeb3React()
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)

  const {
    isLoading: isLoadingEase,
    isError,
    data: easeTokenArray,
    error
  } = useGetTokens(Contracts.easeToken.address)

  const [stepsModalOpen, setStepsModalOpen] = useState(false)

  const [_vaultInfo, getVaultInfo] = useContractMethodCall(
    Contracts.masterChef,
    'ezVaultInfo',
    false,
    Contracts.masterChef.address
  )
  const [vaultInfo, setVaultInfo] = useState<any>()

  const [easePerBlock, getEasePerBlock] = useContractMethodCall(
    Contracts.masterChef,
    'easePerBlock',
    false,
    Contracts.masterChef.address
  )

  const [totalAllocPoint, getTotalAllocPoint] = useContractMethodCall(
    Contracts.masterChef,
    'totalAllocPoint',
    false,
    Contracts.masterChef.address
  )

  const [isFarmingAvailable, setIsFarmingAvailable] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(Steps.APPROVE)

  const memoizedBalance = useMemo(() => {
    return formatUnits(vault.balance, vault.decimals)
  }, [vault.balance, vault.decimals])

  const [easeTokenPriceUSD, setEaseTokenPriceUSD] = useState<number>(0)

  const [loadingModalTitle, setLoadingModalTitle] = useState<string>('')
  const [loadingModalBody, setLoadingModalBody] = useState<ReactNode>('')

  const amountModalRef = useRef('0')
  const [amountModalObj, setAmountModalObj] = useImmer<AmountModalData>(
    {} as AmountModalData
  )

  const [vaultAPR, setVaultAPR] = useState<string | undefined>('0')
  const [predictedVaultAPR, setPredictedVaultAPR] = useState<
    string | undefined
  >('0')

  const [depositedEzTokenBalance, getDepositedEzTokenBalance] =
    useGetDepositedEzTokenBalance()

  const [pendingRewards, getPendingRewards] = useContractMethodCall<number>(
    Contracts.masterChef,
    'pendingEase',
    new BN('0'),
    Contracts.masterChef.address
  )

  const memoizedDepositedEzTokenBalance = useMemo(
    () => depositedEzTokenBalance / Number(makeDecimalUnits(vault.decimals)),
    [depositedEzTokenBalance]
  )

  const memoizedPendingRewards = useMemo(
    () => pendingRewards / Number(makeDecimalUnits(18)),
    [pendingRewards]
  )

  const getEzTokenInUSDPrice = async (_amount: string) => {
    if (!_amount) return '0'
    let denom = makeDecimalUnits(vault.decimals)
    let usdPrice = parseUnits(vault?.token.priceUSD.toString(), vault.decimals)
    let ezTokenUsd = BigNumber.from(_amount).mul(usdPrice.toString()).div(denom)
    return ezTokenUsd.toString()
  }

  const [depositedEzTokenInUSD, setDepositedEzTokenInUSD] = useState('0')

  const memoizedDepositedEzTokenInUSD = useMemo(() => {
    let denom = makeDecimalUnits(vault.decimals)
    return BigNumber.from(depositedEzTokenInUSD).div(denom).toString()
  }, [depositedEzTokenInUSD])

  const [balanceInUSD, setBalanceInUSD] = useState('0')

  const memoizedBalanceInUSD = useMemo(() => {
    let denom = makeDecimalUnits(vault.decimals)
    return BigNumber.from(balanceInUSD).div(denom).toString()
  }, [balanceInUSD])

  useEffect(() => {
    setIsLoading(true)
    getVaultInfo([vault.pool_index])
      .catch((err) => {
        setIsLoading(false)
        setIsFarmingAvailable(false)
        console.error(err)
      })
      .then((_info: any) => {
        setIsFarmingAvailable(
          _info && _info.allocPoint && Number(_info.allocPoint) > 0
        )
        setIsLoading(false)
        setVaultInfo(_info)
        return _info
      })
  }, [])

  useEffect(() => {
    if (isFarmingAvailable && account && easeTokenArray) {
      setIsLoading(true)
      Promise.all([
        getDepositedEzTokenBalance(vault.pool_index).catch((err) => {
          console.error(err)
        }),
        getPendingRewards([vault.pool_index, account]).catch((err) => {
          console.error(err)
        }),
        getEzTokenInUSDPrice(vault.balance).then((_balance) => {
          setBalanceInUSD(_balance)
        }),
        getTotalAllocPoint([]).catch((err) => {
          console.error(err)
        }),
        getEasePerBlock([]).catch((err) => {
          console.error(err)
        }),
      ]).then(() => {
        setEaseTokenPriceUSD(easeTokenArray[0].priceUSD)
        setIsLoading(false)
      })
    }
    if (isFarmingAvailable && depositedEzTokenBalance) {
      getEzTokenInUSDPrice(depositedEzTokenBalance.toString()).then(
        (_depozitedEz) => {
          setDepositedEzTokenInUSD(_depozitedEz)
        }
      )
    }
  }, [account, isFarmingAvailable, depositedEzTokenBalance, easeTokenArray])

  useEffect(() => {
    if(easeTokenPriceUSD && easePerBlock && totalAllocPoint && depositedEzTokenBalance && vaultInfo){
      calculateVaultAPR()
    }
  },[easeTokenPriceUSD , easePerBlock, totalAllocPoint, depositedEzTokenBalance, vaultInfo ])

    const onError = (err: Error) => {
    setStepsModalOpen(false)
    setLoadingModalOpen(false)
  }

  const [_allowance, getAllowance] = useContractMethodCall<string>(
    Contracts.underlyingToken,
    'allowance',
    new BN('0'),
    vault.address
  )

  const [_ballance, getBalanceOf] = useContractMethodCall<number>(
    Contracts.rcaShield,
    'balanceOf',
    new BN('0'),
    vault.address
  )

  const onClaimRewards = () => {
    let amount = BigNumber.from(0)

    setLoadingModalOpen(true)
    setLoadingModalTitle('Confirm Rewards Claim from ' + vault.display_name)
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to claim rewards of{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(memoizedPendingRewards), 2, 2)}
          </span>{' '}
          $Ease.
        </div>
      </>
    )
    callDeposit([vault.pool_index, amount]).catch((err) => console.error(err))
  }

  const onDeposit = async () => {
    calculateVaultAPR(vault.balance)
    amountModalRef.current = formatUnits(vault.balance, vault.decimals)
    setAmountModalObj((v) => {
      v.maxAmount = vault.balance
      v.title = 'Stake ' + vault.display_name
      v.isOpen = true
      v.buttonText = 'Confirm'
      v.onConfirm = () => onDepositConfirm()
      return v
    })
  }

  const onWithdraw = () => {
    amountModalRef.current = memoizedBalance
    setAmountModalObj((v) => {
      v.maxAmount = String(depositedEzTokenBalance)
      v.title = 'Unstake ez-' + vault.display_name
      v.isOpen = true
      v.buttonText = 'Confirm'
      v.onConfirm = () => onWithdrawConfirm()
      return v
    })
  }

  const onTransactionSuccess = () => {
    setCurrentStep(Steps.MINT_TO)
    Promise.all([
      getDepositedEzTokenBalance(vault.pool_index),
      getPendingRewards([vault.pool_index, account]),
      onUpdate()
    ])
      .catch((err) => {
        setStepsModalOpen(false)
        setLoadingModalOpen(false)
        console.error(err)
      })
      .then(() => {
        setStepsModalOpen(false)
        setLoadingModalOpen(false)
      })
  }

  const onApproveSuccess = () => {
    let amount = parseUnits(amountModalRef.current, vault.decimals)
    if (amount.gt(BigNumber.from(vault.balance))) {
      amount = BigNumber.from(vault.balance)
    }

    setCurrentStep(Steps.INIT_DEPOSIT)
    setStepsModalOpen(true)

    callDeposit([vault.pool_index, amount]).catch((err) => console.error(err))
  }

  const ezTokenApprove = useContractMethodSend({
    contract: Contracts.erc20,
    methodName: 'approve',
    onSuccess: onApproveSuccess,
    onError: onError,
    address: vault.address
  })

  const onDepositConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })

    let allowance = await getAllowance([
      account,
      Contracts.masterChef.address
    ]).catch((err) => console.error(err))
    if (!allowance) return

    let amount = parseUnits(amountModalRef.current, vault.decimals)

    if (amount.gt(BigNumber.from(vault.balance))) {
      amount = BigNumber.from(vault.balance)
    }

    if (amount.lte(BigNumber.from(allowance))) {
      onApproveSuccess()
      return
    }

    setCurrentStep(Steps.APPROVE)
    setStepsModalOpen(true)

    ezTokenApprove([Contracts.masterChef.address, amount]).catch((err) =>
      console.error(err)
    )
  }

  const withdrawRequest = useContractMethodSend({
    contract: Contracts.masterChef,
    methodName: 'withdraw',
    onSuccess: onTransactionSuccess,
    onError: onError,
    address: Contracts.masterChef.address,
    methodDisplayName: 'Unstake'
  })

  const onWithdrawConfirm = async () => {
    setAmountModalObj((v) => {
      v.isOpen = false
      return v
    })

    let amount = parseUnits(amountModalRef.current, vault.decimals)
    if (amount.gt(BigNumber.from(depositedEzTokenBalance))) {
      amount = BigNumber.from(depositedEzTokenBalance)
    }

    setLoadingModalOpen(true)
    setLoadingModalTitle('Unstake ez-' + vault.display_name)
    setLoadingModalBody(
      <>
        <div className={'pt-8 mb-4 border-t border-gray-100 text-center'}>
          Please confirm the transaction to unstake"{' '}
          <span className={'text-pink-500'}>
            {formatDecimals(Number(amountModalRef.current), 4, 2)}
          </span>{' '}
          {vault.symbol} from the ez-farm. Any earned rewards will be claimed as
          well.
        </div>
      </>
    )

    await withdrawRequest([vault.pool_index, amount]).catch((err) =>
      console.error(err)
    )
  }

  const callDeposit = useContractMethodSend({
    contract: Contracts.masterChef,
    methodName: 'deposit',
    onSuccess: onTransactionSuccess,
    onError: onError,
    address: Contracts.masterChef.address,
    methodDisplayName: 'Stake'
  })
  const calculateVaultAPR = async (_forcastAmount?: string) => {

    let easePerWeek = Number(formatUnits( String(easePerBlock ?? 0) , 18)) * ((7*24*60*60) / 12) // easePerBlock * aprox. BlocksPerWeek
    let easePerWeekPerVault = easePerWeek * (Number(vaultInfo?.allocPoint) / Number(totalAllocPoint))

    const depositedEzTokens: number = await getBalanceOf([
      Contracts.masterChef.address
    ]).catch((err) => {
      console.error(err)
      return 0
    })

    let easeToken = easeTokenArray ? easeTokenArray[0] : { priceUSD: 0 }
    let earningVault = Number(52.143 * easePerWeekPerVault * easeToken.priceUSD) // weeks in year * 100k * $/ez

    let depositVaultAmount = _forcastAmount
      ? BigNumber.from(depositedEzTokens).add(BigNumber.from(_forcastAmount))
      : BigNumber.from(depositedEzTokens)

    let depositVaultInUSD = await getEzTokenInUSDPrice(
      depositVaultAmount.toString()
    )
    let depositVault = BigNumber.from(depositVaultInUSD)
      .div(BigNumber.from(makeDecimalUnits(vault.decimals)))
      .toNumber()

    //TODO change to BIG NUMBER CALCULATIONS
    let vaultAPRCalculatedNum: number | string | undefined =
      (earningVault / depositVault) * 100
    let vaultAPRCalculated =
      vaultAPRCalculatedNum > 1000
        ? '>1000'
        : flexibleDecimals(vaultAPRCalculatedNum)
    vaultAPRCalculatedNum =
      vaultAPRCalculatedNum === Infinity || vaultAPRCalculatedNum > 100000
        ? '>100,000'
        : flexibleDecimals(vaultAPRCalculatedNum)
    _forcastAmount
      ? setPredictedVaultAPR(vaultAPRCalculatedNum)
      : setVaultAPR(vaultAPRCalculated)
  }

  const steps = useMemo(
    (): ModalStep[] => [
      {
        name: '1: Approve stake amount',
        description: `Approve ${Number(amountModalRef.current)} ${
          vault.symbol
        } tokens to be staked `,
        status: currentStep == Steps.APPROVE ? 'current' : 'complete'
      },
      {
        name: '2: Confirm staking',
        description: `Confirm staking of ${Number(amountModalRef.current)} ${
          vault.symbol
        } tokens into the ez-farm `,
        status:
          currentStep == Steps.INIT_DEPOSIT
            ? 'current'
            : currentStep < Steps.INIT_DEPOSIT
            ? 'upcoming'
            : 'complete'
      },
      {
        name: '3. Staked',
        description: `Staked ${Number(amountModalRef.current)} ${
          vault.symbol
        } `,
        status:
          currentStep == Steps.MINT_TO
            ? 'current'
            : currentStep < Steps.MINT_TO
            ? 'upcoming'
            : 'complete'
      }
    ],
    [currentStep, vault?.symbol, vault?.token?.symbol, amountModalRef.current]
  )

  const amountModalAPRforcast = () => {
    if (!amountModalObj.title.includes('Unstake')) {
      return (
        <div className={'text-lg pt-3 pb-6 text-center'}>
          <span className={''}>Expected APR after deposit: </span>
          <span className={'font-bold'}>{predictedVaultAPR}%</span>
        </div>
      )
    }
  }

  const farmingDisabledMessage = () => {
    if (!isFarmingAvailable) {
      return (
        <div
          className={
            'flex w-full justify-center items-end gap-x-3 mt-4 farming-buttons'
          }
        >
          Farming is not available for this vault
        </div>
      )
    }
  }

  const farmingButtons = () => {
    return (
      <div
        className={
          'flex w-full justify-center items-end gap-x-3 mt-4 farming-buttons'
        }
      >
        <span className={'farming-button-stake'}>
          <Button
            disabled={Number(memoizedBalance) > 0 ? isFarmingAvailable ? false : true : true}
            onClick={() => {
              onDeposit()
            }}
            size={'sm'}
          >
            <span className={'p-1'}>Stake ez-tokens</span>
          </Button>
        </span>
        <span className={'farming-button-claim'}>
          <Button
            disabled={Number(memoizedPendingRewards) > 0 ? isFarmingAvailable ? false : true : true}
            onClick={() => {
              onClaimRewards()
            }}
            size={'sm'}
          >
            <span className={'p-1'}>Claim $ease</span>
          </Button>
        </span>
        <span className={'farming-button-unstake'}>
          <Button
            disabled={
              Number(memoizedDepositedEzTokenBalance) > 0 ? isFarmingAvailable ? false : true : true
            }
            onClick={() => {
              onWithdraw()
            }}
            size={'sm'}
          >
            <span className={'p-1'}>Unstake</span>
          </Button>
        </span>

      </div>
    )
  }

  const open = (url: string) => window.open(url)
  return (
    <Tab.Panel
      className={classNames(
        'text-white rounded-xl py-4 px-1 farming-tab',
        'focus:outline-none focus:ring-transparent',
        isLoading ? 'filter blur-sm animate-pulse' : ''
      )}
    >
      <div
        className={
          'flex justify-center items-center gap-y-2 flex-col gap-x-3 mt-5 mb-7 farming-tab-content'
        }
      >
        <div className={'bg-blackop-50 rounded-xl p-4 '}>
          <div
            className={'flex justify-center items-baseline gap-x-3 mt-3 mb-5'}
          >
            <div className={'ml-4 mr-4 text-center farming-ez-balance'}>
              <div className={'text-gray-100 mb-2'}> Unstaked ez-tokens</div>
              <strong className={'text-2xl'}>
                {' '}
                {unifiedFormat(Number(memoizedBalance))}{' '}
              </strong>
              <div className={'text-gray-400 mb-2'}>
                ≈ ${unifiedFormat(memoizedBalanceInUSD)}
              </div>
            </div>
            <div className={'ml-4 mr-4 text-center farming-ez-staked'}>
              <div className={'text-gray-100 mb-2'}> Staked ez-tokens</div>
              <strong className={'text-2xl'}>
                {memoizedDepositedEzTokenBalance
                  ? unifiedFormat(memoizedDepositedEzTokenBalance)
                  : formatShort(0)}
              </strong>
              <div className={'text-gray-400 mb-2'}>
                ≈ ${unifiedFormat(memoizedDepositedEzTokenInUSD)}
              </div>
            </div>
            <div className={'ml-4 mr-4 text-center farming-rewards'}>
              <div className={'text-gray-100 mb-2'}>$Ease earned</div>
              <strong className={'text-2xl'}>
                {unifiedFormat(memoizedPendingRewards)}
              </strong>
              <div className={'text-gray-400 mb-2'}>
                ≈ ${unifiedFormat(memoizedPendingRewards * easeTokenPriceUSD)}
              </div>
            </div>
            <div className={'ml-4 mr-4 text-center farming-apr'}>
              <div className={'text-gray-100 mb-2'}>APR</div>
              <strong className={'text-2xl'}>
                {' '}
                {isFarmingAvailable ? vaultAPR : '0'}%{' '}
              </strong>
            </div>
          </div>
        </div>

        {farmingButtons()}
        {farmingDisabledMessage()}
      </div>

      {amountModalObj.isOpen && (
        <AmountModal
          title={amountModalObj.title}
          confirmText={amountModalObj.buttonText}
          onConfirm={() => amountModalObj.onConfirm()}
          onClose={() =>
            setAmountModalObj((v) => {
              v.isOpen = false
              return v
            })
          }
          maxAmount={amountModalObj.maxAmount}
          decimals={vault.decimals}
          onChange={(e: any) => {
            let amountWithDecimals = parseUnits(e.toString(), vault.decimals)
            calculateVaultAPR(amountWithDecimals.toString())
            amountModalRef.current = e.toString()
          }}
          open={amountModalObj.isOpen}
          symbol={amountModalObj.symbol}
        >
          {amountModalAPRforcast()}
        </AmountModal>
      )}

      <LoadingModal
        open={loadingModalOpen}
        title={loadingModalTitle}
        onClose={() => {}}
      >
        {loadingModalBody}
      </LoadingModal>

      <LoadingModal
        open={stepsModalOpen}
        title={`Staking ${vault.symbol}`}
        onClose={() => {}}
      >
        <div className={'pt-8 mb-4 border-t border-gray-100'}>
          <LoadingModalSteps steps={steps} />
        </div>
      </LoadingModal>
    </Tab.Panel>
  )
}

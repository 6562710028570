import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ContractInterface } from '../contracts'
import {
  capitalize,
  makeWeb3Contract,
  parseWeb3Error,
  formatDecimals,
  weiToEth
} from '../classes/helpers'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { TransactionReceipt } from 'web3-core'
import { notifyError, notifyPending, notifySuccess } from '../components/Layout'

export const METAMASK_USER_CANCELED_CODE = 4001

export interface methodSendProps {
  contract: ContractInterface
  methodName: string
  onSuccess: (val: boolean) => void
  onError: (error: Error) => void
  address?: string
  methodDisplayName?: string
}

const useContractMethodSend = (props: methodSendProps) => {
  const { account, library } = useWeb3React()

  return useCallback(
    async (params?: unknown[], value?: string) => {
      if (!account) {
        return false
      }
      if (!params) {
        params = []
      }
      if (!value) {
        value = '0'
      }

      let gasPrices = 420 //await getGasPrices()
      const _contract = makeWeb3Contract(
        library.currentProvider,
        props.contract,
        props.address
      )
      let sendData = {
        from: account,
        value: value
      }

      await _contract.methods[props.methodName](...params)
        .send(sendData)
        .once('transactionHash', (hash: string) => {
          let methodDisplayName = props.methodDisplayName ? props.methodDisplayName : props.methodName
          notifyPending(`${capitalize(methodDisplayName)} Pending!`, '', hash)
        })
        .once('receipt', (receipt: TransactionReceipt) => {})
        .on(
          'confirmation',
          (confirmationNumber: number, receipt: TransactionReceipt) => {
            let methodDisplayName = props.methodDisplayName? props.methodDisplayName : props.methodName
            if (confirmationNumber === 2 || confirmationNumber === 1) {
              if (methodDisplayName === 'deposit' && params) {
                notifySuccess(
                  'Deposit Amount',
                  `${formatEther(BigNumber.from(params[0]))} $Ease Deposited!`
                )
              }
              notifySuccess(
                `${capitalize(methodDisplayName)} Confirmed!`,
                '',
                receipt.transactionHash
              )
            }
          }
        )
        .on('error', (error: Error & { code: number }) => {
          const [message, jsonError] = parseWeb3Error(error)

          if (error.code) {
            // don't report to sentry when users cancel their transaction
            if (error.code != METAMASK_USER_CANCELED_CODE) {
              // Sentry.captureException(error)
            }
            notifyError(`${capitalize(props.methodName)} Error`, message)
          }
          props.onError(error)
          return false
        })
        .then((data: boolean) => {
          props.onSuccess(data)
          return true
        })
    },
    [
      account,
      library,
      props.contract,
      props.methodName,
      props.onError,
      props.onSuccess
    ]
  )
}

export default useContractMethodSend

import { ContractInterface } from './index'

const MasterChef = (address: string): ContractInterface =>
  Object.freeze({
    address: address,
    abi: [
      {
        'inputs': [
          {
            'internalType': 'contract IERC20',
            'name': '_ease',
            'type': 'address'
          },
          {
            'internalType': 'contract IGvToken',
            'name': '_gvToken',
            'type': 'address'
          },
          {
            'internalType': 'uint256',
            'name': '_easePerBlock',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'nonpayable',
        'type': 'constructor'
      },
      {
        'anonymous': false,
        'inputs': [
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'user',
            'type': 'address'
          },
          {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'pid',
            'type': 'uint256'
          },
          {
            'indexed': false,
            'internalType': 'uint256',
            'name': 'amount',
            'type': 'uint256'
          }
        ],
        'name': 'Deposit',
        'type': 'event'
      },
      {
        'anonymous': false,
        'inputs': [
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'user',
            'type': 'address'
          },
          {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'pid',
            'type': 'uint256'
          },
          {
            'indexed': false,
            'internalType': 'uint256',
            'name': 'amount',
            'type': 'uint256'
          }
        ],
        'name': 'EmergencyWithdraw',
        'type': 'event'
      },
      {
        'anonymous': false,
        'inputs': [
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'previousOwner',
            'type': 'address'
          },
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'newOwner',
            'type': 'address'
          }
        ],
        'name': 'OwnershipTransferred',
        'type': 'event'
      },
      {
        'anonymous': false,
        'inputs': [
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'ezVaultAddress',
            'type': 'address'
          },
          {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'vaultId',
            'type': 'uint256'
          }
        ],
        'name': 'VaultAdded',
        'type': 'event'
      },
      {
        'anonymous': false,
        'inputs': [
          {
            'indexed': true,
            'internalType': 'address',
            'name': 'user',
            'type': 'address'
          },
          {
            'indexed': true,
            'internalType': 'uint256',
            'name': 'pid',
            'type': 'uint256'
          },
          {
            'indexed': false,
            'internalType': 'uint256',
            'name': 'amount',
            'type': 'uint256'
          }
        ],
        'name': 'Withdraw',
        'type': 'event'
      },
      {
        'inputs': [],
        'name': 'MULTIPLIER',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_allocPoint',
            'type': 'uint256'
          },
          {
            'internalType': 'contract IERC20',
            'name': '_ezToken',
            'type': 'address'
          },
          {
            'internalType': 'bool',
            'name': '_withUpdate',
            'type': 'bool'
          }
        ],
        'name': 'add',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': '_amount',
            'type': 'uint256'
          }
        ],
        'name': 'deposit',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'ease',
        'outputs': [
          {
            'internalType': 'contract IERC20',
            'name': '',
            'type': 'address'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'easePerBlock',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          }
        ],
        'name': 'emergencyWithdraw',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'name': 'ezVaultInfo',
        'outputs': [
          {
            'internalType': 'contract IERC20',
            'name': 'ezToken',
            'type': 'address'
          },
          {
            'internalType': 'uint256',
            'name': 'allocPoint',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': 'lastRewardBlock',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': 'accEasePerShare',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'gvToken',
        'outputs': [
          {
            'internalType': 'contract IGvToken',
            'name': '',
            'type': 'address'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'massUpdatePools',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'owner',
        'outputs': [
          {
            'internalType': 'address',
            'name': '',
            'type': 'address'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          },
          {
            'internalType': 'address',
            'name': '_user',
            'type': 'address'
          }
        ],
        'name': 'pendingEase',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'poolLength',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'renounceOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': '_allocPoint',
            'type': 'uint256'
          },
          {
            'internalType': 'bool',
            'name': '_withUpdate',
            'type': 'bool'
          }
        ],
        'name': 'set',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_easePerBlock',
            'type': 'uint256'
          }
        ],
        'name': 'setEasePerBlock',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'startBlock',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': 'amount',
            'type': 'uint256'
          }
        ],
        'name': 'sweep',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [],
        'name': 'totalAllocPoint',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'address',
            'name': 'newOwner',
            'type': 'address'
          }
        ],
        'name': 'transferOwnership',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          }
        ],
        'name': 'updatePool',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '',
            'type': 'uint256'
          },
          {
            'internalType': 'address',
            'name': '',
            'type': 'address'
          }
        ],
        'name': 'userInfo',
        'outputs': [
          {
            'internalType': 'uint256',
            'name': 'amount',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': 'rewardDebt',
            'type': 'uint256'
          }
        ],
        'stateMutability': 'view',
        'type': 'function'
      },
      {
        'inputs': [
          {
            'internalType': 'uint256',
            'name': '_pid',
            'type': 'uint256'
          },
          {
            'internalType': 'uint256',
            'name': '_amount',
            'type': 'uint256'
          }
        ],
        'name': 'withdraw',
        'outputs': [],
        'stateMutability': 'nonpayable',
        'type': 'function'
      }
    ]
  })

export default MasterChef

import React from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { WarningAlert } from '../WarningAlert'
import { classNames } from '../../classes/helpers'

export const TenderlyRpcAlert: React.FC<{
  className?: string
  children?: React.PropsWithChildren<any>
}> = ({ children, className }) => {
  return <WarningAlert className={className}>{children}</WarningAlert>
}

import React from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { Button } from '../Button'

export const JoyrideTooltip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  step,
  isLastStep,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps
}) => (
  <div
    className={'bg-white text-black w-full p-4 rounded-lg max-w-md'}
    {...tooltipProps}
  >
    {step.title && step.title}
    <div className={'py-4 text-base'}>{step.content}</div>
    {index == 0 && step.target == '.gv-ease-app' && (
      <div className={'flex gap-x-2 justify-center'}>
        <Button {...closeProps} size={'sm'} hover={false}>
          Never
        </Button>
        <Button {...closeProps} size={'sm'} hover={false}>
          Not Now
        </Button>
        <Button
          {...primaryProps}
          classes={'w-16'}
          variant={'pink'}
          size={'sm'}
          hover={false}
        >
          Yes
        </Button>
      </div>
    )}
    <div className={'flex justify-end gap-x-2'}>
      {index > 0 && (
        <Button {...backProps} variant={'orange'} size={'sm'} hover={false}>
          Back
        </Button>
      )}
      {(index > 0 || (index == 0 && step.target != '.gv-ease-app')) &&
        !isLastStep && (
          <Button {...primaryProps} size={'sm'} hover={false}>
            Next
          </Button>
        )}
      {isLastStep && (
        <Button {...closeProps} size={'sm'} hover={false}>
          Close
        </Button>
      )}
    </div>
  </div>
)

import React from 'react'
import { formatDecimals } from '../../classes/helpers'

export const PercentageOfModalStat: React.FC<{
  value: number
  percent: number
  token: string
}> = ({ value, percent, token }) => {
  return (
    <div>
      <span className={'font-bold text-sm text-orange-500'}>
        {percentageOf(value, percent)}
      </span>{' '}
      {token}
    </div>
  )
}

export const percentageOf = (val: number, percent: number) => {
  return formatDecimals(val * (percent / 100), 3, 0)
}

import { Step } from 'react-joyride'

export const activeDepositSteps: Array<Step> = [
  {
    target: '.active-deposits',
    placement: 'top',
    content:
      'Once your wallet is connected and you have deposited assets to get covered, they will appear in this top section.',
    disableBeacon: true
  }
]

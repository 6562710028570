import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import './index.css'
import App from './App'

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: window.location.host.includes('localhost')? undefined : 'https://5b5f7ec42f6f446299bb9060bf07958c@o1104821.ingest.sentry.io/4505108939735040',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'localhost:3000',
        'devapp.ease.org',
        'app.ease.org',
        /^\//
      ]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, // better to keep higher value for low traffic, 50%
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

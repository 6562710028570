import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { AbstractConnector } from '@web3-react/abstract-connector'

export const useWeb3Connect = () => {
  const { activate } = useWeb3React()
  return useCallback(
    (connector: AbstractConnector) => {
      activate(connector)
        .then((r) => {})
        .catch((err) => console.error(err))
    },
    [activate]
  )
}

export const useWeb3Disconnect = () => {
  const { deactivate } = useWeb3React()
  return useCallback(() => {
    deactivate()
  }, [deactivate])
}

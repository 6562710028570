import { useQuery } from 'react-query'

interface TapRequest {
  symbol: string
  amount: number
  to: string
}

const getFaucets = async () => {
  const response = await fetch(
    process.env.REACT_APP_BASE_URL + '/api/v1/faucets'
  )
  return response.json()
}

export default function useGetFaucets() {
  return useQuery<FaucetsApi.Faucet[], Error>('faucets/get', () => getFaucets(), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1
  })
}

export const requestTap = async (
    tapRequest: TapRequest
): Promise<FaucetsApi.Tap> => {
  const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/faucets/${tapRequest.symbol}/taps`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({amount: tapRequest.amount, to: tapRequest.to})
      }
  )
  return response.json()
}


export declare module FaucetsApi {
  export interface Faucet {
    symbol: string
    token: string
    whale: string
  }
  export interface Tap {
    symbol: string
    amount: string
    hash: string
  }

}


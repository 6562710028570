export const testCases: TestCase[] = [
  {
    group: 'dashboard',
    tests: [
      'Active deposits tabs are functioning properly',
      'Active deposits data is displaying properly',
      'Top Vaults are displaying in the correct order',
      'Opportunities data is displaying properly',
      'Opportunities sorting is functioning properly',
      'Opportunities Available to deposit is showing the correct numbers'
    ]
  },
  {
    group: 'token swap',
    tests: [
      'Swapping between vArmor and Armor updates the UI properly',
      'Swap modal is showing the same value that was submitted (approval + swap)',
      'Swap modal is showing the correct selected token and the corresponding swap token',
      'Swapping doesnt call approve when it already has enough allowance',
      'Swapping doesnt fail from the max rounding error'
    ]
  }
]

export interface TestCase {
  group: string
  tests: string[]
}

import clsx from 'clsx'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { chainIdToName } from '../classes/helpers'
import React from 'react'

export const InformationAlert: React.FC<{
  children?: React.PropsWithChildren<any>
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        className,
        'bg-blackop-50 mb-4 p-4 mx-2 sm:mx-0 rounded-2xl text-center text-white flex items-center justify-center  gap-x-4 border-red-500 border-2'
      )}
    >
      <InformationCircleIcon className={'h-8 w-8 fill-white'} />
      <div>{children}</div>
    </div>
  )
}
